export default function splitNumberAndDialCode(str = "", countries) {
  const countriesByDialCode = countries.reduce((acc, curr) => ({
      ...acc,
      [curr.dialCode]: acc[curr.dialCode]
        ? [acc[curr.dialCode], curr].sort((a, b) => a.priority - b.priority)[0]
        : curr,
    }), {});

  const [longestDialCode = []] = Object.keys(countriesByDialCode).sort(
    (a = "", b = "") => b.length - a.length
  );

  const country = ((n) => {
    let i = n;
    while (i > 0) {
      const x = str.substring(0, i);
      if (countriesByDialCode[x]) {
        return countriesByDialCode[x];
      }
      i -= 1;
    }
    return {};
  })(longestDialCode.length);

  return {
    number: str.replace(country?.dialCode || "", ""),
    dialCode: country?.dialCode || "",
    iso2: country?.iso2 || "",
  };
}
