import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";

import { accommodationsContentReset } from "store/accommodationsContent";
import { offersContentReset } from "store/offersContent";
import { propertiesReset } from "store/properties";
import { propertyContentReset } from "store/propertyContent";
import { taxesContentReset } from "store/taxesContent";
import { upsellsContentReset } from "store/upsellsContent";
import { changeLanguage } from "../app.slice";

export default function changeLanguageEpic(action$) {
  return action$.pipe(
    ofType(changeLanguage.type),
    mergeMap(({ payload: { locale: _locale } }) => [
        propertiesReset(),
        accommodationsContentReset(),
        offersContentReset(),
        propertyContentReset(),
        taxesContentReset(),
        upsellsContentReset(),
      ])
  );
}
