import React from "react";
import { AuthenticationLayout } from "Authentication";
import { useTranslation } from "hooks";
import { useForm, Controller } from "react-hook-form";
import { TextInput, Button } from "Profile/components";
import Typeahead from "Profile/ProfileView/Typeahead";
import { ErrorBanner, EarlyCheckInBanner } from "../Banners";
import { webCheckInPath } from "../../webCheckInRoutes";

function ReservationDetails({
  properties,
  confirmation = "",
  propertyData: { owsCode = "", name = "", verticalImage, checkInTimeUTC } = {},
  onSubmitReservationDetails,
  reservationError,
  canCheckIn,
  resetWebCheckIn,
  showPartialReservationDetailsForm,
  showFullReservationDetailsForm,
}) {
  const { t } = useTranslation();

  const { register, handleSubmit, control, errors, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      hotelCode: owsCode,
      confirmation,
      lastName: "",
    },
  });

  return (
    <AuthenticationLayout
      pageTitle={webCheckInPath.getPageTitle()}
      htmlBodyClassName={webCheckInPath.htmlBodyClassName}
      backgroundImageSrc={verticalImage}
      hideLanguageSelector
      enableProfileLinkFlyout={false}
      fullWidthRender={() => (
        <>
          {reservationError && (
            <ErrorBanner
              message={t(reservationError)}
              onClose={resetWebCheckIn}
            />
          )}

          {!canCheckIn && (
            <EarlyCheckInBanner
              onClose={resetWebCheckIn}
              confirmation={confirmation}
              hotelCode={owsCode}
              checkInTimeUTC={checkInTimeUTC}
            />
          )}
        </>
      )}
    >
      <div className="view step-1">
        <h1>{t("Check In")}</h1>
        <form
          className="form-gray"
          onSubmit={handleSubmit(onSubmitReservationDetails)}
        >
          {showPartialReservationDetailsForm && (
            <>
              <p className="provided-details">
                {t("Reservation #: {0}", [confirmation])}
                <br />
                {name}
              </p>

              <h2 className="ty-c1">{t("Confirm My Details")}</h2>

              <p>
                {t(
                  "For security purposes, please confirm the details on your reservation to proceed with check in."
                )}
              </p>
            </>
          )}
          {showFullReservationDetailsForm && (
            <p>
              {t(
                "Please enter the details on your reservation to proceed with check in."
              )}
            </p>
          )}

          <div className="inputs">
            {showFullReservationDetailsForm && (
              <TextInput
                name="confirmation"
                label={t("Confirmation Number")}
                required
                register={register({ required: true })}
                error={
                  errors.reservationId &&
                  t("Please enter a confirmation number")
                }
              />
            )}

            <TextInput
              name="lastName"
              label={t("Last Name")}
              required
              register={register({ required: true })}
              error={errors.lastName && t("Please enter a last name")}
            />

            {showFullReservationDetailsForm && (
              <div className="row">
                <div className="col-sm-12">
                  <Controller
                    control={control}
                    name="hotelCode"
                    rules={{
                      required: true,
                    }}
                    render={({ ref: _ignoredRef, ...renderProps }) => (
                        <Typeahead
                          options={properties}
                          {...renderProps}
                          label={t("Reservation Location")}
                          error={errors.hotelCode && t("Please select a hotel")}
                          required
                        />
                      )}
                  />
                </div>
              </div>
            )}
          </div>
          <Button type="submit" disabled={!formState.isValid}>
            {t("Continue")}
          </Button>
        </form>
      </div>
    </AuthenticationLayout>
  );
}

export default ReservationDetails;
