import React from "react";

import { useTranslation } from "hooks";
import { selectEmails } from "store/profile";
import { PERSONAL_INFO_TYPE } from "fixtures/constants";
import { ToggleBlock } from "Profile/components";
import AddEmail from "./AddEmail";
import EditEmail from "./EditEmail";
import useBlock from "../../hooks/useBlock";
import ToggleBlockLabel from "../ToggleBlockLabel";

const MAX_NUMBER_OF_EMAILS = 4;

function Emails() {
  const { t } = useTranslation();

  const { canAdd, items: emails } = useBlock({
    selector: selectEmails,
    max: MAX_NUMBER_OF_EMAILS,
  });

  return (
    <div id="email" className="view--profile-edit__block">
      <div className="fs-profile-emails">
        <div className="view--profile-edit__block__title-row">
          <ToggleBlock
            type="add"
            label={t("Email")}
            buttonLabel={t("Add")}
            buttonAriaLabel={t("Add a new email address")}
            showButton={canAdd}
          >
            {({ close }) => <AddEmail closeForm={close} />}
          </ToggleBlock>
        </div>

        {emails.map((email, index) => (
          <ToggleBlock
            key={email.salesForceId}
            type="profile-value-edit"
            buttonLabel={t("Add")}
            buttonAriaLabel={t("Add a new email address")}
            label={({ toggle, isOpen }) => (
                <ToggleBlockLabel
                  label={PERSONAL_INFO_TYPE({ isEmail: true })[email.emailType]}
                  ariaLabel={t("Edit your email address")}
                  secondaryLabel={email.defaultEmail && t("primary")}
                  selectedValue={email.email}
                  toggleForm={toggle}
                  isActive={isOpen}
                />
              )}
          >
            {({ close }) => (
              <EditEmail
                identifier={`edit-email-address-${index}`}
                email={email}
                closeForm={close}
                canBeDeleted={
                  emails.length > 1 && !email.isLogin && !email.defaultEmail
                }
              />
            )}
          </ToggleBlock>
        ))}
      </div>
    </div>
  );
}

export default Emails;
