import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useDispatchWithLocale, useTranslation } from "hooks";
import { resetBookingFlow } from "store/bookingFlow";
import {
  API_REQUEST_STATES,
  selectIsRequestLoading,
} from "store/apiRequestStates";
import {
  fetchBookingInProgress,
  fetchBookingInProgressCancel,
  selectBookingInProgressId,
  selectBookingInProgressSearchParams,
  selectHasBookingInProgress,
  selectHasCompletedBooking,
} from "store/bookings";
import {
  fetchProperties,
  fetchPropertiesCancel,
  selectIsPropertyBookableInLocale,
} from "store/properties";
import {
  fetchAllPropertyContent,
  fetchAllPropertyContentCancel,
} from "store/allPropertyContent";
import {
  fetchBookingMessages,
  fetchBookingMessagesCancel,
} from "store/bookingMessages";
import {
  fetchExchangeRates,
  fetchExchangeRatesCancel,
} from "store/exchangeRates";
import {
  getSearchFormValuesFromQueryString,
  isEmployeeRate,
} from "BookingFlow/utils";
import { fetchWorkplaces, fetchWorkplacesCancel } from "store/workplaces";
import { selectIsUserLoggedIn } from "store/profile";

export default function useFetchRequiredData() {
  const { locale } = useTranslation();

  const location = useLocation();
  const dispatchWithLocale = useDispatchWithLocale();

  const searchParams = getSearchFormValuesFromQueryString(
    location.search,
    new Date()
  );

  const bookingInProgressSearchParams =
    useSelector(selectBookingInProgressSearchParams) || {};
  const {
    hotelCode: bookingInProgressHotelCode = "",
    promoCode: bookingInProgressPromoCode = "",
  } = bookingInProgressSearchParams;
  const propertyCode = searchParams.hotelCode || bookingInProgressHotelCode;
  const isPropertyBookableInLocale = useSelector(
    selectIsPropertyBookableInLocale({ propertyCode, locale })
  );
  const isExchanegRatesLoading = useSelector(
    selectIsRequestLoading(fetchExchangeRates.type)
  );
  const isPropertiesLoading = useSelector(
    selectIsRequestLoading(fetchProperties.type)
  );

  const isAnyPropertyContentMissing = useSelector((state) => [
      state.accommodationsContent,
      state.offersContent,
      state.propertyContent,
      state.taxes,
      state.upsells,
    ].some(({ data }) => !data[propertyCode]));

  const isAnyPropertyContentLoading = useSelector((state) => [
      state.accommodationsContent,
      state.offersContent,
      state.propertyContent,
      state.taxes,
      state.upsells,
    ].some(({ requestStates }) => requestStates[propertyCode] === API_REQUEST_STATES.REQUEST));

  const isWorkplacesLoading = useSelector(
    selectIsRequestLoading(fetchWorkplaces.type)
  );

  const promoCode = searchParams.promoCode || bookingInProgressPromoCode;

  const bookingInProgressId = useSelector(selectBookingInProgressId);
  const hasBookingInProgress = useSelector(selectHasBookingInProgress);

  const needToFetchBookingInProgress =
    Boolean(bookingInProgressId) && !hasBookingInProgress;
  const [isLoadingBooking, setIsLoadingBooking] = useState(
    needToFetchBookingInProgress
  );
  const hasCompletedBooking = useSelector(selectHasCompletedBooking);
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const employeeMode = isEmployeeRate({ promoCode });

  useEffect(() => {
    if (!employeeMode) {
      dispatchWithLocale(resetBookingFlow());
    }
  }, [employeeMode]);

  const showLoadingIndicator = Boolean(
    isLoadingBooking ||
      isExchanegRatesLoading ||
      isPropertiesLoading ||
      (propertyCode && isAnyPropertyContentLoading) ||
      (employeeMode && isWorkplacesLoading)
  );

  const localeForContentRequests = isPropertyBookableInLocale ? locale : "en";

  useEffect(() => {
    dispatchWithLocale(fetchProperties());
    dispatchWithLocale(fetchExchangeRates());

    return () => {
      dispatchWithLocale(fetchPropertiesCancel());
      dispatchWithLocale(fetchExchangeRatesCancel());
    };
  }, [locale]);

  useEffect(() => {
    if (employeeMode) {
      dispatchWithLocale(fetchWorkplaces());
    }

    return () => {
      if (employeeMode) {
        dispatchWithLocale(fetchWorkplacesCancel());
      }
    };
  }, [employeeMode, locale]);

  useEffect(() => {
    if (needToFetchBookingInProgress) {
      dispatchWithLocale(fetchBookingInProgress());
    }

    return () => {
      if (needToFetchBookingInProgress) {
        dispatchWithLocale(fetchBookingInProgressCancel());
      }
    };
  }, [needToFetchBookingInProgress, locale]);

  useEffect(() => {
    if (propertyCode && isAnyPropertyContentMissing) {
      dispatchWithLocale(
        fetchAllPropertyContent({
          propertyCode,
          locale: localeForContentRequests,
        })
      );
    }
    return () => {
      if (propertyCode && isAnyPropertyContentMissing) {
        dispatchWithLocale(fetchAllPropertyContentCancel({ propertyCode }));
      }
    };
  }, [propertyCode, isAnyPropertyContentMissing, localeForContentRequests]);

  useEffect(() => {
    const shouldFetchBookingMessages =
      propertyCode && searchParams.dates.checkIn && searchParams.dates.checkOut;
    if (shouldFetchBookingMessages) {
      dispatchWithLocale(
        fetchBookingMessages({
          propertyCode,
          bookingSteps: ["availability", "soldout", "confirmation"],
          checkinDate: searchParams.dates.checkIn,
          checkoutDate: searchParams.dates.checkOut,
          ratePlanCode: [
            searchParams.ratePlanCode,
            searchParams.offerOrsCode,
            searchParams.promoCode,
          ]
            .filter(Boolean)
            .join(","),
          locale: localeForContentRequests,
        })
      );
    }
    return () => {
      if (shouldFetchBookingMessages) {
        dispatchWithLocale(fetchBookingMessagesCancel());
      }
    };
  }, [
    propertyCode,
    searchParams.dates.checkIn,
    searchParams.dates.checkOut,
    searchParams.promoCode,
    localeForContentRequests,
  ]);

  useEffect(() => {
    if (!bookingInProgressId || hasBookingInProgress) {
      setIsLoadingBooking(false);
    }
  }, [bookingInProgressId, hasBookingInProgress]);

  return {
    hasBookingInProgress,
    hasCompletedBooking,
    showLoadingIndicator,
    employeeMode,
    isUserLoggedIn,
  };
}
