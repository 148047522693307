import { ofType } from "redux-observable";
import { of } from "rxjs";
import { mergeMap, switchMap, catchError, takeUntil } from "rxjs/operators";
import get from "lodash/get";

import { getSourceName } from "utils";
import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { getBookingById$ } from "api/tretail/booking";
import { retrieveBooking$ } from "api/tretail/bookingHistory";
import {
  getUpcomingTripData,
  getUpcomingTripDataCancel,
  getUpcomingTripDataFailed,
  fetchHistoricBookingFulfilled,
  getUpcomingTripDataFulfilled,
} from "store/bookings";
import { fetchProperties } from "store/properties";
import catchInternalServerError from "store/catchInternalServerError";
import { fetchAccommodationsContent } from "store/accommodationsContent";
import { fetchBookingComments } from "store/bookingComments";
import { fetchBookingMessages } from "store/bookingMessages";
import { fetchCalendarAvailability } from "store/calendarAvailability";
import { fetchCountriesContent } from "store/countriesContent";
import { fetchOffersContent } from "store/offersContent";
import { fetchPropertyContent } from "store/propertyContent";
import { fetchTaxesContent } from "store/taxesContent";
import { fetchTermsAndConditionsContent } from "store/termsAndConditionsContent";
import { getTermsAndConditionsOptions } from "store/bookings/epics/fetchHistoricBookingAndContent";
import { fetchGuestRequests } from "store/guestRequests";
import { fetchTransportationAmenitiesContent } from "store/transportationAmenitiesContent";
import { fetchCartRecommendations } from "store/cartRecommendations";

function retrieveBooking({
  reservationId,
  hotelCode,
  surname,
  creditCardExpiryDate,
  creditCardNumber,
  firstName,
  reservationStartDate,
  locale,
}) {
  return retrieveBooking$({
    reservationId,
    hotelCode,
    surname,
    creditCardExpiryDate,
    creditCardNumber,
    firstName,
    reservationStartDate,
    locale,
  }).pipe(
    switchMap(({ bookingId }) => getBookingById$({
        bookingId,
        locale,
      }))
  );
}

const handleHistoricBookingError = ({ reservationId, response = {} }) =>
  of(
    getUpcomingTripDataFailed({
      reservationId,
      apiErrors: response?.apiErrors || [],
      supplierErrors: response?.supplierErrors || [],
    })
  );

export default function getUpcomingTripDataEpic(action$) {
  return action$.pipe(
    ofType(getUpcomingTripData.type),
    switchMap(
      ({
        payload: {
          reservationId,
          propertyCode,
          hotelCode: _hotelCode,
          surname,
          locale,
        },
      }) => {
        const hotelCode = _hotelCode ?? propertyCode;
        return ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() => retrieveBooking({
              reservationId,
              hotelCode,
              surname,
              locale,
            }).pipe(
              mergeMap((booking) => {
                const ratePlanCode = get(booking, [
                  "hotelProducts",
                  0,
                  "roomRate",
                  "ratePlanCode",
                ]);

                const { numberAdults = 0, numberChildren = 0 } = get(
                  booking,
                  ["hotelProducts", 0, "guestCount"],
                  {}
                );

                return [
                  fetchTransportationAmenitiesContent({
                    reservationId,
                    locale,
                    hotelCode,
                    startDate: booking.startDate,
                    endDate: booking.endDate,
                    adults: numberAdults,
                    children: numberChildren,
                    sourceName: getSourceName(booking),
                  }),
                  fetchCartRecommendations({
                    locale,
                    hotelCode,
                  }),
                  fetchGuestRequests({
                    reservationId,
                    surname,
                    propertyCode: hotelCode,
                    locale,
                  }),
                  fetchHistoricBookingFulfilled(booking),
                  fetchBookingComments({
                    bookingId: booking.bookingId,
                    reservationId,
                    locale,
                  }),
                  fetchProperties({ locale }),
                  fetchCountriesContent({ locale }),
                  fetchPropertyContent({ locale, propertyCode: hotelCode }),
                  fetchAccommodationsContent({
                    locale,
                    propertyCode: hotelCode,
                  }),
                  fetchTaxesContent({ propertyCode: hotelCode, locale }),
                  fetchOffersContent({ propertyCode: hotelCode, locale }),
                  fetchBookingMessages({
                    propertyCode: hotelCode,
                    bookingSteps: ["availability"],
                    checkinDate: booking.startDate,
                    checkoutDate: booking.endDate,
                    ratePlanCode,
                    locale,
                  }),
                  fetchTermsAndConditionsContent({
                    hotelCode,
                    locale,
                    optionsList: [getTermsAndConditionsOptions(booking)],
                  }),
                  fetchCalendarAvailability({ locale }),
                  getUpcomingTripDataFulfilled(booking),
                ];
              }),

              catchInternalServerError(),

              catchError(({ response }) =>
                handleHistoricBookingError({ reservationId, response })
              ),

              takeUntil(action$.pipe(ofType(getUpcomingTripDataCancel.type)))
            ))
        );
      }
    )
  );
}
