import React, { useState, useEffect } from "react";
import debounce from "lodash/debounce";

import { isMobileApp, isMobileDevice } from "utils";

export const MediaContext = React.createContext();

function detectMedia(breakpoints, width) {
  return Object.keys(breakpoints).reduce(
    (acc, label) => ({
        ...acc,
        isGreaterThan: {
          ...acc.isGreaterThan,
          [label]: width >= breakpoints[label],
        },
        isLessThan: {
          ...acc.isLessThan,
          [label]: width < breakpoints[label],
        },
      }),
    {
      isMobileApp: isMobileApp(),
      isMobileDevice: isMobileDevice(),
      isGreaterThan: {},
      isLessThan: {},
    }
  );
}

function MediaContextProvider({
  breakpoints = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200,
  },
  children,
}) {
  const [media, setMedia] = useState(
    detectMedia(breakpoints, window.innerWidth)
  );

  useEffect(() => {
    const handleWindowResize = debounce(() => {
      setMedia(detectMedia(breakpoints, window.innerWidth));
    }, 200);

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [JSON.stringify(breakpoints)]);

  return (
    <MediaContext.Provider value={media}>{children}</MediaContext.Provider>
  );
}

export default MediaContextProvider;
