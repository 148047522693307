import { ofType } from "redux-observable";
import {
  catchError,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import get from "lodash/get";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { updateHotelProductPostBookingComments$ } from "api/tretail/bookingProductCust";
import { modifyHotelProductCust$ } from "api/tretail/bookingManagementCust";
import catchInternalServerError from "store/catchInternalServerError";
import { selectBookingByReservationId } from "store/bookings/bookings.selectors";
import {
  updatePostBookingCommentsFailed,
  fetchBookingCommentsFulfilled,
} from "../../bookingComments/bookingComments.slice";
import {
  submitBookingPreferences,
  submitBookingPreferencesFulfilled,
  submitBookingPreferencesFailed,
  submitBookingPreferencesCancel,
} from "../bookings.slice";
import fetchBookingWithCommentsById$ from "./fetchBookingWithCommentsById";

export function submitBookingPreferences$({
  bookingId,
  checkoutModifyToken,
  comments,
}) {
  return updateHotelProductPostBookingComments$({
    bookingId,
    checkoutModifyToken,
    comments,
  });
}

export default function submitBookingPreferencesEpic(action$, state$) {
  return action$.pipe(
    ofType(submitBookingPreferences.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: {
            arrivalTime: nextArrivalTime,
            bookingId,
            comments,
            locale,
            ppMode,
            ppUrl,
            reservationId,
            sendGuestEmail,
            specialRequestChanged,
            checkoutModifyToken,
          },
        },
        state,
      ]) => {
        const arrivalTime =
          nextArrivalTime ||
          get(selectBookingByReservationId({ reservationId })(state), [
            "hotelProducts",
            0,
            "checkInTime",
          ]);
        const inProgressParams =
          state?.bookings?.bookingInProgressSearchParams || {};

        const hotelCode = inProgressParams?.hotelCode || "";
        const ppMode1 = ppMode || inProgressParams?.ppMode || false;
        const ppUrl1 = ppUrl || inProgressParams?.ppUrl || "";
        let searchParams = {};
        if (ppMode1) {
          searchParams = { ppMode: ppMode1, ppUrl: ppUrl1 };
        }
        const bookingEmailOptions = {
          inBookingFlow: true,
          sendGuestEmail,
          specialRequestChanged,
        };

        return ajaxWithHealthCheck$({
          locale,
          propertyCode: hotelCode,
          searchParams,
        }).pipe(
          switchMap(() => submitBookingPreferences$({
              bookingId,
              locale,
              checkoutModifyToken,
              comments,
            }).pipe(
              switchMap(() => modifyHotelProductCust$({
                  bookingId,
                  locale,
                  arrivalTime,
                  bookingEmailOptions,
                }).pipe(
                  switchMap(() => fetchBookingWithCommentsById$({
                      bookingId,
                      locale,
                    }).pipe(
                      mergeMap(
                        ({
                          booking,
                          preBookingComments,
                          postBookingComments,
                        }) => [
                            fetchBookingCommentsFulfilled({
                              bookingId,
                              reservationId,
                              preBookingComments,
                              postBookingComments,
                            }),

                            submitBookingPreferencesFulfilled({
                              booking,
                            }),
                          ]
                      )
                    ))
                )),

              catchInternalServerError(),

              catchError(({ response = {} }) => [
                updatePostBookingCommentsFailed({
                  apiErrors: response?.apiErrors || [],
                  supplierErrors: response?.supplierErrors || [],
                }),
                submitBookingPreferencesFailed(),
              ]),

              takeUntil(
                action$.pipe(ofType(submitBookingPreferencesCancel.type))
              )
            ))
        );
      }
    )
  );
}
