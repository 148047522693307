import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "hooks";
import SignaturePad from "react-signature-canvas";
import { Button } from "Profile/components";
import useTextInput from "../../Profile/hooks/useTextInput";

const width = 225;

function FormSignature(props) {
  const { t } = useTranslation();
  const sigPad = useRef({});
  const { label } = useTextInput(props);

  const { name, clearLabel, required, register, trigger } = props;

  const [trimmedValue, setTrimmedValue] = useState("");
  const [typedSignature, setTypedSignature] = useState("");
  const [typedSignatureFocus, setTypedSignatureFocus] = useState(false);

  const trim = () => sigPad.current.getTrimmedCanvas().toDataURL("image/png");
  const updateTrimmed = () => {
    setTrimmedValue(sigPad.current.isEmpty() ? "" : trim());
  };
  const clear = () => {
    sigPad.current.clear();
    setTypedSignature("");
    setTrimmedValue("");
  };

  const toggleTypedSignatureFocus = (show) => {
    setTypedSignatureFocus(show);
  };
  const updateTypedSignature = (inputVal) => {
    sigPad.current.clear();
    const canvas = sigPad.current.getCanvas().getContext("2d");
    canvas.font = "36px cursive";
    canvas.fillText(inputVal, 10, 50, width - 20);
    setTypedSignature(inputVal);
    if (inputVal) {
      // calling this directly because the .isEmpty() call isn't reflecting the updated text correctly
      setTrimmedValue(trim());
    }
  };

  useEffect(() => {
    trigger(name);
  }, [trimmedValue]);

  return (
    <div className="FormSignature">
      <input
        className="FormSignature-typed"
        aria-label={t("Type your signature here")}
        style={{ opacity: typedSignatureFocus ? 1 : 0, border: "none" }}
        value={typedSignature}
        onChange={(e) => updateTypedSignature(e.target.value)}
        onFocus={() => toggleTypedSignatureFocus(true)}
        onBlur={() => toggleTypedSignatureFocus(false)}
      />
      <div className="label-and-field" style={{ width: `${width}px` }}>
        {label && <label className="ty-c4">{label}</label>}
        <br />
        <SignaturePad
          ref={sigPad}
          onEnd={() => updateTrimmed()}
          canvasProps={{
            height: 72,
            width,
            className: "FormSignature-canvas",
          }}
        />
      </div>
      <div>
        <Button kind="link" onClick={clear}>
          {clearLabel}
        </Button>
      </div>
      <input
        name={name}
        type="hidden"
        style={{ display: "none" }}
        value={trimmedValue}
        ref={register}
        required={required}
      />
    </div>
  );
}

export default FormSignature;
