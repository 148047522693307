import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";

import getTransportationAmenitiesContent$ from "api/content/getTransportationAmenitiesContent/getTransportationAmenitiesContent";
import {
  fetchTransportationAmenitiesContent,
  fetchTransportationAmenitiesContentFulfilled,
  fetchTransportationAmenitiesContentFailed,
  fetchTransportationAmenitiesContentCancel,
} from "../transportationAmenitiesContent.slice";

export default function fetchTransportationAmenitiesContentEpic(action$) {
  return action$.pipe(
    ofType(fetchTransportationAmenitiesContent.type),
    switchMap(({ payload: args }) => getTransportationAmenitiesContent$(args).pipe(
        map((data) =>
          fetchTransportationAmenitiesContentFulfilled({
            data,
            args,
          })
        ),
        catchError((error) =>
          of(fetchTransportationAmenitiesContentFailed(error))
        ),
        takeUntil(
          action$.pipe(ofType(fetchTransportationAmenitiesContentCancel.type))
        )
      ))
  );
}
