import { API_REQUEST_STATES } from "./constants";

export const selectIsRequestMade = (apiRequest) => (state) =>
  Boolean(state.apiRequestStates[apiRequest]);

export const selectIsRequestLoading = (apiRequest) => (state) =>
  state.apiRequestStates[apiRequest] === API_REQUEST_STATES.REQUEST;

export const selectAreSomeRequestsLoading = (apiRequests) => (state) =>
  apiRequests.some(
    (apiRequest) =>
      state.apiRequestStates[apiRequest] === API_REQUEST_STATES.REQUEST
  );

export const selectWereSomeRequestsFulfilled = (apiRequests) => (state) =>
  apiRequests.some(
    (apiRequest) =>
      state.apiRequestStates[apiRequest] === API_REQUEST_STATES.FULFILLED
  );

export const selectIsRequestToBeMadeOrLoading = (apiRequest) => (state) =>
  !selectIsRequestMade(apiRequest)(state) ||
  selectIsRequestLoading(apiRequest)(state);

export const selectIsRequestFulfilled = (apiRequest) => (state) =>
  state.apiRequestStates[apiRequest] === API_REQUEST_STATES.FULFILLED;

export const selectIsRequestFailed = (apiRequest) => (state) =>
  state.apiRequestStates[apiRequest] === API_REQUEST_STATES.FAILED;

export const selectIsRequestCancelled = (apiRequest) => (state) =>
  state.apiRequestStates[apiRequest] === API_REQUEST_STATES.CANCELLED;

export const selectIsLoadingRequests =
  (apiRequests = []) =>
  (state) => apiRequests.map(selectIsRequestLoading).some((fn) => fn(state));

export const selectIsLoadingOrToBeMadeRequests =
  (apiRequests = []) =>
  (state) => apiRequests
      .map(selectIsRequestToBeMadeOrLoading)
      .some((fn) => fn(state));
