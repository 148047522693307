import { ajax } from "rxjs/ajax";
import { map, pluck } from "rxjs/operators";
import isEmpty from "lodash/isEmpty";

import env from "config/env";
import { baseGetRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

const isNotEmpty = (v) => !isEmpty(v);

export const getHistoricBookingSummaries$ = ({ resultSetId, locale }) =>
  ajax({
    ...baseGetRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookingHistory/resultSets/${resultSetId}`,
  }).pipe(
    pluck("response"),
    map((response) => ({
        ...response,
        bookingSummaries: response.bookingSummaries.filter(isNotEmpty),
      }))
  );

export default getHistoricBookingSummaries$;
