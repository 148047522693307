/* eslint-disable no-unused-vars */
import queryString from "query-string";

export default function exposeBookingInProgressData(inProgressData) {
  window.FS = window.FS || {};
  window.FS.BOOKING_IN_PROGRESS = window.FS.BOOKING_IN_PROGRESS || {};
  window.FS.BOOKING_IN_PROGRESS.data = window.FS.BOOKING_IN_PROGRESS.data || {}; // object that exposes everything need by SaleCycle

  window.FS.BOOKING_IN_PROGRESS.data.hotelCode = inProgressData.hotelCode; // String. hotel ows code
  [window.FS.BOOKING_IN_PROGRESS.data.checkIn] =
    inProgressData.bookingData.booking_arrival_array; // String. start date (format: YYYY-MM-DD)
  [window.FS.BOOKING_IN_PROGRESS.data.checkOut] =
    inProgressData.bookingData.booking_departure_array; // String. start date (format: YYYY-MM-DD)
  if (inProgressData.bookingData.promocode === "Yes") {
    [window.FS.BOOKING_IN_PROGRESS.data.promoCode] =
      inProgressData.bookingData.room_package_ows_code_array; // String or undefine. only populated if provided in the search
  }

  // Array. Each entry is an object with the data for the respective room.
  const roomArray = inProgressData.bookingData.room_package_ows_code_array.map(
    (offerCode, index) => ({
        adults: inProgressData.bookingData.room_adults_array[index], // Number. number of adults for this room
        children: inProgressData.bookingData.room_children_array[index], // Number. number of children for this room
        roomCode: inProgressData.bookingData.room_code_array[index], // String. room ows code
        offerCode, // String. offer ows code
      })
  );

  window.FS.BOOKING_IN_PROGRESS.data.rooms = roomArray; // Array. Each entry is an object with the data for the respective room.

  // not use for creating the URL, but additional data points
  window.FS.BOOKING_IN_PROGRESS.data.hotelCity = inProgressData.city; // String. City the hotel is located in
  window.FS.BOOKING_IN_PROGRESS.data.emp = !!inProgressData.employeeMode; // Boolean. True if booking as an employee, otherwise false
  window.FS.BOOKING_IN_PROGRESS.data.pp = !!inProgressData.ppMode; // Boolean. True if booking as a Preferred Partner, otherwise false

  // function that should return a fully formed URL with all the params needed to get the guest back to the same step.
  window.FS.BOOKING_IN_PROGRESS.createDeepLink = () => {
    let formedUrl = inProgressData.initialPageData.page_url.split("?")[0];
    const qsObject = {
      hotelCode: window.FS.BOOKING_IN_PROGRESS.data.hotelCode,
      checkIn: window.FS.BOOKING_IN_PROGRESS.data.checkIn,
      checkOut: window.FS.BOOKING_IN_PROGRESS.data.checkOut,
      adults: inProgressData.bookingData.room_adults_array,
      children: inProgressData.bookingData.room_children_array,
      promoCode: window.FS.BOOKING_IN_PROGRESS.data.promoCode,
      roomOwsCode: inProgressData.bookingData.room_code_array,
      roomOfferCode: inProgressData.bookingData.room_package_ows_code_array,
    };

    const parsedQuery = queryString.stringify(qsObject);

    formedUrl = `${formedUrl}?${parsedQuery}`;

    return formedUrl;
  };
}
