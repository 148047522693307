/* eslint-disable no-control-regex */
export function mergeRegex(
  regexs = [],
  { mustMatchAll = true } = { mustMatchAll: true }
) {
  const regexSources = regexs.map((regex) => regex.source);

  if (!mustMatchAll) {
    return new RegExp(regexSources.join("|"));
  }
  return new RegExp(
    `^${
      regexSources
        .map((source) => new RegExp(`(?=${source})`))
        .reduce((acc, curr) => new RegExp((acc.source || "") + curr.source)).source
    }.*$`
  );
}

export const nonDoubleByte = /^[\u0000-\u00ff]+$/;

export const noDigits = mergeRegex([nonDoubleByte, /^[\D]*$/]);

export const onlyDigits = mergeRegex([nonDoubleByte, /^[\d]*$/]);

export const alphanumeric = mergeRegex([nonDoubleByte, /^[\da-zA-Z]+$/]);

export const alphanumericWithSpaces = mergeRegex([
  nonDoubleByte,
  /^[\d\sa-zA-Z]+$/,
]);

export const fullName = mergeRegex([nonDoubleByte, /^[.\-\sa-zA-Z']+$/]);

export const telephoneNumber = mergeRegex([
  nonDoubleByte,
  /^[\d\s+\-()]{1,17}$/,
]);

export const addressLine = mergeRegex([nonDoubleByte, /^[\w\-_\s.#,]*$/i]);

export const city = mergeRegex([nonDoubleByte, /^[\w\-_\s.#]*$/i]);

export const email =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

export const password = mergeRegex([
  nonDoubleByte,
  /^[^<>&"'\\\x00-\x1F\x7F]{6,13}$/,
]);

export const dateMMDDYYYY = mergeRegex([
  nonDoubleByte,
  /^\d{2}\/\d{2}\/\d{4}$/,
]);

export function state(countryCode = "") {
  switch (countryCode.toLowerCase()) {
    case "us":
      return mergeRegex([nonDoubleByte, /^\D{2}$/]);

    case "gb":
      return mergeRegex([nonDoubleByte, /^[^<>]{0,40}$/]);

    case "au":
      return mergeRegex([nonDoubleByte, /^\D{2,3}$/]);

    default:
      return mergeRegex([nonDoubleByte, /^[^<>]{0,40}$/]);
  }
}

export function postcode(countryCode = "") {
  switch (countryCode.toLowerCase()) {
    case "us":
      return mergeRegex([nonDoubleByte, /^[a-zA-Z0-9\-\s]+$/i]);

    case "gb":
      return mergeRegex([nonDoubleByte, /^[a-zA-Z0-9\-\s]+$/i]);

    case "au":
      return mergeRegex([nonDoubleByte, /^[a-zA-Z0-9\-\s]+$/i]);

    default:
      return mergeRegex([nonDoubleByte, /^[^<>]+$/]);
  }
}

// CC ccRegX
export const creditCard = {
  unionpay:
    /^(622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5])|62[4-6]|628[2-8])/,
  visa: /^4/,
  mastercard: /^5[1-5]/,
  amex: /^3[47]/,
  diners: /^3(?:0[0-5]|[68][0-9])[0-9]/,
  discover: /^(6011|64[4-9]|65)/,
  jcb: /^35(2[89]|[3-8][0-9])/,
};

const EN_SHORT_DATE_REGEX =
  /^(([0][1-9]{1})|([1][0,1,2]{1}))\/(([0][1-9]{1})|([1-2]\d{1})|([3][0,1]{1}))\/(([1]{1}[9]{1}\d{2})|([2-9]{1}\d{3}))$/;
const ML_SHORT_DATE_REGEX =
  /^(([0][1-9]{1})|([1-2]\d{1})|([3][0,1]{1}))\/(([0][1-9]{1})|([1][0,1,2]{1}))\/(([1]{1}[9]{1}\d{2})|([2-9]{1}\d{3}))$/;
const ZH_SHORT_DATE_REGEX =
  /^(([1]{1}[9]{1}\d{2})|([2-9]{1}\d{3}))年(([0][1-9]{1})|([1][0,1,2]{1}))月(([0][1-9]{1})|([1-2]\d{1})|([3][0,1]{1}))日$/;
const KR_SHORT_DATE_REGEX =
  /^(([1]{1}[9]{1}\d{2})|([2-9]{1}\d{3}))년(([0][1-9]{1})|([1][0,1,2]{1}))월(([0][1-9]{1})|([1-2]\d{1})|([3][0,1]{1}))일$/;

export function getRegexForDateFormat(locale) {
  switch (locale) {
    case "en":
      return EN_SHORT_DATE_REGEX;

    case "kr":
      return KR_SHORT_DATE_REGEX;

    case "jp":
    case "zh_hant":
    case "zh":
      return ZH_SHORT_DATE_REGEX;

    case "ar":
    case "es":
    case "es-es":
    case "gr":
    case "ru":
    default:
      return ML_SHORT_DATE_REGEX;
  }
}

const OPTIONAL_EN_SHORT_DATE_REGEX =
  /^$|(([0][1-9]{1})|([1][0,1,2]{1}))\/(([0][1-9]{1})|([1-2]\d{1})|([3][0,1]{1}))\/(([1]{1}[9]{1}\d{2})|([2-9]{1}\d{3}))$/;
const OPTIONAL_ML_SHORT_DATE_REGEX =
  /^$|(([0][1-9]{1})|([1-2]\d{1})|([3][0,1]{1}))\/(([0][1-9]{1})|([1][0,1,2]{1}))\/(([1]{1}[9]{1}\d{2})|([2-9]{1}\d{3}))$/;
const OPTIONAL_ZH_SHORT_DATE_REGEX =
  /^$|(([1]{1}[9]{1}\d{2})|([2-9]{1}\d{3}))年(([0][1-9]{1})|([1][0,1,2]{1}))月(([0][1-9]{1})|([1-2]\d{1})|([3][0,1]{1}))日$/;
const OPTIONAL_KR_SHORT_DATE_REGEX =
  /^$|(([1]{1}[9]{1}\d{2})|([2-9]{1}\d{3}))년(([0][1-9]{1})|([1][0,1,2]{1}))월(([0][1-9]{1})|([1-2]\d{1})|([3][0,1]{1}))일$/;

export function getRegexForOptionalDateFormat(locale) {
  switch (locale) {
    case "en":
      return OPTIONAL_EN_SHORT_DATE_REGEX;

    case "kr":
      return OPTIONAL_KR_SHORT_DATE_REGEX;

    case "jp":
    case "zh_hant":
    case "zh":
      return OPTIONAL_ZH_SHORT_DATE_REGEX;

    case "ar":
    case "es":
    case "es-es":
    case "gr":
    case "ru":
    default:
      return OPTIONAL_ML_SHORT_DATE_REGEX;
  }
}
