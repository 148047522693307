import { map } from "rxjs/operators";
import { forkJoin } from "rxjs";

import onlyUnique from "utils/onlyUnique";
import contentAjax from "api/content/contentAjax";

export default function getTaxesContent$({
  locale = "en",
  propertyCode,
  propertyCodes = [],
  retryInEN,
}) {
  return forkJoin(
    [...propertyCodes, propertyCode]
      .filter(Boolean)
      .filter(onlyUnique)
      .map((hotelCode) => contentAjax({
          locale,
          url: `/properties/${hotelCode}/taxes`,
          retryInEN,
        }).pipe(map((ajaxResponse) => ({ ajaxResponse, hotelCode }))))
  );
}
