import React from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "hooks";
import { selectAddresses } from "store/profile";
import { PERSONAL_INFO_TYPE } from "fixtures/constants";
import { selectCountriesContent } from "store/countriesContent";
import { ToggleBlock } from "Profile/components";
import AddAddress from "./AddAddress";
import EditAddress from "./EditAddress";
import useBlock from "../../hooks/useBlock";
import ToggleBlockLabel from "../ToggleBlockLabel";

const MAX_NUMBER_OF_ADDRESSES = 4;

function Addresses() {
  const { t } = useTranslation();

  const { canAdd, items: addresses } = useBlock({
    selector: selectAddresses,
    max: MAX_NUMBER_OF_ADDRESSES,
  });

  const countries = useSelector(selectCountriesContent);

  const getCountryName = (countryCode) =>
    (countries.find(({ iso2 }) => iso2 === countryCode) || { name: "" }).name;

  const constructAddress = (address_) =>
    [
      address_.addressLines,
      address_.city,
      address_.state,
      address_.postcode,
      getCountryName(address_.country.code),
    ]
      .filter(Boolean)
      .join(", ");

  return (
    <div id="address" className="view--profile-edit__block">
      <div className="fs-profile-addresses">
        <div className="view--profile-edit__block__title-row">
          <ToggleBlock
            type="add"
            label={t("Address")}
            buttonLabel={t("Add")}
            buttonAriaLabel={t("Add a new address")}
            showButton={canAdd}
          >
            {({ close }) => <AddAddress closeForm={close} />}
          </ToggleBlock>
        </div>

        {addresses.map((address, index) => (
          <ToggleBlock
            key={address.salesForceId || index}
            type="profile-value-edit"
            buttonLabel={t("Add")}
            buttonAriaLabel={t("Add a new email address")}
            label={({ toggle, isOpen }) => (
                <ToggleBlockLabel
                  label={PERSONAL_INFO_TYPE()[address.addressType]}
                  ariaLabel={t("Edit your address")}
                  secondaryLabel={address.defaultAddress && "primary"}
                  selectedValue={constructAddress(address)}
                  toggleForm={toggle}
                  isActive={isOpen}
                />
              )}
          >
            {({ close }) => (
              <EditAddress
                identifier={`edit-address-${index}`}
                address={address}
                closeForm={close}
                canBeDeleted={addresses.length > 1 && !address.defaultAddress}
                isNotLast={addresses.length > 1}
              />
            )}
          </ToggleBlock>
        ))}
      </div>
    </div>
  );
}

export default Addresses;
