import { useState, useEffect, useContext } from "react";
import { getConfirmationData } from "rbf-content-mapping";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import {
  parse,
  isBefore,
  endOfMonth,
  isValid as dateFnsIsValid,
} from "date-fns";
import get from "lodash/get";

import env from "config/env";
import { useDispatchWithLocale, useTranslation } from "hooks";
import {
  completeBooking,
  completeBookingFailed,
} from "store/bookings/bookings.slice";
import { RUSSIA, STEPS } from "fixtures/constants";
import {
  selectAreSomeRequestsLoading,
  selectIsRequestLoading,
  selectIsRequestToBeMadeOrLoading,
} from "store/apiRequestStates";
import { selectTermsAndConditionsForCurrentBooking } from "store/termsAndConditionsContent";
import {
  alipayPaymentFailedClear,
  selectAlipayError,
  selectBookingInProgress,
  selectBookingInProgressSearchParams,
  selectBookingInProgressStatePhase,
  selectBookingsApiErrors,
  selectBookingSupplierErrors,
  selectUpgradedRooms,
  upgradeRooms,
} from "store/bookings";
import getWebChatStatus from "utils/getWebChatStatus";
import { selectChatStatus } from "store/appStatus";
import {
  fetchPaymentOptions,
  fetchPaymentOptionsCancel,
  selectPaymentOptions,
} from "store/paymentOptions";
import { selectUpsellsContent } from "store/upsellsContent";
import { selectTaxesContent } from "store/taxesContent";
import {
  fetchGlobalMailSubscriptions,
  fetchGlobalMailSubscriptionsCancel,
} from "store/globalMailSubscriptions";
import { selectGlobalSettings } from "store/globalSettings";
import { selectPropertyAccommodationsContent } from "store/accommodationsContent";
import { selectPropertyContent } from "store/propertyContent";
import { selectExchangeRates, setCurrencyCode } from "store/exchangeRates";
import { selectPropertyOffersContent } from "store/offersContent";
import { selectSearchResults } from "store/searchResults";
import {
  selectIsUserLoggedIn,
  selectHasExplicitOptInGlobalMailSubscription,
  selectIsUserPersistent,
} from "store/profile";
import isBookingEmployeeRate from "BookingFlow/utils/isBookingEmployeeRate";
import doesMeetRussianCriteria from "utils/doesMeetRussianCriteria";
import { getGeoIPCode } from "utils/geoIP";
import {
  getConsentAcceptanceTimestamp,
  formValuesToPaymentOptionsParams,
} from "utils/utils";
import { MediaContext } from "contexts/MediaContext";
import { setParams } from "store/webChat";
import { SECTIONS, PAYMENT_METHODS } from "./constants";

const { API_BASE_URL, SHOW_SIGN_IN_BLOCK } = env;

export default function useConfirmYourStay() {
  const { locale } = useTranslation();
  const media = useContext(MediaContext);
  const { isMobileApp, isMobileDevice } = media;

  const dispatchWithLocale = useDispatchWithLocale();
  const location = useLocation();

  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const isUserPersistent = useSelector(selectIsUserPersistent);
  const hasExplicitOptInGlobalMailSubscription = useSelector(
    selectHasExplicitOptInGlobalMailSubscription
  );
  const hasExplicitOptIn =
    isUserLoggedIn && hasExplicitOptInGlobalMailSubscription;

  const areSomeRequestsLoading = useSelector(
    selectAreSomeRequestsLoading([
      completeBooking.type,
      upgradeRooms.type,
      fetchPaymentOptions.type,
    ])
  );
  const upgradedRooms = useSelector(selectUpgradedRooms);
  const alipayError = useSelector(selectAlipayError);

  const showLoadingIndicator = areSomeRequestsLoading;

  const queryParams = queryString.parse(location.search || "");
  const bookingType = queryParams.bookingType || SECTIONS.MYSELF;

  const {
    hotelCode,
    ppMode: isPreferredPartners = false,
    ppUrl: ppReturnUrl,
    promoCode = "",
    dates,
  } = useSelector(selectBookingInProgressSearchParams) || {};

  const bookingInProgress = useSelector(selectBookingInProgress);

  const bookingCurrencyCode = get(bookingInProgress, [
    "price",
    "total",
    "cash",
    "currencyCode",
  ]);
  const [currencyCode, setCurrencyCode_] = useState(
    bookingCurrencyCode || "USD"
  );

  const setCurrencyCodeAndDispatchAction = (value) => {
    setCurrencyCode_(value);
    dispatchWithLocale(setCurrencyCode({ currencyCode: value }));
  };

  const employeeMode = isBookingEmployeeRate(bookingInProgress);

  const termsAndConditions = useSelector(
    selectTermsAndConditionsForCurrentBooking({
      locale,
      currentBooking: bookingInProgress,
    })
  ).filter(Boolean);

  const propertyContent = useSelector(selectPropertyContent(hotelCode)) || {};

  const confirmYourStayData = {
    ...getConfirmationData({
      confirmationState: {
        currency: currencyCode,
        upsellsHandled: upgradedRooms.length > 0,
      },
      content: {
        accommodations: useSelector(
          selectPropertyAccommodationsContent(hotelCode)
        ) || { bookableAccommodations: [] },
        globalSettings: useSelector(selectGlobalSettings) || {},
        exchangeRates: {
          exchangeRates: useSelector(selectExchangeRates) || {},
        },
        offers: useSelector(selectPropertyOffersContent(hotelCode)) || {},
        taxes: { taxes: useSelector(selectTaxesContent(hotelCode)) || [] },
        termsAndConditions,
        upsells: {
          upsells: useSelector(selectUpsellsContent(hotelCode)) || [],
        },
        property: propertyContent,
      },
      employee: employeeMode,
      language: locale,
      oj: {
        apiErrors: useSelector(selectBookingsApiErrors),
        availabilityResults: useSelector(selectSearchResults),
        results: bookingInProgress,
        supplierErrors: useSelector(selectBookingSupplierErrors),
      },
      pp: isPreferredPartners,
      promoCode,
    }),
    promoCode,
    dates,
  };

  useEffect(() => {
    dispatchWithLocale(setParams(confirmYourStayData?.webChat || {}));
  }, [JSON.stringify(confirmYourStayData?.webChat || {})]);

  const { suppressPaymentOptions } = confirmYourStayData;

  const completeBookingLoading = useSelector(
    selectIsRequestLoading(completeBookingFailed.type)
  );
  const bookingInProgressPhase = useSelector(selectBookingInProgressStatePhase);
  const { hotelProducts } = useSelector(selectBookingInProgress) || {};

  // Fetch the paymentOptions data if the mapping layer indicates Alipay is available
  useEffect(() => {
    if (confirmYourStayData.alipay) {
      dispatchWithLocale(
        fetchPaymentOptions(confirmYourStayData.alipay.paymentOptionParams)
      );
    }

    return () => {
      if (confirmYourStayData.alipay) {
        dispatchWithLocale(fetchPaymentOptionsCancel());
      }
    };
  }, [JSON.stringify(confirmYourStayData?.alipay || {})]);

  useEffect(() => () => {
      dispatchWithLocale(alipayPaymentFailedClear());
    }, []);

  useEffect(() => {
    if (!hasExplicitOptIn) {
      dispatchWithLocale(fetchGlobalMailSubscriptions());
    }

    return () => {
      if (!hasExplicitOptIn) {
        dispatchWithLocale(fetchGlobalMailSubscriptionsCancel());
      }
    };
  }, [hasExplicitOptIn, locale]);

  useEffect(() => {
    const beforeunloadHandler = (event) => {
      if (
        completeBookingLoading &&
        bookingInProgressPhase?.data?.formValues?.paymentMethod !== "alipay"
      ) {
        event.preventDefault();
        /* eslint no-param-reassign: "error" */
        event.returnValue =
          "Confirmation in progress, are you sure you want to exit?";
        return event.returnValue;
      }
      return false;
    };
    const visibilityChangeHandler = () => {
      if (
        completeBookingLoading &&
        bookingInProgressPhase?.data?.formValues?.paymentMethod !== "alipay" &&
        document.visibilityState === "hidden"
      ) {
        navigator.sendBeacon(
          `${API_BASE_URL}/abandoned/reservation`,
          new Blob(
            [
              JSON.stringify({
                error: "makeReservationClosed",
                context: { hotelProducts },
              }),
            ],
            { type: "application/json" }
          )
        );
      }
    };

    if (!!navigator && !!navigator.sendBeacon) {
      window.addEventListener("beforeunload", beforeunloadHandler);
      window.addEventListener("visibilitychange", visibilityChangeHandler);
    }

    return () => {
      window.removeEventListener("beforeunload", beforeunloadHandler);
      window.removeEventListener("visibilitychange", visibilityChangeHandler);
    };
  }, [completeBookingLoading, bookingInProgressPhase, hotelProducts]);

  // Connect to the paymentOptions data in redux
  const paymentOptions = useSelector(selectPaymentOptions);

  // Check if Alipay is an allowed payment option
  // AJD: TODO - Mapping Layer now determines whether Alipay is enabled, but may need some extra logic to cover 'Short Lead Time' bookings (BE20-425)
  const allowAlipay =
    (isMobileApp || isMobileDevice) &&
    locale === "zh" &&
    confirmYourStayData.alipay &&
    paymentOptions.alipayChina?.enabled;

  const [russianCitizenHandler, setRussianCitizenHandler] = useState(undefined);
  const closeRussianCitizenModal = () => setRussianCitizenHandler(undefined);
  const [alipaySubmitHandler, setAlipaySubmitHandler] = useState(undefined);
  const [isAlipayModalOpen, setIsAlipayModalOpen] = useState(false);

  // Check if we are awaiting payment options info, so we can delay rendering the relevant part of the form if needed
  const awaitingPaymentOptions = useSelector(
    selectIsRequestToBeMadeOrLoading(fetchPaymentOptions.type)
  );
  const arePaymentOptionsLoading =
    confirmYourStayData.alipay && awaitingPaymentOptions;

  const webChatStatus = getWebChatStatus({
    currentState: useSelector(selectChatStatus),
    isEnabled: confirmYourStayData?.webChat?.enabled,
    isUserLoggedIn,
    step: STEPS.THREE,
    ...media,
  });

  const dispatchMakeReservation = (formValues) =>
    dispatchWithLocale(
      completeBooking({
        contactType: bookingType,
        ppMode: isPreferredPartners,
        employeeMode,
        suppressPaymentOptions,
        isUserLoggedIn,
        isUserPersistent,
        ...formValues,
      })
    );

  const onRussianCitizenSubmit =
    (formValues) =>
    () =>
    ({ isRussianCitizen }) => {
      closeRussianCitizenModal();
      dispatchMakeReservation({
        ...formValues,
        guestDetails: {
          ...formValues.guestDetails,
          ...(isRussianCitizen
            ? {
                nationality: RUSSIA.CODE,
                consentAcceptanceTimestamp: getConsentAcceptanceTimestamp(),
              }
            : {}),
        },
      });
    };

  const onAlipaySubmit = (formValues) => () => (isDesktop) => {
    const redirectLocation = isDesktop
      ? paymentOptions.alipayChina.desktopPaymentUrl
      : paymentOptions.alipayChina.mobilePaymentUrl;

    setIsAlipayModalOpen(false);

    // Dispatch an action to trigger the makeReservation epic - this will check for an Alipay booking
    dispatchWithLocale(
      completeBooking({
        contactType: bookingType,
        ppMode: isPreferredPartners,
        employeeMode,
        ...formValues,
        redirectLocation,
      })
    );
  };

  const validateCCExpiry = (formValues) => {
    if (
      suppressPaymentOptions ||
      formValues.paymentMethod === PAYMENT_METHODS.ALIPAY
    ) {
      return true;
    }
    const cardDateStr = `${formValues.creditCard.expiryDateMonth}/${formValues.creditCard.expiryDateYear}`;
    const parsedCardDate = parse(cardDateStr, "MM/yy", new Date());
    if (dateFnsIsValid(parsedCardDate)) {
      const parsedCheckInDate = parse(
        confirmYourStayData.dates.checkIn,
        "yyyy-MM-dd",
        new Date()
      );
      const expiryDate = endOfMonth(parsedCardDate);
      if (!isBefore(expiryDate, parsedCheckInDate)) {
        return true;
      }
    }
    return false;
  };

  const onSubmit = (formValues) => {
    if (!validateCCExpiry(formValues)) {
      dispatchWithLocale(
        completeBookingFailed({
          apiErrors: [{ errorCode: "OJ-04-0003" }],
          supplierErrors: [{ errorCode: "E0045" }],
          bookingInProgressPhase: {
            data: {
              formValues,
            },
          },
        })
      );
      return;
    }
    if (
      doesMeetRussianCriteria({
        isUserLoggedIn,
        bookingType,
        geoIPCode: getGeoIPCode(),
        countryAddress: formValues.guestDetails?.countryCode,
        propertyRegion: propertyContent.analyticsContent.subRegion,
      })
    ) {
      setRussianCitizenHandler(onRussianCitizenSubmit(formValues));
    } else if (formValues.paymentMethod === PAYMENT_METHODS.ALIPAY) {
      // Define the submission handler
      setAlipaySubmitHandler(onAlipaySubmit(formValues));
      // Open the Alipay modal
      dispatchWithLocale(
        fetchPaymentOptions({
          ...confirmYourStayData.alipay.paymentOptionParams,
          ...formValuesToPaymentOptionsParams(formValues),
        })
      );
      setIsAlipayModalOpen(true);
    } else {
      dispatchMakeReservation(formValues);
    }
  };

  return {
    alipayInfo: confirmYourStayData.alipay,
    alipaySubmitHandler,
    allowAlipay,
    arePaymentOptionsLoading,
    bookingInProgress,
    bookingType,
    cancelBy: confirmYourStayData.cancelBy,
    closeRussianCitizenModal,
    confirmYourStayData,
    currencyCode,
    employeeMode,
    hasExplicitOptIn,
    hotelCode,
    isAgentBookingSelected: bookingType === SECTIONS.AGENT,
    isAlipayModalOpen,
    isBookingForMyselfSelected: bookingType === SECTIONS.MYSELF,
    isBookingForSomeoneElseSelected: bookingType === SECTIONS.SOMEONE_ELSE,
    isPreferredPartners,
    isUserLoggedIn,
    locale,
    onSubmit,
    paymentOptions,
    ppReturnUrl,
    queryParams,
    russianCitizenHandler,
    setCurrencyCode: setCurrencyCodeAndDispatchAction,
    setIsAlipayModalOpen,
    showLoadingIndicator,
    showSignInBlock: SHOW_SIGN_IN_BLOCK && !isUserLoggedIn,
    webChatStatus,
    additionalWebChatInfo: confirmYourStayData?.webChat,
    alipayError: alipayError(propertyContent),
    propertyContent,
  };
}
