import { ofType } from "redux-observable";
import { of, from } from "rxjs";
import {
  catchError,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
  last,
  concatMap,
} from "rxjs/operators";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { addHotelProduct$, removeItem$ } from "api/tretail/booking";
import { fetchBookingCommentsFulfilled } from "store/bookingComments";
import catchInternalServerError from "store/catchInternalServerError";
import fetchBookingWithCommentsById$ from "./fetchBookingWithCommentsById";
import {
  upgradeRooms,
  upgradeRoomsCancel,
  upgradeRoomsFulfilled,
  upgradeRoomsFailed,
} from "../bookings.slice";
import {
  fetchTermsAndConditionsContent$,
  getTermsAndConditionsOptionsFromBooking,
  fetchTermsAndConditionsContentFulfilled,
} from "../../termsAndConditionsContent";
import { getUpsells } from "../packageHelpers";

const upgradeRoomEpic = (action$, state$) =>
  action$.pipe(
    ofType(upgradeRooms.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { roomIndices, locale },
        },
        {
          termsAndConditions: { data: termsAndConditionsData },
          bookings: {
            bookingInProgressId: bookingId,
            bookingsInProgress: bookingData,
            bookingInProgressSearchParams: { hotelCode },
          },
          searchResults: { resultSetId, data },
        },
      ]) => {
        const [hotelOptions] = data[resultSetId].hotelOptions;

        return ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() => from(
              bookingData[bookingId].hotelProducts.map(
                ({ productId }) => productId
              )
            ).pipe(
              concatMap((productId) =>
                removeItem$({
                  bookingId,
                  productId,
                  locale,
                })
              ),
              last(),
              switchMap(() =>
                addHotelProduct$({
                  bookingId,
                  resultSetId,
                  selectedPackages: getUpsells({
                    roomIndices,
                    hotelProducts: bookingData[bookingId].hotelProducts,
                    hotelOptions,
                  }),
                  locale,
                }).pipe(
                  switchMap(() =>
                    fetchBookingWithCommentsById$({ bookingId, locale }).pipe(
                      switchMap(
                        ({
                          booking,
                          preBookingComments,
                          postBookingComments,
                        }) =>
                          fetchTermsAndConditionsContent$(
                            {
                              hotelCode,
                              locale,
                              optionsList:
                                getTermsAndConditionsOptionsFromBooking({
                                  booking,
                                  hotelCode,
                                }),
                            },
                            termsAndConditionsData
                          ).pipe(
                            mergeMap((termsAndConditions) => [
                              fetchBookingCommentsFulfilled({
                                bookingId,
                                preBookingComments,
                                postBookingComments,
                              }),
                              fetchTermsAndConditionsContentFulfilled({
                                hotelCode,
                                termsAndConditions,
                              }),
                              upgradeRoomsFulfilled({
                                booking,
                                roomIndices,
                                prevBooking: bookingData,
                              }),
                            ])
                          )
                      )
                    )
                  )
                )
              ),
              catchInternalServerError(),
              catchError((errors) => of(
                  upgradeRoomsFailed({
                    errors: errors || [],
                  })
                )),
              takeUntil(action$.pipe(ofType(upgradeRoomsCancel.type)))
            ))
        );
      }
    )
  );

export default upgradeRoomEpic;
