import { ofType } from "redux-observable";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";
import { of } from "rxjs";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { createEmailVerifyCode$ } from "api/tretail/profile";
import {
  createEmailVerifyCode,
  createEmailVerifyCodeFulfilled,
  createEmailVerifyCodeFailed,
  createEmailVerifyCodeCancel,
} from "store/profile/profile.slice";
import catchInternalServerError from "store/catchInternalServerError";

export default (action$) =>
  action$.pipe(
    ofType(createEmailVerifyCode.type),
    switchMap(({ payload: { email, locale } }) => ajaxWithHealthCheck$({
        locale,
      }).pipe(
        switchMap(() => createEmailVerifyCode$({ body: { email }, locale }).pipe(
            map(createEmailVerifyCodeFulfilled),
            catchInternalServerError(),
            catchError(({ response = {} }) =>
              of(
                createEmailVerifyCodeFailed({
                  apiErrors: response?.apiErrors || [],
                  supplierErrors: response?.supplierErrors || [],
                })
              )
            ),
            takeUntil(action$.pipe(ofType(createEmailVerifyCodeCancel.type)))
          ))
      ))
  );
