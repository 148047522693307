import React, { useState, useContext, useEffect } from "react";

import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import { StarIcon, StarIconFilled } from "components";

export default function SurveyCard({ createSurveyLink, setShowBookingSurvey }) {
  const { t } = useTranslation();
  const media = useContext(MediaContext);

  const [starRating, setStarRating] = useState(0);

  useEffect(() => {
    if (starRating === 0) {
      return () => {};
    }
    // set rating in local storage for qualtrics
    localStorage.setItem("starRating", starRating);
    const surveyWidth = 930;
    const surveyHeight = 740;
    const clickQualtricsTimeout = setTimeout(() => {
      window.open(
        createSurveyLink({ StarRating: starRating }),
        "_blank",
        `width=${surveyWidth},height=${surveyHeight},top=${
          (window.outerHeight - surveyHeight) / 2
        },left=${(window.outerWidth - surveyWidth) / 2}`
      );
    }, 500);

    const runQualtricsTimeout = setTimeout(() => {
      setShowBookingSurvey(false);
    }, 2000);

    return () => {
      localStorage.removeItem("starRating", starRating);
      clearTimeout(runQualtricsTimeout);
      clearTimeout(clickQualtricsTimeout);
    };
  }, [starRating]);

  return (
    <div className="survey-card">
      <div
        className={`survey-card__heading ${
          media.isGreaterThan.md ? "ty-c1" : "ty-c3"
        }`}
      >
        {t("rate your booking experience")}
      </div>
      <div className="survey-card__stars">
        {[1, 2, 3, 4, 5].map((starNumber) => (
            <button
              key={`star-${starNumber}`}
              type="button"
              className="survey-card__star ty-c1"
              onClick={() => {
                setStarRating(starNumber);
              }}
            >
              {starRating >= starNumber ? <StarIconFilled /> : <StarIcon />}
            </button>
          ))}
      </div>
    </div>
  );
}
