import { ajax } from "rxjs/ajax";
import { switchMap, pluck } from "rxjs/operators";
import castArray from "lodash/fp/castArray";
import filter from "lodash/fp/filter";
import flatMap from "lodash/fp/flatMap";
import flow from "lodash/fp/flow";
import keys from "lodash/fp/keys";
import pick from "lodash/fp/pick";
import reduce from "lodash/fp/reduce";
import update from "lodash/fp/update";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

const formFieldValuesForSubmit = flatMap((item) => flow(
    keys,
    reduce((acc, key) => [...acc, { name: key, value: item[key] }], [])
  )(item));

const getProcessedFormData = flow(
  pick([
    "details",
    "quantity",
    "numQuantity",
    "startDate",
    "code",
    "imageURL",
    "name",
    "onRequestEventProductType",
    "PRICE_ID",
    "PRODUCT_ID",
    "merchantGroupType",
    "merchantGroupCategory",
    "merchantGroupSubCategory",
  ]),
  update(["details", "fields"], formFieldValuesForSubmit),
  update(
    ["details", "fields"],
    filter(({ value = "" }) => value !== "")
  ),
  update(
    ["details", "aggregation"],
    filter(({ value = "" }) => value !== "")
  )
);

function doAddOnRequestEventProduct({
  locale,
  bookingId,
  formDataArray,
  productIds = [],
}) {
  const [formData, ...rest] = formDataArray;
  return ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookings/${bookingId}/products/onRequestEvent`,
    body: getProcessedFormData(formData),
  }).pipe(
    pluck("response"),
    switchMap(({ productId }) => {
      const nextProductIds = [...productIds, productId];
      if (rest.length === 0) {
        return Promise.resolve({ productIds: nextProductIds });
      }
      return doAddOnRequestEventProduct({
        locale,
        bookingId,
        formDataArray: rest,
        productIds: nextProductIds,
      });
    })
  );
}

export default function addOnRequestEventProduct$({
  locale = "en",
  bookingId,
  formData,
}) {
  return doAddOnRequestEventProduct({
    locale,
    bookingId,
    formDataArray: castArray(formData),
  });
}
