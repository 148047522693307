import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { isSameDay, eachDayOfInterval, subDays } from "date-fns/fp";
import { Controller } from "react-hook-form";
import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import { parseDate } from "BookingFlow/PlanYourStay/DateRangePicker/calendarHelpers";
import PropertySearch from "BookingFlow/PlanYourStay/PropertySearch/PropertySearch";
import DateRangePicker from "./DateRangePicker";
import { SEARCH_BY_OPTIONS } from "./constants";

function LocationAndDates({
  searchData: {
    properties = [],
    allPropertyEmployeeAvailability = [],
    setHotelCode,
    setCheckIn,
    setCheckOut,
    checkIn,
    checkOut,
    promoCode,
    caw: {
      cawConfig: {
        name,
        calendar: {
          checkInStart,
          checkInEnd,
          checkOutEnd,
          restrictions: { minStay, maxStay },
          availabilityEnabled,
          availabilityLastUpdated,
          restrictedDates,
          unavailableDates,
        },
      },
    },
    hasUserSelectedCheckIn,
    setHasUserSelectedCheckIn,
  },
  formControls: { control, watch },
  searchBy = SEARCH_BY_OPTIONS.DESTINATION,
}) {
  const { t } = useTranslation();
  const media = useContext(MediaContext);

  const watchHotelCode = watch("hotelCode");

  const [searchByDateProperties, setSearchByDateProperties] = useState([]);

  const [numDestinations, setNumDestinations] = useState(0);

  useEffect(() => setHotelCode(watchHotelCode), [watchHotelCode]);

  useEffect(() => {
    if (
      searchBy === SEARCH_BY_OPTIONS.DATES &&
      !isEmpty(allPropertyEmployeeAvailability) &&
      properties.length
    ) {
      const startDate = parseDate(checkIn);
      // Note: Don't include the departure date since you are not staying that night
      const endDate = subDays(1, parseDate(checkOut));
      const daysInterval = eachDayOfInterval({
        start: startDate,
        end: endDate,
      });

      const sbdPropList = properties.filter((p) => {
        const propAvail = allPropertyEmployeeAvailability[p.code];
        let propOk = !!propAvail;
        if (propAvail) {
          const noAvailabilityDates = propAvail[promoCode]?.noAvailabilityDates;
          if (noAvailabilityDates) {
            daysInterval.forEach((day) => {
              const dateNotAvailable = noAvailabilityDates.some(isSameDay(day));
              if (dateNotAvailable) {
                propOk = false;
              }
            });
          }
        }
        return propOk;
      });
      setNumDestinations(sbdPropList.length);
      setSearchByDateProperties(sbdPropList);
    }
  }, [searchBy, checkIn, checkOut, promoCode]);

  const destinationContent = (propertyList) => (
      <div className="col-sm-12 col-lg-6">
        <Controller
          key={`hotelCode-properties-count-${propertyList.length}`}
          control={control}
          name="hotelCode"
          rules={{
            required: true,
          }}
          render={({ ref: _ignoredRef, ...renderProps }) => (
              <>
                <label className="ty-c3">{t("Destination")}</label>
                {searchBy === SEARCH_BY_OPTIONS.DATES && (
                  <span className="ty-c4 destination-count">
                    {t("{0} Destinations", [numDestinations])}
                  </span>
                )}
                <PropertySearch
                  label={t(name)}
                  properties={propertyList}
                  placeholder={
                    searchBy === SEARCH_BY_OPTIONS.DATES
                      ? t("Available Destinations")
                      : ""
                  }
                  closeBtnClassName="btn-close"
                  {...renderProps}
                />
              </>
            )}
        />
      </div>
    );

  const datesContent = (
    <div
      className={classNames("change-search", {
        "col-sm-12": !media.isGreaterThan.sm,
        "calendar-inline": !media.isGreaterThan.sm,
        "calendar-floating": media.isGreaterThan.sm,
        "dates-first": searchBy === SEARCH_BY_OPTIONS.DATES,
      })}
    >
      <DateRangePicker
        availabilityEnabled={availabilityEnabled}
        availabilityLastUpdated={availabilityLastUpdated}
        checkInEnd={checkInEnd}
        checkOutEnd={checkOutEnd}
        maxNumberOfNights={maxStay}
        checkInStart={checkInStart}
        minNumberOfNights={minStay}
        restrictedDates={restrictedDates}
        setCheckIn={setCheckIn}
        checkIn={checkIn}
        checkOut={checkOut}
        setCheckOut={setCheckOut}
        unavailableDates={unavailableDates}
        hasUserSelectedCheckIn={hasUserSelectedCheckIn}
        setHasUserSelectedCheckIn={setHasUserSelectedCheckIn}
        checkInWrapperClassName="col-sm-12 col-md-6 col-lg-3"
        checkOutWrapperClassName="col-sm-12 col-md-6 col-lg-3"
        checkInLabel={t("Check In")}
        checkOutLabel={t("Check Out")}
      />
    </div>
  );

  return (
    <div className="location-and-dates row">
      {searchBy === SEARCH_BY_OPTIONS.DATES ? (
        <>
          {datesContent}
          {destinationContent(searchByDateProperties)}
        </>
      ) : (
        <>
          {destinationContent(properties)}
          {datesContent}
        </>
      )}
    </div>
  );
}

export default LocationAndDates;
