import React, { useContext } from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { splitNumberAndDialCode } from "utils";
import { MediaContext } from "contexts/MediaContext";
import TextInput from "./TextInput";
import Dropdown from "./Dropdown";

export default React.forwardRef((
  {
    identifier,
    name,
    phoneNumberName,
    countryCodeName,
    countryCodeClassName = "col-sm-4",
    phoneNumberClassName = "col-sm-8",
    label = "Phone number",
    placeholder = "Phone number",
    value = "",
    onChange = () => {},
    onBlur = () => {},
    error = "",
    disabled,
    required,
    countries = [],
    isLoadingCountries,
    ...props
  },
  ref
) => {
  const { t, locale } = useTranslation();
  const media = useContext(MediaContext);

  const countriesOptions = countries.map(({ name: name_, iso2, dialCode }) => ({
    value: iso2,
    label: `${name_} +${dialCode}`,
    dialCode,
  }));

  const id = identifier || `input-${name}`;

  const {
    iso2: countryCode,
    number: phoneNumber,
    dialCode,
  } = splitNumberAndDialCode(value.replace(/^\++/, ""), countries);

  const mapCountryKeyToCode = (v) =>
    countriesOptions.find((a) => a.value === v)?.dialCode;

  const hasError =
    (typeof error === "boolean" && error === true) || error.length > 0;

  const handleCountryCodeChange = ({ target }) => {
    onChange(`${mapCountryKeyToCode(target.value)}${phoneNumber}`);
  };

  const handlePhoneNumberChange = ({ target }) => {
    onChange(`${dialCode}${target.value}`);
  };

  const screenTooSmallForTwoLabels = ["it", "pt"].includes(locale)
    ? false
    : !media.isLessThan.xs;

  return (
    <div
      className={classNames("fsp-input fsp-input--phone-input", {
        "fsp-input--is-invalid": hasError,
      })}
    >
      <div className="row">
        <div className={countryCodeClassName}>
          <div className="fsp-input--phone-input__country-code">
            <div className="formElement-field fsp-input--phone-input__country-code__label">
              {(mapCountryKeyToCode(countryCode) &&
                `+${mapCountryKeyToCode(countryCode)}`) ||
                `${t("Country code")}*`}
            </div>
            <Dropdown
              name={countryCodeName || "countryCode"}
              label={screenTooSmallForTwoLabels ? t("Country code") : t(label)}
              identifier={`${id}-phone-countryDialCode`}
              value={countryCode}
              options={countriesOptions}
              onChange={handleCountryCodeChange}
              disabled={disabled || isLoadingCountries}
              required={required}
            />
          </div>
        </div>
        <div className={phoneNumberClassName}>
          <TextInput
            identifier={`${id}-phone-number`}
            name={phoneNumberName || "phoneNumber"}
            value={phoneNumber}
            label={screenTooSmallForTwoLabels ? t(label) : null}
            placeholder={t(placeholder)}
            onChange={handlePhoneNumberChange}
            onBlur={onBlur}
            disabled={disabled || isLoadingCountries}
            required={required}
            error={hasError}
            ref={ref}
            {...props}
          />
        </div>
      </div>
      {hasError && typeof error === "string" && (
        <div className="error-message">
          <span className="formElement-message" role="alert">
            {error}
          </span>
        </div>
      )}
    </div>
  );
});
