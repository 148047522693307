import { ofType } from "redux-observable";
import {
  catchError,
  map,
  switchMap,
  takeUntil,
  mergeMap,
} from "rxjs/operators";
import { of } from "rxjs";

import { getEmployeeProfile$ } from "api/workday";
import {
  fetchEmployeeProfile,
  fetchEmployeeProfileFulfilled,
  fetchEmployeeProfileFailed,
  fetchEmployeeProfileCancel,
} from "../employeeProfile.slice";

export function fetchEmployeeProfile$({ locale, workdayId }) {
  return getEmployeeProfile$({ locale, workdayId }).pipe(
    mergeMap((response = {}) => of(response)),

    catchError(({ response = {} } = {}) => of(response))
  );
}

export default function fetchEmployeeProfileEpic(action$) {
  return action$.pipe(
    ofType(fetchEmployeeProfile.type),
    switchMap(({ payload: { locale, workdayId } }) =>
      fetchEmployeeProfile$({ locale, workdayId }).pipe(
        map(fetchEmployeeProfileFulfilled),

        catchError(({ response = {} }) =>
          of(
            fetchEmployeeProfileFailed({
              apiErrors: response?.apiErrors || [],
              supplierErrors: response?.supplierErrors || [],
            })
          )
        ),

        takeUntil(action$.pipe(ofType(fetchEmployeeProfileCancel.type)))
      )
    )
  );
}
