import { API_REQUEST_STATES } from "store/apiRequestStates/constants";
import { fetchGlobalMailSubscriptions } from "./globalMailSubscriptions.slice";

export const selectGlobalMailSubscriptions = (state) =>
  state.globalMailSubscriptions?.data || [];

export const selectIsLoadingGlobalMailSubscriptions = ({
  apiRequestStates,
}) => {
  const apiRequestState = apiRequestStates[fetchGlobalMailSubscriptions.type];
  return (
    (apiRequestState && apiRequestState === API_REQUEST_STATES.REQUEST) || false
  );
};

export const selectHasRequestedGlobalMailSubscriptions = ({
  apiRequestStates,
}) => {
  const apiRequestState = apiRequestStates[fetchGlobalMailSubscriptions.type];
  return (
    (apiRequestState && apiRequestState === API_REQUEST_STATES.FULFILLED) ||
    false
  );
};
