import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { errorCodes, getErrorCodeForTranslations } from "utils/errorCodes";
import { LOGIN_TYPES } from "api/tretail/authentication/generateOTP";
import { useDispatchWithLocale, useTranslation } from "hooks";
import { selectEmployeeActivateProfile } from "store/employeeProfile";
import { activateEmployeeProfile } from "store/profile";
import { selectIsLoadingRequests } from "store/apiRequestStates";
import {
  generateOtp,
  generateOtpCancel,
  generateOtpReset,
  submitOtp,
  submitOtpCancel,
  selectViewState,
  selectOtpSentTo,
  selectOtpSentToParams,
  selectOtpSubmittedTo,
  selectOtpSentVia,
  selectOtpSubmittedVia,
  selectError,
} from "store/signIn";
import { SIGN_IN_VIEW_STATES } from "store/signIn/signIn.slice";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { ErrorMessage } from "Profile/components";
import classNames from "classnames";
import EmployeeActivateProfileModal from "Profile/ProfileView/EmployeeActivateProfileModal";
import RequestOtpForm from "./RequestOtpForm";
import RequestOtpError from "./RequestOtpError";
import RequestOtpErrorFooter from "./RequestOtpErrorFooter";
import SubmitOtpForm from "./SubmitOtpForm";
import OtpError from "./OtpError";

export default function OtpForm({
  signInTitle,
  introContent,
  messageType,
  registerLinkTo,
  afterSignInRedirectTo,
  requestOtpInitialValues: initialRequestOtpInitialValues = {},
  employeeMode = false,
  isYachtsMode = false,
  isUserPersistent = false,
  showToastOnNextView,
  disablePersistentLoginOption = false,
}) {
  const { t } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const [requestOtpInitialValues, setRequestOtpInitialValues] = useState(
    initialRequestOtpInitialValues
  );

  const employeeProfileActivate = useSelector(selectEmployeeActivateProfile);
  const viewState = useSelector(selectViewState);
  const isLoading = useSelector(
    selectIsLoadingRequests([
      generateOtp.type,
      submitOtp.type,
      activateEmployeeProfile.type,
    ])
  );
  const sendCodeTo = useSelector(selectOtpSentTo);
  const sentToParams = useSelector(selectOtpSentToParams);
  const otpSubmittedTo = useSelector(selectOtpSubmittedTo);
  const sendCodeVia = useSelector(selectOtpSentVia);
  const otpSubmittedVia = useSelector(selectOtpSubmittedVia);
  const error = useSelector(selectError);
  const errorCode = error?.errorCode || "";
  const hasError = errorCode.length > 0;
  const reset = () => dispatchWithLocale(generateOtpReset());

  useEffect(() => () => {
      dispatchWithLocale(generateOtpCancel());
      dispatchWithLocale(submitOtpCancel());
    }, []);

  const handleRequestOtp = (formValues) => {
    dispatchWithLocale(generateOtp(formValues));
  };

  const handleSubmitOtp = (formValues) => {
    dispatchWithLocale(
      submitOtp({
        formValues,
        afterSignInRedirectTo,
        employeeMode,
        isUserPersistent,
        showToastOnNextView,
      })
    );
  };

  const emailOrPhoneNotFound =
    hasError &&
    [
      errorCodes.NO_ACCOUNT_FOUND_WITH_PHONE_NUMBER,
      errorCodes.NO_ACCOUNT_FOUND_WITH_EMAIL_ADDRESS,
    ].includes(errorCode);

  let sendCodeViaOverride = sendCodeVia;
  if (hasError && errorCode === errorCodes.NO_ACCOUNT_FOUND_WITH_PHONE_NUMBER) {
    sendCodeViaOverride = LOGIN_TYPES.EMAIL_ADDRESS;
  }
  if (
    hasError &&
    errorCode === errorCodes.NO_ACCOUNT_FOUND_WITH_EMAIL_ADDRESS
  ) {
    sendCodeViaOverride = LOGIN_TYPES.PHONE_NUMBER;
  }

  useEffect(() => {
    if (emailOrPhoneNotFound) {
      setRequestOtpInitialValues({});
    }
  }, [emailOrPhoneNotFound]);

  return (
    <div className="fs-otp-sign-in">
      {(viewState === SIGN_IN_VIEW_STATES.CODE_INVALID ||
        viewState === SIGN_IN_VIEW_STATES.CODE_EXPIRED) && (
        <OtpError errorCode={errorCode} reset={reset} />
      )}

      {viewState === SIGN_IN_VIEW_STATES.SUBMIT_OTP && (
        <SubmitOtpForm
          sendCodeVia={otpSubmittedVia}
          sendCodeTo={otpSubmittedTo}
          messageType={messageType}
          onSubmit={handleSubmitOtp}
          reset={reset}
          employeeMode={employeeMode}
          isYachtsMode={isYachtsMode}
          isUserPersistent={isUserPersistent}
          disablePersistentLoginOption={disablePersistentLoginOption}
        />
      )}

      {viewState === SIGN_IN_VIEW_STATES.REQUEST_OTP && (
        <RequestOtpForm
          key={JSON.stringify({
            sendCodeViaOverride,
            emailOrPhoneNotFound,
            requestOtpInitialValues,
          })}
          title={signInTitle}
          introContent={!emailOrPhoneNotFound && introContent}
          emailOrPhoneNotFound={emailOrPhoneNotFound}
          registerLinkTo={registerLinkTo}
          initialValues={{
            sendCodeTo: sentToParams,
            ...requestOtpInitialValues,
            sendCodeVia: sendCodeViaOverride,
          }}
          errorComponent={({ resetForm }) => {
            if (emailOrPhoneNotFound) {
              return (
                <RequestOtpError
                  errorCode={errorCode}
                  sendCodeVia={sendCodeVia}
                  sendCodeTo={sendCodeTo}
                  onReset={() => {
                    reset();
                    resetForm();
                  }}
                />
              );
            }
            if (hasError) {
              return (
                <ErrorMessage
                  newAlertIcon={isUserPersistent}
                  className={classNames({ "ty-d2": isUserPersistent })}
                >
                  {t(getErrorCodeForTranslations({ errorCode }), [sendCodeTo])}
                </ErrorMessage>
              );
            }
            return null;
          }}
          footerComponent={
            emailOrPhoneNotFound && (
              <RequestOtpErrorFooter isYachtsMode={isYachtsMode} />
            )
          }
          onSubmit={handleRequestOtp}
          hasError={hasError}
          isYachtsMode={isYachtsMode}
          isUserPersistent={isUserPersistent}
        />
      )}

      {employeeMode && employeeProfileActivate?.salesForceId && (
        <EmployeeActivateProfileModal
          profileToActivate={employeeProfileActivate}
        />
      )}

      {isLoading && <BookingFlowLoadingIndicator />}
    </div>
  );
}
