import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";
import queryString from "query-string";

import env from "config/env";
import { basePutRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

export default function updateHotelProductPostBookingComments$({
  bookingId,
  checkoutModifyToken,
  comments,
  locale,
}) {
  // BODY:
  // {
  //   "comments": [
  //     {
  //       "additionalRequests": "string",
  //       "adjoiningRooms": false,
  //       "keepRoomsClose": false,
  //       "mostImportant": "string",
  //       "productId": 0,
  //       "roomGuests": [
  //         {
  //           "dateOfBirth": "2020-12-16",
  //           "email": "string",
  //           "name": "string",
  //           "type": 0
  //         }
  //       ],
  //       "transportationRequests": {
  //         "airRequest": {
  //           "airlineFlightNumber": "string",
  //           "airlineName": "string",
  //           "airportName": "string",
  //           "cityOfOrigin": "string",
  //           "contactPhoneNumber": "string",
  //           "flightArrivalTime": "string",
  //           "numberOfLuggage": 0,
  //           "numberOfPassengers": 0
  //         },
  //         "vehicleRequest": {
  //           "cost": "string",
  //           "description": "string",
  //           "type": "string"
  //         }
  //       },
  //       "tripPurpose": "string",
  //       "welcomeAmenities": "string"
  //     }
  //   ]
  // }
  const qsStringified = checkoutModifyToken
    ? `?${queryString.stringify({ checkoutModifyToken })}`
    : "";

  return ajax({
    ...basePutRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookings/${bookingId}/products/hotel/postbooking/comments${qsStringified}`,
    body: {
      comments: comments.map(
        ({
          additionalRequests,
          adjoiningRooms,
          keepRoomsClose,
          mostImportant,
          productId,
          roomGuests,
          transportationRequests,
          tripPurpose,
          welcomeAmenities,
        }) => ({
            additionalRequests: additionalRequests || undefined,
            adjoiningRooms: adjoiningRooms || undefined,
            keepRoomsClose: keepRoomsClose || undefined,
            mostImportant: mostImportant || undefined,
            productId: productId || undefined,
            roomGuests: roomGuests
              ? roomGuests.map((roomGuest) => ({
                  ...roomGuest,
                  name: roomGuest.name || undefined,
                }))
              : undefined,
            transportationRequests: transportationRequests || undefined,
            tripPurpose: tripPurpose || undefined,
            welcomeAmenities: welcomeAmenities || undefined,
          })
      ),
    },
  }).pipe(pluck("response"));
}
