import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useCombobox } from "downshift";
import { fromEvent, partition } from "rxjs";
import { buffer, filter, debounceTime } from "rxjs/operators";
import parsePhoneNumber from "libphonenumber-js";
import getCountryCodeFromAreaCode from "utils/getCountryCodeFromAreaCode";

import { useTranslation } from "hooks";
import useCountries from "hooks/useCountries";
import { cleanPhoneNumber } from "utils/utils";

const BUFFER_TIME = 350;

const replaceSpaceWithDash = (number) => number.replace(/\s+/g, "-");

const customFormatInternationalNumber = (number) => {
  const [countryCode, nationalNumber] = number.split(/\s(.+)/);
  const formattedNationalNumber = replaceSpaceWithDash(nationalNumber);

  return `${countryCode} ${formattedNationalNumber}`;
};

export default function FormRowPhoneNumber({
  name,
  label = "Mobile Phone Number",
  value = "",
  onChange,
  errorMessage,
  readOnly,
  onFocus = () => {},
  onBlur = () => {},
}) {
  const id = `input-${name}`;
  const { t } = useTranslation();
  const [inFocus, setInFocus] = useState(false);
  const inputRef = useRef();

  const inputValue = value ? `+${value.replace(/^\++/, "")}` : "";
  const phoneNumber = parsePhoneNumber(inputValue);

  const hasError = Boolean(errorMessage);

  const { countries, isLoading: isLoadingCountries } = useCountries();
  const dialCodes = [
    {
      iso2: "none",
      dialCode: "",
      shortName: "",
    },
    ...countries.map(({ iso2, dialCode, shortName, priority }) => ({
      iso2,
      dialCode,
      shortName,
      priority,
    })),
  ]
    .filter((dialCode) => dialCode.iso2 && dialCode.shortName && dialCode.dialCode)
    .filter((v, index, self) => self.findIndex(({ iso2 }) => iso2 === v.iso2) === index);

  const getByDialCode = (dialCode) => {
    const [prioritisedCountry] = dialCodes
      .filter((country) => country.dialCode === dialCode)
      .sort((a, b) => a.priority - b.priority);

    const countryCodeFromAreaCode = getCountryCodeFromAreaCode(
      cleanPhoneNumber(inputValue)
    );

    if (countryCodeFromAreaCode) {
      return dialCodes.find(
        (country) => country.iso2 === countryCodeFromAreaCode
      );
    }

    return prioritisedCountry;
  };

  const getDialCodeByGeoIp = (geoIpCode) => dialCodes.find((country) => country.iso2 === geoIpCode)?.dialCode;

  const getDialCodeByPhoneNumber = (numberStr) => {
    const [matchedCountry] = dialCodes
      .filter((country) => numberStr.startsWith(country.dialCode))
      .sort((a, b) => b.dialCode.length - a.dialCode.length);
    return matchedCountry.dialCode;
  };

  const handleFocus = (evt) => {
    setInFocus(true);
    onFocus(evt);
  };

  const handleBlur = (evt) => {
    setInFocus(false);
    onBlur(evt);
  };

  const handleInputChange = ({ target }) => {
    onChange(cleanPhoneNumber(target.value));
  };

  const handleSelectItem = (selectedDialCode) => {
    if (phoneNumber) {
      const currentDialCode =
        getDialCodeByGeoIp(phoneNumber.country) ||
        getByDialCode(phoneNumber.countryCallingCode).dialCode;
      const nextNationalNumber = phoneNumber.number.replace(
        `+${currentDialCode}`,
        ""
      );

      const nextPhoneNumber = parsePhoneNumber(
        `+${selectedDialCode}${nextNationalNumber}`
      );
      onChange(cleanPhoneNumber(nextPhoneNumber?.number || ""));
    } else {
      onChange(cleanPhoneNumber(selectedDialCode));
    }
  };

  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    isOpen,
    selectedItem,
    setHighlightedIndex,
    selectItem,
    toggleMenu,
  } = useCombobox({
    initialSelectedItem:
      value && countries.find(({ dialCode }) => value.startsWith(dialCode)),
    items: dialCodes,
    itemToString: (item) => (item ? `+${item.dialCode}` : "+"),

    onSelectedItemChange: ({ selectedItem: { dialCode } }) => {
      handleSelectItem(dialCode);
    },
  });

  const callingCodeAlreadySelected = (nextCallingCode) =>
    selectedItem?.dialCode === nextCallingCode;

  useEffect(() => {
    if (dialCodes.length <= 1) {
      return;
    }

    const phoneNumberStr = cleanPhoneNumber(inputValue);
    if (
      (!callingCodeAlreadySelected(phoneNumberStr) &&
        getByDialCode(phoneNumberStr)) ||
      getCountryCodeFromAreaCode(cleanPhoneNumber(phoneNumberStr)) ||
      phoneNumber?.countryCallingCode
    ) {
      selectItem(getByDialCode(getDialCodeByPhoneNumber(phoneNumberStr)));
    }
  }, [inputValue, isLoadingCountries]);

  const { onKeyDown, ref } = getInputProps();

  useEffect(() => {
    const bufferredKeydownEvents$ = fromEvent(inputRef?.current, "keydown");

    const [chars$, nonChars$] = partition(
      bufferredKeydownEvents$,
      (e) => e.code?.startsWith("Key") || e.char // char is for IE11
    );

    nonChars$.subscribe((e) => onKeyDown(e));
    chars$
      .pipe(
        buffer(chars$.pipe(debounceTime(BUFFER_TIME))),
        filter((events) => events.length > 0)
      )
      .subscribe((eventsList) =>
        setHighlightedIndex(
          dialCodes.findIndex(({ shortName }) =>
            shortName
              .toLowerCase()
              .startsWith(eventsList.map((a) => a.key).join(""))
          ) || 0
        )
      );

    return () => {
      if (nonChars$.unsubscribe) nonChars$.unsubscribe();
      if (chars$.unsubscribe) chars$.unsubscribe();
    };
  }, [isLoadingCountries]);

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <div
      className={classNames("form-row telephone-row", {
        "has-errors": hasError,
        "in-focus": inFocus,
      })}
    >
      <label htmlFor={id}>{t(label)}*</label>
      <div className="intl-tel-input allow-dropdown">
        <div className="flag-container">
          <div
            ref={inputRef}
            type="button"
            className="selected-flag"
            aria-controls=""
            aria-expanded="false"
            role="combobox"
            aria-owns="country-listbox"
            tabIndex="0"
            title={`${selectedItem?.shortName || ""}: +${
              selectedItem?.dialCode || ""
            }`}
            onClick={!readOnly && !isLoadingCountries ? toggleMenu : undefined}
          >
            {selectedItem && (
              <div
                className={classNames("iti-flag", {
                  [selectedItem.iso2.toLowerCase()]: selectedItem,
                })}
              />
            )}
            <div
              className={classNames("iti-arrow", {
                up: isOpen,
              })}
            />
          </div>
          <ul
            {...getMenuProps()}
            className={classNames("country-list", { hide: !isOpen })}
          >
            {dialCodes.map((country, index) => {
              const listInputProps = getItemProps({ item: country, index });
              /* eslint-disable jsx-a11y/click-events-have-key-events */
              /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
              return (
                <li
                  key={country.iso2}
                  id={`iti-item-${country.iso2}`}
                  className={classNames("country standard", {
                    highlight: listInputProps["aria-selected"] === "true",
                  })}
                  {...listInputProps}
                >
                  <div className="flag-box">
                    <div className={`iti-flag ${country.iso2.toLowerCase()}`} />
                  </div>
                  <span className="country-name">{country.shortName}</span>
                  <span className="dial-code">{` +${country.dialCode}`}</span>
                </li>
              );
            })}
          </ul>
        </div>

        <input
          aria-describedby="ttGuestPhone2"
          aria-invalid={hasError}
          autoComplete="off"
          id={id}
          name={name}
          onBlur={handleBlur}
          onChange={handleInputChange}
          onFocus={handleFocus}
          placeholder={`${t("Mobile Phone Number")}*`}
          readOnly={readOnly || isLoadingCountries}
          ref={ref}
          required
          type="tel"
          value={
            phoneNumber
              ? customFormatInternationalNumber(
                  phoneNumber.formatInternational()
                )
              : inputValue
          }
        />
      </div>

      {hasError && (
        <div className="inline-error">
          <span id="ttGuestPhone2" className="message" role="tooltip">
            {errorMessage}
          </span>
          <span aria-hidden="true" className="icon">
            !
          </span>
        </div>
      )}
    </div>
  );
}
