import { ofType } from "redux-observable";
import { mergeMap, switchMap, catchError, takeUntil } from "rxjs/operators";
import { replace } from "connected-react-router";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";

import { getSourceName } from "utils";
import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import {
  fetchHistoricBookingAndContent,
  fetchHistoricBookingCancel,
  fetchHistoricBookingFulfilled,
} from "store/bookings";
import { fetchPropertyContent } from "store/propertyContent";
import { fetchAccommodationsContent } from "store/accommodationsContent";
import { fetchOffersContent } from "store/offersContent";
import { fetchBookingComments } from "store/bookingComments";
import { fetchTaxesContent } from "store/taxesContent";
import { fetchBookingMessages } from "store/bookingMessages";
import { fetchTermsAndConditionsContent } from "store/termsAndConditionsContent";
import { fetchCountriesContent } from "store/countriesContent";
import { fetchGuestRequests } from "store/guestRequests";
import { fetchTransportationAmenitiesContent } from "store/transportationAmenitiesContent";
import catchInternalServerError from "store/catchInternalServerError";
import {
  retrieveBooking,
  handleHistoricBookingError,
} from "./fetchHistoricBooking";

export const getTermsAndConditionsOptions = (booking) => ({
  checkinDate: get(["hotelProducts", "0", "checkInDate"], booking),
  checkoutDate: get(["hotelProducts", "0", "checkOutDate"], booking),
  offerCode: get(["hotelProducts", "0", "roomRate", "ratePlanCode"], booking),
  roomCode:
    get(["hotelProducts", "0", "roomTypes", "0", "fsRoomToCharge"], booking) ||
    get(["hotelProducts", "0", "roomTypes", "0", "roomTypeId"], booking),
});

export default function fetchHistoricBookingEpic(action$) {
  return action$.pipe(
    ofType(fetchHistoricBookingAndContent.type),
    switchMap(
      ({
        payload: { reservationId, propertyCode, surname, locale, redirectTo },
      }) => ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() => retrieveBooking({
              reservationId,
              hotelCode: propertyCode,
              surname,
              locale,
            }).pipe(
              mergeMap((booking) => {
                const ratePlanCode = get([
                  "hotelProducts",
                  0,
                  "roomRate",
                  "ratePlanCode",
                ])(booking);

                const { numberAdults = 0, numberChildren = 0 } = getOr(
                  ["hotelProducts", 0, "guestCount"],
                  {}
                )(booking);

                return [
                  fetchHistoricBookingFulfilled(booking),
                  fetchPropertyContent({ propertyCode, locale }),
                  fetchAccommodationsContent({ propertyCode, locale }),
                  fetchOffersContent({ propertyCode, locale }),
                  fetchBookingComments({
                    bookingId: booking.bookingId,
                    reservationId,
                  }),
                  fetchTaxesContent({ propertyCode, locale }),
                  fetchBookingMessages({
                    propertyCode,
                    bookingSteps: ["availability"],
                    checkinDate: booking.startDate,
                    checkoutDate: booking.endDate,
                    ratePlanCode,
                    locale,
                  }),
                  fetchTermsAndConditionsContent({
                    hotelCode: propertyCode,
                    locale,
                    optionsList: [getTermsAndConditionsOptions(booking)],
                  }),
                  fetchCountriesContent({ locale }),

                  fetchTransportationAmenitiesContent({
                    reservationId,
                    locale,
                    hotelCode: propertyCode,
                    startDate: booking.startDate,
                    endDate: booking.endDate,
                    adults: numberAdults,
                    children: numberChildren,
                    sourceName: getSourceName(booking),
                  }),

                  fetchGuestRequests({
                    reservationId,
                    surname,
                    propertyCode,
                    locale,
                  }),

                  redirectTo && replace(redirectTo),
                ].filter(Boolean);
              }),

              catchInternalServerError(),

              catchError(({ response }) =>
                handleHistoricBookingError({ reservationId, response })
              ),

              takeUntil(action$.pipe(ofType(fetchHistoricBookingCancel.type)))
            ))
        )
    )
  );
}
