import { ofType } from "redux-observable";
import {
  catchError,
  mergeMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import get from "lodash/get";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { updateHotelProductPostBookingComments$ } from "api/tretail/bookingProductCust";
import { fetchProfile } from "store/profile";
import { modifyHotelProductCust$ } from "api/tretail/bookingManagementCust";
import fetchBookingWithCommentsById$ from "store/bookings/epics/fetchBookingWithCommentsById";
import { submitBookingPreferencesFulfilled } from "store/bookings/bookings.slice";
import { selectBookingByReservationId } from "store/bookings/bookings.selectors";
import catchInternalServerError from "store/catchInternalServerError";
import {
  updatePostBookingCommentsFulfilled,
  updateProfileComments,
  updateProfileCommentsFailed,
  updateProfileCommentsFulfilled,
} from "../bookingComments.slice";

export default function updateProfileCommentsEpic(action$, state$) {
  return action$.pipe(
    ofType(updateProfileComments.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: {
            arrivalTime: nextArrivalTime,
            bookingId,
            checkInDate,
            checkOutDate,
            comments,
            locale,
            reservationId,
            specialRequestChanged = false,
          },
        },
        state,
      ]) => {
        const arrivalTime =
          nextArrivalTime ||
          get(selectBookingByReservationId({ reservationId })(state), [
            "hotelProducts",
            0,
            "checkInTime",
          ]);

        return ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() => {
            const amendedComments = comments.map(
              ({
                adjoiningRooms: _ignoredAdjoiningRooms,
                keepRoomsClose: _ignoredKeepRoomsClose,
                ...c
              }) => c
            );

            return updateHotelProductPostBookingComments$({
              bookingId,
              comments: amendedComments,
              locale,
            }).pipe(
              switchMap(() =>
                modifyHotelProductCust$({
                  arrivalTime,
                  bookingId,
                  locale,
                  checkInDate,
                  checkOutDate,
                  bookingEmailOptions: {
                    specialRequestChanged,
                  },
                }).pipe(
                  switchMap(() => fetchBookingWithCommentsById$({
                      bookingId,
                      locale,
                    }).pipe(
                      mergeMap(({ booking, postBookingComments }) => [
                          updatePostBookingCommentsFulfilled({
                            reservationId,
                            postBookingComments,
                          }),
                          submitBookingPreferencesFulfilled({
                            booking,
                          }),
                          updateProfileCommentsFulfilled(),
                          fetchProfile({ locale }),
                        ])
                    ))
                )
              ),

              catchInternalServerError(),

              catchError(({ response } = {}) => [
                  updateProfileCommentsFailed({
                    apiErrors: response?.apiErrors || [],
                    supplierErrors: response?.supplierErrors || [],
                  }),
                ])
            );
          })
        );
      }
    )
  );
}
