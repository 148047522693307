import { ajax } from "rxjs/ajax";
import { of, forkJoin } from "rxjs";
import { mergeMap, map } from "rxjs/operators";

import env from "config/env";

const { CONTENT_API_BASE_URL } = env;

export function getPropertyContent$({
  locale = "en",
  hotelCode,
  retryInEN = true,
}) {
  const url = `/properties/${hotelCode}`;
  return ajax.getJSON(`${CONTENT_API_BASE_URL}/${locale}${url}`).pipe(
    mergeMap((response) => {
      if (
        retryInEN &&
        locale !== "en" &&
        response?.error === "invalid property"
      ) {
        return ajax.getJSON(`${CONTENT_API_BASE_URL}/en/${url}`).pipe(
          map((enResponse) => ({ ...enResponse, webChatConfig: {}, disableWebChat: true }))
        );
      }
      return of(response);
    })
  );
}

export default function getPropertiesContent$({
  locale = "en",
  propertyCode,
  propertyCodes = [],
  retryInEN,
}) {
  return forkJoin(
    [...propertyCodes, propertyCode].filter(Boolean).map((hotelCode) => getPropertyContent$({
        locale,
        hotelCode,
        retryInEN,
      }).pipe(
        map((ajaxResponse) => ({ ajaxResponse, hotelCode }))
      ))
  );
}
