/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  error: "",
};

const paymentOptionsSlice = createSlice({
  name: "paymentOptions",
  initialState,
  reducers: {
    fetchPaymentOptions: (state) => {
      state.error = "";
    },

    fetchPaymentOptionsFulfilled: (state, { payload }) => {
      state.data = payload;
      state.error = "";
    },

    fetchPaymentOptionsFailed: (state, { payload }) => {
      state.error = payload.error;
    },

    fetchPaymentOptionsCancel: () => {},

    resetPaymentOptions: () => initialState,
  },
});

export const {
  fetchPaymentOptions,
  fetchPaymentOptionsFulfilled,
  fetchPaymentOptionsFailed,
  fetchPaymentOptionsCancel,
  resetPaymentOptions,
} = paymentOptionsSlice.actions;

export default paymentOptionsSlice.reducer;
