import React from "react";
import get from "lodash/fp/get";

import FieldSelector from "./FieldSelector";

export default function TransportationFormFieldSet({
  legend,
  fields = [],
  register,
  control,
  errors = {},
}) {
  return (
    <fieldset className="arrival-transportation__fieldset">
      {legend && (
        <div className="arrival-transportation__fieldset__legend ty-c3">
          {legend}
        </div>
      )}

      <div className="arrival-transportation__fieldset__fields">
        {fields
          .filter((field) => field.type !== "HIDDEN")
          .map((fieldProps) => (
              <FieldSelector
                key={fieldProps.name}
                {...fieldProps}
                register={register}
                control={control}
                error={get(`${fieldProps.name}.message`)(errors)}
              />
            ))}
      </div>
    </fieldset>
  );
}
