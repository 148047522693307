import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";

import contentAjax from "api/content/contentAjax";

export default function getAccommodationsContent$({
  locale = "en",
  propertyCode,
  propertyCodes = [],
  retryInEN,
}) {
  return forkJoin(
    [...propertyCodes, propertyCode].filter(Boolean).map((hotelCode) => contentAjax({
        locale,
        url: `/properties/${hotelCode}/accommodations`,
        retryInEN,
      }).pipe(map((ajaxResponse) => ({ ajaxResponse, hotelCode }))))
  );
}
