import get from "lodash/get";
import {
  add,
  sub,
  isAfter,
  isBefore,
  parse,
  differenceInDays,
  isSameYear,
} from "date-fns";

import { EMPLOYEE_SEARCH_ERRORS } from "store/searchResults/checkEmployeeSearchParams";
import { getEmployeeServiceDateThisYear, parseDate } from "utils/datesHelpers";
import { getEmployeeRateByCode } from "Profile/utils/getEmployeeRate";

function entitlementError(nights, locale) {
  let stayHistoryLink = "/profile/employee-stay-history";
  if (locale && locale !== "en") {
    stayHistoryLink = `/${locale}${stayHistoryLink}`;
  }
  return {
    errorCode: EMPLOYEE_SEARCH_ERRORS.BOOKING_EXCEEDS_YEARLY_ENTITLEMENT,
    params: [nights, stayHistoryLink],
  };
}

function checkEmployeeRoomNights({
  searchParams = {},
  stayHistory = [],
  globalSettings = {},
  employeeProfile = {},
  locale = "en",
} = {}) {
  if (
    !get(searchParams, ["dates", "checkIn"]) ||
    !get(searchParams, ["dates", "checkOut"])
  ) {
    return false;
  }

  const employeeRate = getEmployeeRateByCode(searchParams.promoCode);
  if (
    !employeeRate ||
    ((!Array.isArray(stayHistory) || !stayHistory.length) &&
      !employeeRate.compRate)
  ) {
    return false;
  }
  let nextServiceDate;
  let currentServiceDate;
  let currentYearlyEntitlement;
  let nextYearlyEntitlement;
  if (employeeRate.compRate) {
    if (
      employeeProfile.yearlyEntitlement &&
      !Number.isNaN(employeeProfile.yearlyEntitlement)
    ) {
      currentYearlyEntitlement = parseInt(
        employeeProfile.yearlyEntitlement,
        10
      );
    }
    if (
      (!currentYearlyEntitlement && currentYearlyEntitlement !== 0) ||
      Number.isNaN(currentYearlyEntitlement)
    ) {
      return entitlementError(0, locale);
    }
    nextYearlyEntitlement = currentYearlyEntitlement;

    nextServiceDate = getEmployeeServiceDateThisYear(employeeProfile);
    if (!nextServiceDate) {
      return entitlementError(0, locale);
    }
    if (isBefore(new Date(), nextServiceDate)) {
      currentServiceDate = sub(nextServiceDate, { years: 1 });
    } else {
      currentServiceDate = nextServiceDate;
      nextServiceDate = add(nextServiceDate, { years: 1 });
    }

    const apiNextServiceDate = parseDate(employeeProfile.nextServiceDate);
    if (apiNextServiceDate) {
      let apiNextServiceEntitlement;
      if (
        employeeProfile.nextEntitlement &&
        !Number.isNaN(employeeProfile.nextEntitlement)
      ) {
        apiNextServiceEntitlement = parseInt(
          employeeProfile.nextEntitlement,
          10
        );
      }
      if (
        (apiNextServiceEntitlement || apiNextServiceEntitlement === 0) &&
        !Number.isNaN(apiNextServiceEntitlement)
      ) {
        nextYearlyEntitlement = apiNextServiceEntitlement;
        nextServiceDate = apiNextServiceDate;
      }
    }
  }

  const maxRoomNights30Days = globalSettings.maxRoomNights30Days || 0;
  const maxRoomNightsCalendarYear =
    globalSettings.maxRoomNightsCalendarYear || 0;
  if (
    !maxRoomNights30Days &&
    !maxRoomNightsCalendarYear &&
    !employeeRate.compRate
  ) {
    return false;
  }

  const checkInDate = parse(
    searchParams.dates.checkIn,
    "yyyy-MM-dd",
    new Date()
  );
  const checkOutDate = parse(
    searchParams.dates.checkOut,
    "yyyy-MM-dd",
    new Date()
  );

  const roomNightsThisStay = differenceInDays(checkOutDate, checkInDate);
  if (roomNightsThisStay < 1) {
    return false;
  }

  const checkOut30Before = add(checkOutDate, { days: -31 });
  const checkIn30After = add(checkInDate, { days: 30 });

  if (
    maxRoomNightsCalendarYear &&
    roomNightsThisStay > maxRoomNightsCalendarYear
  ) {
    return {
      errorCode: EMPLOYEE_SEARCH_ERRORS.MAX_ROOM_NIGHTS_YEAR,
      params: [maxRoomNightsCalendarYear, 365],
    };
  }
  if (maxRoomNights30Days && roomNightsThisStay > maxRoomNights30Days) {
    return {
      errorCode: EMPLOYEE_SEARCH_ERRORS.MAX_ROOM_NIGHTS_30,
      params: [maxRoomNights30Days],
    };
  }

  let compNightsCurrentServiceYear = 0;
  let compNightsNextServiceYear = 0;
  if (employeeRate.compRate) {
    let dayOfStay = checkInDate;
    while (isBefore(dayOfStay, checkOutDate)) {
      if (isBefore(dayOfStay, nextServiceDate)) {
        if (!isBefore(dayOfStay, currentServiceDate)) {
          compNightsCurrentServiceYear += 1;
        }
      } else {
        compNightsNextServiceYear += 1;
      }
      dayOfStay = add(dayOfStay, { days: 1 });
    }
  }

  let roomNightsYear = roomNightsThisStay;
  let compHistoryCurrentServiceYear = 0;
  let compHistoryNextServiceYear = 0;
  const roomNights30Dates = [];

  if (Array.isArray(stayHistory)) {
    stayHistory.forEach((booking) => {
      const bookingRateCode = get(
        booking,
        ["hotelProducts", 0, "rooms", "room", 0, "ratePlanCode"],
        ""
      );
      const stayEmployeeRate = getEmployeeRateByCode(bookingRateCode);
      if (
        !!booking.startDate &&
        !!booking.endDate &&
        !get(booking, ["hotelProducts", 0, "fsCachedExistingReservation"]) &&
        !!stayEmployeeRate
      ) {
        let stayDate = parse(booking.startDate, "yyyy-MM-dd", new Date());
        const stayEndDate = parse(booking.endDate, "yyyy-MM-dd", new Date());
        while (isBefore(stayDate, stayEndDate)) {
          if (isSameYear(checkInDate, stayDate)) {
            roomNightsYear += 1;
          }
          if (
            isAfter(stayDate, checkOut30Before) &&
            isBefore(stayDate, checkIn30After)
          ) {
            roomNights30Dates.push(stayDate);
          }
          if (stayEmployeeRate.compRate) {
            if (isBefore(stayDate, nextServiceDate)) {
              if (!isBefore(stayDate, currentServiceDate)) {
                compHistoryCurrentServiceYear += 1;
              }
            } else {
              compHistoryNextServiceYear += 1;
            }
          }
          stayDate = add(stayDate, { days: 1 });
        }
      }
    });
  }

  if (maxRoomNightsCalendarYear && roomNightsYear > maxRoomNightsCalendarYear) {
    return {
      errorCode: EMPLOYEE_SEARCH_ERRORS.MAX_ROOM_NIGHTS_YEAR,
      params: [maxRoomNightsCalendarYear, 365],
    };
  }
  if (
    maxRoomNights30Days &&
    roomNightsThisStay + roomNights30Dates.length > maxRoomNights30Days
  ) {
    let stayRange30Date = checkOut30Before;
    let exceededmaxRoomNights30Days = false;
    while (
      isBefore(stayRange30Date, checkInDate) &&
      !exceededmaxRoomNights30Days
    ) {
      const stayRange30Start = stayRange30Date;
      const stayRange30End = add(stayRange30Date, { days: 31 });
      const roomNights = roomNights30Dates.reduce((nightCounter, stayDate) => {
        if (
          isAfter(stayDate, stayRange30Start) &&
          isBefore(stayDate, stayRange30End)
        ) {
          return nightCounter + 1;
        }
        return nightCounter;
      }, 0);

      if (roomNightsThisStay + roomNights > maxRoomNights30Days) {
        exceededmaxRoomNights30Days = true;
      }
      stayRange30Date = add(stayRange30Date, { days: 1 });
    }

    if (exceededmaxRoomNights30Days) {
      return {
        errorCode: EMPLOYEE_SEARCH_ERRORS.MAX_ROOM_NIGHTS_30,
        params: [maxRoomNights30Days],
      };
    }
  }

  if (
    compNightsCurrentServiceYear &&
    compNightsCurrentServiceYear + compHistoryCurrentServiceYear >
      currentYearlyEntitlement
  ) {
    return entitlementError(
      currentYearlyEntitlement - compHistoryCurrentServiceYear,
      locale
    );
  }
  if (
    compNightsNextServiceYear &&
    compNightsNextServiceYear + compHistoryNextServiceYear >
      nextYearlyEntitlement
  ) {
    return entitlementError(
      nextYearlyEntitlement - compHistoryNextServiceYear,
      locale
    );
  }

  return false;
}

export default checkEmployeeRoomNights;
