import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "hooks";

import * as routes from "Authentication/authenticationRoutes";

export default function IntroContent({
  messageType, // 'email' | 'SMS' | 'short'
  sendCodeTo,
  employeeMode = false,
  isYachtsMode = false,
  isUserPersistent = false,
}) {
  const { t, locale } = useTranslation();

  const path = employeeMode
    ? routes.employeeRegister.to({ locale })
    : routes.register.to({ locale });

  let translatedHTML = "";
  if (isYachtsMode) {
    translatedHTML =
      messageType === "email"
        ? t(
            `An ${messageType} with a 6-digit code was sent to <strong>{0}</strong>. If you did not receive a code, check your spam folder or contact your Personal Yacht Consultant for help.`,
            [sendCodeTo]
          )
        : t(
            `An ${messageType} with a 6-digit code was sent to <strong>{0}</strong>. If you did not receive a code, contact your Personal Yacht Consultant for help.`,
            [sendCodeTo]
          );
  } else if (isUserPersistent) {
    translatedHTML = t(
      `An ${messageType} with a 6-digit code was sent to <strong>{0}</strong>. Please enter the code in the fields shown.`,
      [sendCodeTo]
    );
  } else {
    translatedHTML = t(
      `An ${messageType} with a 6-digit code was sent to <strong>{0}</strong>. Please enter the code in the fields shown. If you did not receive a verification code, you may need to <a href='{1}' ui-sref='{2}'>register.</a>`,
      [sendCodeTo, path, "register_2018"]
    );
  }

  if (["email", "SMS"].includes(messageType)) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: translatedHTML,
        }}
      />
    );
  }

  return (
    <span
        dangerouslySetInnerHTML={{
          __html: t(
            "Please enter the 6-digit code we sent to <strong>{0}</strong>",
            [sendCodeTo]
          ),
        }}
      />
  );
}

IntroContent.propTypes = {
  messageType: PropTypes.oneOf(["email", "SMS", "short"]).isRequired,
  sendCodeTo: PropTypes.string.isRequired,
};
