import cloneDeep from "lodash/fp/cloneDeep";
import compose from "lodash/fp/compose";
import concat from "lodash/fp/concat";
import defaultTo from "lodash/fp/defaultTo";
import find from "lodash/fp/find";
import get from "lodash/fp/get";
import includes from "lodash/fp/includes";
import isEqual from "lodash/fp/isEqual";
import map from "lodash/fp/map";
import omit from "lodash/fp/omit";
import partition from "lodash/fp/partition";
import pickBy from "lodash/fp/pickBy";
import some from "lodash/fp/some";
import update from "lodash/fp/update";

import {
  CONSENT_SOURCE,
  MATTRESS,
  NEW_ADDRESS_ID,
  NEW_EMAIL_ID,
  NEW_PHONE_NUMBER_ID,
  NEW_PREFERENCE,
  NEW_SUBSCRIPTION,
  OPTING_OPTIONS,
  PILLOW,
  RANGE_TYPE,
  deprecatedSubscriptions,
} from "fixtures/constants";
import {
  foodAndDrinkPreferences,
  healthAndWellnessPreferences,
  mattressPreferences,
  pillowPreferences,
  roomPreferences,
  travelAndLifestylePreferences,
} from "fixtures/preferences";
import cleanPhoneNumber from "utils/cleanPhoneNumber";

const matchValueWithMasterCode =
  (masterCode) =>
  ({ value }) =>
    value && value === masterCode;

export const isPillowPreference = ({ masterCode }) =>
  pillowPreferences.options.some(matchValueWithMasterCode(masterCode));
export const isMattressPreference = ({ masterCode }) =>
  mattressPreferences.options.some(matchValueWithMasterCode(masterCode));
export const isRoomPreference = ({ masterCode }) =>
  roomPreferences.options.some(matchValueWithMasterCode(masterCode));

export const matchTitle =
  (string) =>
  ({ title }) =>
    title === string;
export const mapToViewPreference = ({ masterCode, name }) => ({
  label: name,
  value: masterCode,
});

const isSleepPreferenceDifferent =
  (preferenceFn) =>
  ({ guestPreferenceList, newPreference }) =>
    guestPreferenceList.some(
      (a) => preferenceFn(a) && a.masterCode !== newPreference
    ) || guestPreferenceList.every((a) => !preferenceFn(a));

const deactivate = (toDeactivate) => ({ ...toDeactivate, active: false });

const prepareSleepPreference =
  (isPreferenceFn, preference) => (guestPreferenceList, sleepPreferences) =>
    isSleepPreferenceDifferent(isPreferenceFn)({
      guestPreferenceList,
      newPreference: sleepPreferences[preference],
    })
      ? [
          ...(sleepPreferences[preference]
            ? [{ name: sleepPreferences[preference], type: NEW_PREFERENCE }]
            : []),
          ...guestPreferenceList.filter(isPreferenceFn).map(deactivate),
        ]
      : [];

const newMattressPreference = prepareSleepPreference(
  isMattressPreference,
  MATTRESS
);

const newPillowPreference = prepareSleepPreference(isPillowPreference, PILLOW);

export const prepareGuestPreferenceListWithSleepPreferences = (
  guestPreferenceList = [],
  sleepPreferences
) => [
  ...newMattressPreference(guestPreferenceList, sleepPreferences),
  ...newPillowPreference(guestPreferenceList, sleepPreferences),
];

export const prepareGuestPreferenceListWithRoomPreference = (
  guestPreferenceList = [],
  roomPreference
) => [
  ...(roomPreference ? [{ name: roomPreference, type: NEW_PREFERENCE }] : []),
  ...guestPreferenceList.filter(isRoomPreference).map(deactivate),
];

const matchMasterCodeWithValue =
  (value) =>
  ({ masterCode }) =>
    masterCode === value;

const matchValueWithValue =
  (newValue) =>
  ({ value }) =>
    value === newValue;

export const addSelection = (guestPreferenceList) =>
  [
    foodAndDrinkPreferences,
    healthAndWellnessPreferences,
    travelAndLifestylePreferences,
  ].map(({ title, options }) => ({
    title,
    options: options.map(({ value, ...rest }) => ({
      value,
      isSelected: guestPreferenceList.some(matchMasterCodeWithValue(value)),
      ...rest,
    })),
  }));

export const prepareGuestPreferenceListWithInterests = (
  guestPreferenceList = [],
  toggledInterest
) =>
  guestPreferenceList.some(matchMasterCodeWithValue(toggledInterest))
    ? guestPreferenceList
        .filter(matchMasterCodeWithValue(toggledInterest))
        .map(deactivate)
    : [{ name: toggledInterest, type: NEW_PREFERENCE }];

const updateInterestsOptions = ({ options, toggledInterest }) =>
  options.map((option) => ({
    ...option,
    isSelected:
      option.value === toggledInterest ? !option.isSelected : option.isSelected,
  }));

const getTitleOfUpdatedInterest = ({ toggledInterest }) =>
  [
    foodAndDrinkPreferences,
    healthAndWellnessPreferences,
    travelAndLifestylePreferences,
  ].find(({ options }) => options.some(matchValueWithValue(toggledInterest)));

export const updateInterests = (interests, toggledInterest) => {
  const { title } = getTitleOfUpdatedInterest({ toggledInterest });

  return interests.map((interest) =>
    interest.title === title
      ? {
          title: interest.title,
          options: updateInterestsOptions({
            options: interest.options,
            toggledInterest,
          }),
        }
      : interest
  );
};

export const prepareName = (nameFromProfile = {}, newTitle) => ({
  ...nameFromProfile,
  title: newTitle,
});

export const prepareEmails = (
  existingEmails = [],
  { email, salesForceId, emailType, defaultEmail, validatedEmail }
) =>
  salesForceId === NEW_EMAIL_ID
    ? [{ email, emailType }]
    : existingEmails
        .filter((email_) => salesForceId === email_.salesForceId)
        .map((email_) => ({
          ...email_,
          email: email || email_.email,
          emailType,
          defaultEmail,
          validatedEmail,
        }));

export const makeLoginEmail = ({
  extension,
  emails = [],
  email: { salesForceId, isLogin },
}) => ({
  ...cloneDeep(extension),
  digitalProfileLoginList:
    extension.digitalProfileLoginList?.map(
      update(["userName"], (userName) =>
        !userName.startsWith("+") && isLogin
          ? emails.find((email) => email.salesForceId === salesForceId)
              ?.email || userName
          : userName
      )
    ) || [],
});

export const getNotValidatedSignInEmail = ({ emails = [], signInEmail }) => emails.find(
    (email) => email.email === signInEmail && email.validatedEmail === false
  );

export const preparePhones = (
  existingPhones = [],
  { number, salesForceId, phoneType, isLogin }
) =>
  salesForceId === NEW_PHONE_NUMBER_ID
    ? [{ number: cleanPhoneNumber(number), phoneType }]
    : existingPhones
        .filter((phone) => salesForceId === phone.salesForceId)
        .map((phone) => ({
          ...phone,
          number: cleanPhoneNumber(number),
          phoneType,
          defaultPhone: isLogin,
        }));

export const makeLoginPhone = ({ extension, phone: { number, isLogin } }) => ({
  ...cloneDeep(extension),
  digitalProfileLoginList:
    extension.digitalProfileLoginList?.map(
      update(["userName"], (userName) =>
        userName.startsWith("+") && isLogin ? `+${number}` : userName
      )
    ) || [],
});

export const prepareAddresses = (
  existingAddresses = [],
  {
    addressLines,
    addressType,
    city,
    country,
    postCode,
    salesForceId,
    state,
    defaultAddress,
  }
) =>
  salesForceId === NEW_ADDRESS_ID
    ? [
        {
          addressLines: [addressLines],
          addressType,
          city,
          country,
          defaultAddress,
          postCode,
          state,
        },
      ]
    : existingAddresses
        .filter((address) => salesForceId === address.salesForceId)
        .map((address) => ({
          ...address,
          addressLines: [addressLines],
          addressType,
          city,
          country,
          defaultAddress,
          postCode,
          state,
        }));

export const deleteEmail = (existingEmails = [], { salesForceId }) =>
  existingEmails
    .filter((email) => email.salesForceId === salesForceId)
    .map((email) => ({ ...email, activeEmail: false }));

export const deletePhone = (existingPhones = [], { salesForceId }) =>
  existingPhones
    .filter((phone) => phone.salesForceId === salesForceId)
    .map((phone) => ({ ...phone, activePhone: false }));

export const deleteAddress = (existingAddresses = [], { salesForceId }) =>
  existingAddresses
    .filter((address) => address.salesForceId === salesForceId)
    .map((address) => ({ ...address, activeAddress: false }));

export const separateGlobalAndLocalSubscriptions = (allSubscriptions) => ({
  global: pickBy(({ range }) => range === RANGE_TYPE.GLOBAL, allSubscriptions),
  local: pickBy(({ range }) => range === RANGE_TYPE.LOCAL, allSubscriptions),
});

export const buildLocalSubscriptionKey = ({ mailingList, propertyCode }) =>
  `${mailingList}:${propertyCode}`;

export const prepareMailSubscriptionList = (
  existingMailSubscriptions = [],
  updatedEmailSubscriptions
) => {
  const updatedEmailSubscriptionsWithDeprecated = {
    ...updatedEmailSubscriptions,
    ...(deprecatedSubscriptions.reduce((acc, mailingList) => ({
        ...acc,
        [mailingList]: {
          optInOption: false,
        },
      }), {}) || {}),
  };

  const [local, global] = partition(
    ({ range }) => range === RANGE_TYPE.LOCAL,
    existingMailSubscriptions
  );

  const globalUpdatedEmailSubscriptionsWithDeprecated = omit(
    local.map(buildLocalSubscriptionKey),
    updatedEmailSubscriptionsWithDeprecated
  );

  const updatedLocal = local.map((a) => {
    const isOptingIn =
      updatedEmailSubscriptionsWithDeprecated[buildLocalSubscriptionKey(a)]
        ?.optInOption;

    return {
      ...a,
      optInOption: isOptingIn ? OPTING_OPTIONS.IN : OPTING_OPTIONS.OUT,
    };
  });

  const updatedGlobal = Object.keys(
    globalUpdatedEmailSubscriptionsWithDeprecated
  )
    .map((key) => ({
      ...(global.find((e = {}) => key === e.mailingList) || {
        name: NEW_SUBSCRIPTION,
        mailingList: key,
      }),
      optInOption: globalUpdatedEmailSubscriptionsWithDeprecated[key]
        .optInOption
        ? OPTING_OPTIONS.IN
        : OPTING_OPTIONS.OUT,
      consentSource: CONSENT_SOURCE,
    }))
    .filter(
      ({ name, optInOption }) =>
        !(optInOption === OPTING_OPTIONS.OUT && name === NEW_SUBSCRIPTION)
    );

  return [...updatedGlobal, ...updatedLocal];
};

export const groupLocalSubscriptionsByProperty = (localSubscriptions = {}) =>
  Object.keys(localSubscriptions).reduce((acc, key) => {
    const [_, propertyCode] = key.split(":");
    const isEqualToPropertyCode = compose(
      isEqual(propertyCode),
      get(["propertyCode"])
    );

    return some(isEqualToPropertyCode, acc)
      ? map(
          (a) =>
            isEqualToPropertyCode(a)
              ? {
                  ...a,
                  subscriptions: [...a.subscriptions, localSubscriptions[key]],
                }
              : a,
          acc
        )
      : concat(acc, {
          propertyCode,
          propertyName: localSubscriptions[key].propertyName,
          subscriptions: [{ ...localSubscriptions[key] }],
        });
  }, []);

export const prepareCountryAndLanguage = (
  existingCountryOfResidence,
  existingPreferredLanguage,
  { countryOfResidence, preferredLanguage }
) => ({
  countryOfResidence: countryOfResidence || existingCountryOfResidence,
  preferredLanguage: preferredLanguage || existingPreferredLanguage,
});

export const makeUpdateSubscriptions = ({
  extension,
  mailSubscriptions,
  updateSubscriptions = false,
}) => {
  if (!updateSubscriptions) {
    return extension;
  }
  return {
    ...cloneDeep(extension),
    mailSubscriptionList: prepareMailSubscriptionList(
      extension?.mailSubscriptionList,
      mailSubscriptions
    ).map(
      ({ salesForceId: _ignoredSalesForceId, ...mailSubscription }) =>
        mailSubscription
    ),
  };
};

const getEmailFromDigitalProfile = compose(
  get(["userName"]),
  find(compose(includes("@"), get(["userName"]))),
  get(["profile", "data", "extension", "digitalProfileLoginList"])
);

const getEmailFromEmails = compose(
  get(["email"]),
  find(get(["defaultEmail"])),
  get(["profile", "data", "emails"])
);

export const getLoginEmail = (store) =>
  compose(
    defaultTo(getEmailFromDigitalProfile(store)),
    getEmailFromEmails
  )(store);
