import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";

import { searchResultsClear } from "store/searchResults";
import { upgradeRoomsClear } from "store/bookings";
import { resetBookingFlow } from "../bookingFlow.actions";

export default function resetBookingFlowEpic(action$) {
  return action$.pipe(
    ofType(resetBookingFlow.type),
    mergeMap(({ payload: { locale } }) => [searchResultsClear({ locale }), upgradeRoomsClear()])
  );
}
