import { ofType } from "redux-observable";
import { of } from "rxjs";
import {
  catchError,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import get from "lodash/get";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { fetchBookingCommentsFulfilled } from "store/bookingComments";
import catchInternalServerError from "store/catchInternalServerError";
import { fetchTermsAndConditionsContent } from "store/termsAndConditionsContent";
import {
  fetchBookingInProgress,
  fetchBookingInProgressCancel,
  fetchBookingInProgressFailed,
  fetchBookingInProgressFulfilled,
} from "../bookings.slice";
import fetchBookingWithCommentsById$ from "./fetchBookingWithCommentsById";
import { getTermsAndConditionsOptions } from "./fetchHistoricBookingAndContent";

export default function fetchBookingInProgressEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchBookingInProgress.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { locale },
        },
        {
          bookings: { bookingInProgressId: bookingId },
        },
      ]) => {
        if (!bookingId) {
          return of(fetchBookingInProgressCancel());
        }

        return ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() => fetchBookingWithCommentsById$({
              bookingId,
              locale,
            }).pipe(
              mergeMap(
                ({ booking, preBookingComments, postBookingComments }) => [
                    fetchTermsAndConditionsContent({
                      hotelCode: get(booking, [
                        "hotelProducts",
                        0,
                        "hotelCode",
                      ]),
                      locale,
                      optionsList: [getTermsAndConditionsOptions(booking)],
                    }),
                    fetchBookingInProgressFulfilled({ booking }),
                    fetchBookingCommentsFulfilled({
                      bookingId,
                      reservationId: get(booking, [
                        "hotelProducts",
                        0,
                        "reservationId",
                      ]),
                      preBookingComments,
                      postBookingComments,
                    }),
                  ]
              ),

              catchInternalServerError(),

              catchError(({ response = {} }) =>
                of(
                  fetchBookingInProgressFailed({
                    apiErrors: response?.apiErrors || [],
                    supplierErrors: response?.supplierErrors || [],
                  })
                )
              ),

              takeUntil(action$.pipe(ofType(fetchBookingInProgressCancel.type)))
            ))
        );
      }
    )
  );
}
