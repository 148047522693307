import { ofType } from "redux-observable";
import { iif, of, EMPTY } from "rxjs";
import {
  catchError,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import { push } from "connected-react-router";
import queryString from "query-string";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { addHotelProduct$, createBooking$ } from "api/tretail/booking";
import { updateHotelProductComments$ } from "api/tretail/bookingProductCust";
import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";
import { fetchBookingCommentsFulfilled } from "store/bookingComments";
import { SECTIONS } from "BookingFlow/ConfirmYourStay/hooks/constants";
import catchInternalServerError from "store/catchInternalServerError";
import isMobileApp from "utils/isMobileApp";
import { authenticationRoutes } from "Authentication";
import {
  addPackage,
  addPackageFailed,
  addPackageFulfilled,
  addPackageCancel,
} from "../bookings.slice";
import {
  fetchTermsAndConditionsContent,
  getTermsAndConditionsOptionsFromBooking,
} from "../../termsAndConditionsContent";
import fetchBookingWithCommentsById$ from "./fetchBookingWithCommentsById";

export function addPackage$({
  actions = [],
  fromConstructBooking = false,
  locale,
  resultSetId,
  salesChannel,
  searchParams,
  selectedPackages,
  isSignedIn = false,
  asMember = false,
}) {
  return createBooking$({ locale }).pipe(
    switchMap(({ response: { bookingId, supplierErrors } }) => {
      if (supplierErrors) {
        return of(addPackageFailed({ supplierErrors }));
      }
      return addHotelProduct$({
        bookingId,
        resultSetId,
        selectedPackages,
        locale,
      }).pipe(
        mergeMap(
          ({ response: { supplierErrors: addHotelProductSupplierErrors } }) => {
            if (addHotelProductSupplierErrors) {
              return of(
                addPackageFailed({
                  supplierErrors: addHotelProductSupplierErrors,
                })
              );
            }

            return fetchBookingWithCommentsById$({
              bookingId,
              locale,
            }).pipe(
              switchMap(({ booking, preBookingComments: { comments } }) => {
                const preBookingComments = comments.map((comment, index) => ({
                    ...comment,
                    ...(selectedPackages[index].isAccessible
                      ? { adaRoomRequest: true }
                      : {}),
                  }));

                const preBookingCommentsToSave = preBookingComments.filter(
                  (c) => c.adaRoomRequest
                );

                return iif(
                  () => preBookingCommentsToSave.length > 0,
                  updateHotelProductComments$({
                    bookingId,
                    comments: preBookingCommentsToSave,
                    locale,
                  }),
                  of(EMPTY)
                ).pipe(
                  mergeMap(() => {
                    const confirmYourStayRoute = {
                      pathname: bookingFlowRoutes.confirmYourStay.to({
                        locale,
                      }),
                      search: queryString.stringify({
                        channel: salesChannel,
                        bookingType: searchParams.ppMode
                          ? SECTIONS.AGENT
                          : SECTIONS.MYSELF,
                        ...(isMobileApp()
                          ? {
                              vid: searchParams.vid,
                              source: searchParams.source,
                            }
                          : {}),
                        ...(!(asMember || fromConstructBooking)
                          ? {
                              _s_icmp: `rbf_check_out_${
                                isSignedIn ? "selectpackage" : "guest"
                              }`,
                            }
                          : {}),
                      }),
                      analyticsData: {
                        from: window.location.href,
                        availCheckTemplate: searchParams.availCheckTemplate,
                        roomFindingMethod: searchParams.roomFindingMethod,
                      },
                    };

                    return [
                      addPackageFulfilled({
                        booking,
                        searchParams,
                      }),
                      fetchBookingCommentsFulfilled({
                        bookingId,
                        preBookingComments: { comments: preBookingComments },
                      }),
                      fetchTermsAndConditionsContent({
                        locale,
                        hotelCode: searchParams.hotelCode,
                        optionsList: getTermsAndConditionsOptionsFromBooking({
                          booking,
                          hotelCode: searchParams.hotelCode,
                        }),
                      }),
                      ...(asMember
                        ? [
                            push({
                              pathname: authenticationRoutes.signIn.to({
                                locale,
                              }),
                              state: {
                                afterSignInRedirectTo: confirmYourStayRoute,
                              },
                              search: queryString.stringify({
                                _s_icmp: "rbf_check_out_member",
                              }),
                            }),
                          ]
                        : [push(confirmYourStayRoute)]),
                      ...actions,
                    ];
                  })
                );
              })
            );
          }
        )
      );
    }),

    catchInternalServerError(),

    catchError((error) => {
      if (fromConstructBooking) {
        throw error;
      }
      return of(
        addPackageFailed({
          apiErrors: error?.response?.apiErrors || [],
          supplierErrors: error?.response?.supplierErrors || [],
        })
      );
    })
  );
}

export default function addPackageEpic(action$, state$) {
  return action$.pipe(
    ofType(addPackage.type),
    withLatestFrom(state$),
    switchMap(([{ payload }, { router }]) => {
      const { channel: salesChannel, ppMode, ppUrl } = router?.location?.query;
      let searchParams = {};
      if (ppMode) {
        searchParams = { ppMode, ppUrl };
      }
      return ajaxWithHealthCheck$({
        locale: payload.locale,
        propertyCode: router?.location?.query?.hotelCode,
        searchParams,
      }).pipe(
        switchMap(() => addPackage$({ ...payload, salesChannel }).pipe(
            takeUntil(action$.pipe(ofType(addPackageCancel.type)))
          ))
      );
    })
  );
}
