import { ofType } from "redux-observable";
import { catchError, mergeMap, switchMap, takeUntil } from "rxjs/operators";
import { of } from "rxjs";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { updateHotelProductPostBookingComments$ } from "api/tretail/bookingProductCust";
import fetchBookingWithCommentsById$ from "store/bookings/epics/fetchBookingWithCommentsById";
import { submitBookingPreferencesFulfilled } from "store/bookings/bookings.slice";
import catchInternalServerError from "store/catchInternalServerError";
import {
  updatePostBookingComments,
  updatePostBookingCommentsFulfilled,
  updatePostBookingCommentsFailed,
  updatePostBookingCommentsCancel,
} from "../bookingComments.slice";

export const handleUpdatePostBookingCommentsFailed = ({ response = {} }) =>
  of(
    updatePostBookingCommentsFailed({
      apiErrors: response?.apiErrors || [],
      supplierErrors: response?.supplierErrors || [],
    })
  );

export default function updatePostBookingCommentsEpic(action$) {
  return action$.pipe(
    ofType(updatePostBookingComments.type),

    switchMap(({ payload: { bookingId, comments, locale } }) => {
      const amendedComments = comments.map(
        ({
          adjoiningRooms: _ignoredAdjoiningRooms,
          keepRoomsClose: _ignoredKeepRoomsClose,
          ...c
        }) => c
      );
      return ajaxWithHealthCheck$({
        locale,
      }).pipe(
        switchMap(() => updateHotelProductPostBookingComments$({
            bookingId,
            comments: amendedComments,
            locale,
          }).pipe(
            switchMap(() => fetchBookingWithCommentsById$({
                bookingId,
                locale,
              }).pipe(
                mergeMap(({ booking, postBookingComments }) => [
                    updatePostBookingCommentsFulfilled({
                      bookingId,
                      postBookingComments,
                    }),
                    submitBookingPreferencesFulfilled({
                      booking,
                    }),
                  ])
              )),

            catchInternalServerError(),

            catchError(handleUpdatePostBookingCommentsFailed),

            takeUntil(
              action$.pipe(ofType(updatePostBookingCommentsCancel.type))
            )
          ))
      );
    })
  );
}
