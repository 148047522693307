import { ofType } from "redux-observable";
import { map, switchMap, takeUntil, catchError } from "rxjs/operators";
import { of } from "rxjs";

import { getAppStatus$ } from "api/content";
import {
  fetchAppStatus,
  fetchAppStatusFulfilled,
  fetchAppStatusCancel,
} from "../appStatus.slice";

export default function getAppStatusEpic(action$) {
  return action$.pipe(
    ofType(fetchAppStatus.type),
    switchMap(({ payload: { locale } }) =>
      getAppStatus$({ locale }).pipe(
        map(fetchAppStatusFulfilled),
        catchError(({ response }) => of(
            fetchAppStatusFulfilled(
              response || { message: "Internal Server Error" }
            )
          )),
        takeUntil(action$.pipe(ofType(fetchAppStatusCancel.type)))
      )
    )
  );
}
