import { ajax } from "rxjs/ajax";

import env from "config/env";
import { baseDeleteRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;
const TRETAIL_API_BOOKINGS_URL = `${TRETAIL_API_BASE_URL}/bookings`;

const deleteAllCustomers$ = ({ bookingId, locale }) => ajax({
    ...baseDeleteRequest({ locale }),
    url: `${TRETAIL_API_BOOKINGS_URL}/${bookingId}/customers`,
  });

export default deleteAllCustomers$;
