/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { createMiddleware } from "redux-beacon";
import { LOCATION_CHANGE } from "connected-react-router";
import debounceEvent from "@redux-beacon/debounce-event";
import combineEvents from "@redux-beacon/combine-events";
import flow from "lodash/fp/flow";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import map from "lodash/fp/map";
import pick from "lodash/fp/pick";
import reduce from "lodash/fp/reduce";
import values from "lodash/fp/values";
import flatMap from "lodash/fp/flatMap";
import { parseISO, differenceInDays, startOfDay } from "date-fns/fp";

import { getHotelCodeFromPathname, getSourceName } from "utils";
import { appStartFulfilled } from "store/app";
import {
  missingLanguage,
  changeSearch,
  fetchSearchResultsFulfilled,
  fetchSearchResultsFailed,
  sortResultsEvt,
  accessibleRoomsToggleEvt,
  viewRoomDetailsEvt,
  viewFloorplanEvt,
  viewServicesAmenitiesEvt,
} from "store/searchResults";
import {
  changeRoomEvt,
  viewPackagesEvt,
  viewNextRoomEvt,
  submitBookingPreferences,
  submitConfirmationToolEvt,
  upgradeRoomsFulfilled,
  singleRoomUpsellEvt,
  multiRoomUpsellEvt,
  viewPackageIncludedEvt,
  viewRequestInvoiceFormEvt,
  requestInvoiceFulfilled,
} from "store/bookings";
import {
  webCheckInStartEvt,
  webCheckInErrorEvt,
  webCheckInRegistrationEvt,
  webCheckInCompleteEvt,
} from "store/webCheckIn";
import { setCurrencyCode } from "store/exchangeRates";
import { setViewState } from "store/registration";
import {
  createProfileFulfilled,
  createProfileFailed,
  updateUserEmailFulfilled,
  deleteUserEmailFulfilled,
  updateEmailSubscriptions,
  updateUserPhone,
  deleteUserPhone,
  updateUserAddress,
  deleteUserAddress,
  updateCountryAndLanguage,
  toggleInterestFulfilled,
  updateSleepPreferencesFulfilled,
  updateRoomPreference,
} from "store/profile";
import { submitOtpFulfilled } from "store/signIn";
import { setIsOpen, setIsSuccess } from "store/addReservationModal";
import { updateProfileComments } from "store/bookingComments";
import { updateChatStatus, webChatAccepted } from "store/appStatus";
import {
  leadWithCareLinkClicked,
  leadWithCareTravelAdvisoryButtonClicked,
  leadWithCareCheckHotelStatusLinkClicked,
  locationChanged,
  itineraryActivityDetailsModal,
  openTransportationRequestForm,
  buildYourItineraryAction,
  requestCtaClicked,
  welcomeAmentitiesAdded,
  welcomeAmentitiesRemoved,
  contextualLinkClick,
} from "store/analyticEvents";
import { confirmYourStayReady } from "store/bookingFlow";
import { addRequestEventFulfilled } from "store/guestRequests";

import {
  allInPricingToggleInteraction,
  flexDateSelected,
} from "BookingFlow/ChooseYourRoom/chooseYourRoom.actions";
import { getGoldenIdString } from "utils/FSSecurityMX";
import tealiumTarget, {
  trackEvent,
  trackView,
  getLocationChangeData,
  getPropertyData,
  getProfileData,
  getAvailCheckData,
  userIsEmployee,
  getMultiRoomData,
  isInRbf,
  isOnStepOne,
  isRouteToSkipAppStart,
} from "../Tealium/tealium";
import getFlexDatesAnalyticsData from "../Tealium/getFlexDatesAnalyticsData";

const employeeRegisterRegex = /\/employee-travel\/register/;

function locationChange({
  pathname,
  hotelCode: initialHotelCode,
  enforceProceed = false,
  nextState,
}) {
  if (userIsEmployee(nextState) || pathname.includes("/employee-travel")) {
    window.utag_data.is_internal = true;
  }

  const doNotProceedForPathNames = [
    /^(\/\S{2})?\/choose-your-room/, // tracked by fetchSearchResultsFulfilled action
    /^(\/\S{2})?\/confirm-your-stay/, // tracked by confirmYourStayReady action
    /^(\/\S{2})?\/check-in/, // tracked by webCheckInStartEvt action
    /^(\/\S{2})?(\/profile)?\/upcoming-trip\/(\S{6})\/(\d+)\/itinerary/, // tracked by locationChanged action
  ];

  const proceed =
    enforceProceed ||
    !doNotProceedForPathNames.some((regex) => regex.test(pathname));

  const hotelCode = initialHotelCode ?? getHotelCodeFromPathname(pathname);

  return {
    isLocationChange: true,
    proceed,
    ...getLocationChangeData(pathname),
    ...getPropertyData(hotelCode, nextState),
  };
}

// If the visitor has an upcoming stay, this should be a numeric integer cast as a string indicating the number of days until their stay begins,
// if the visitor's account has no record of an upcoming stay this should be set as "no upcoming stay",
// if visitor is currently staying at a property and/or has a stay starting today this should be set as "in stay"
const getUpcomingStayValue = flow(
  get(["hotelProducts", 0]),
  pick(["checkInDate", "checkOutDate"]),
  values,
  map(parseISO),
  (dates) => {
    if (dates.length === 0) {
      return "no upcoming stay";
    }

    const today = startOfDay(new Date());
    const diff = differenceInDays(today)(dates[0]);

    if (diff > 0) {
      return diff.toString();
    }

    const diff2 = differenceInDays(today)(dates[1]);
    if (diff2 >= 0) {
      return "in stay";
    }

    return "no upcoming stay";
  }
);

const eventsMap = {
  [locationChanged.type]: debounceEvent(
    2000,
    trackView(
      (
        {
          payload: {
            location: { pathname },
            hotelCode,
          },
        },
        prevState,
        nextState
      ) => locationChange({
          pathname,
          hotelCode,
          enforceProceed: true,
          nextState,
        })
    )
  ),

  [LOCATION_CHANGE]: debounceEvent(
    2000,
    trackView(({ payload }, prevState, nextState) => {
      const {
        location: { pathname },
        hotelCode,
      } = payload;

      return locationChange({
        pathname,
        hotelCode,
        nextState,
      });
    })
  ),

  [confirmYourStayReady.type]: debounceEvent(
    3000,
    trackView(({ payload }, prevState, nextState) => {
      const {
        router: {
          location: { pathname },
        },
      } = nextState;
      if (userIsEmployee(nextState) || pathname.includes("/employee-travel")) {
        window.utag_data.is_internal = true;
      }

      return {
        isLocationChange: true,
        proceed: pathname === "/confirm-your-stay", // RBF tracks page views via events except for step 1 page load
        ...getLocationChangeData(pathname),
      };
    })
  ),

  [appStartFulfilled.type]: debounceEvent(
    3000,
    trackView(({ payload }, prevState, nextState) => {
      const {
        router: {
          location: { pathname },
        },
      } = nextState;
      if (userIsEmployee(nextState) || pathname.includes("/employee-travel")) {
        window.utag_data.is_internal = true;
      }

      // RBF tracks page views via events except for step 1 page load
      // Authentication Routes track page views via LOCATION_CHANGE
      return {
        isLocationChange: true,
        proceed: (!isInRbf() || isOnStepOne()) && !isRouteToSkipAppStart(),
        ...getLocationChangeData(pathname),
      };
    })
  ),

  [missingLanguage.type]: trackEvent(({ payload: { property, locale } }) => ({
    interaction_type: "error",
    interaction_detail: `${property.name} not available in ${locale}`,
    interaction_name: "rbe",
  })),

  [changeSearch.type]: combineEvents(
    trackEvent(() => ({
      event_name: "room_or_package_change",
    })),
    trackEvent(() => ({
      event_name: "interaction",
      interaction_type: "booking_engine",
      interaction_name: "change_search",
    }))
  ),

  [fetchSearchResultsFulfilled.type]: trackView(
    ({ payload }, prevState, nextState) => ({
        event_name: "avail_check",
        ...getAvailCheckData(prevState, nextState, payload),
        ...(payload?.refetch ? { proceed: false } : {}),
      })
  ),

  [fetchSearchResultsFailed.type]: trackView(
    ({ payload }, prevState, nextState) => ({
      event_name: "avail_check",
      ...getAvailCheckData(prevState, nextState, payload),
    })
  ),

  [allInPricingToggleInteraction]: trackEvent(
    ({ payload: { isAllInPricing } }) => ({
      event_name: "interaction",
      interaction_type: "booking_engine",
      interaction_name: "all-in pricing",
      interaction_detail: isAllInPricing ? "enabled" : "disabled",
    })
  ),

  [flexDateSelected]: trackEvent(() => ({
    event_name: "interaction",
    interaction_type: "booking_engine",
    interaction_name: "flex dates",
    interaction_detail: "date carousel",
  })),

  [submitBookingPreferences.type]: trackEvent(
    ({
      payload: {
        comments,
        amenities,
        welcomeAmenitiesChanged,
        transferChanged,
        specialRequestChanged,
      },
    }) => ({
      event_name: `personalize_stay${
        welcomeAmenitiesChanged ? ",personalize_amenities" : ""
      }${transferChanged ? ",personalize_transportation" : ""}${
        specialRequestChanged ? ",personalize_comments" : ""
      }`,
      room_amenities_array: amenities[0],
      personalize_type: Object.keys(comments[0]).filter(
        (item) => item !== "productId"
      ),
    })
  ),

  [submitConfirmationToolEvt.type]: trackEvent(() => ({
    interaction_name: "RCT|Generate a Reservation Confirmation",
  })),

  [upgradeRoomsFulfilled.type]: combineEvents(
    trackEvent(
      ({
        payload: {
          roomIndices,
          booking: { hotelProducts },
        },
      }) => ({
        event_name: "upsell_accept",
        room_added_array: hotelProducts.flatMap((product, index) =>
          roomIndices.includes(index + 1) ? ["", "1"] : []
        ),
        room_removed_array: hotelProducts.flatMap((product, index) =>
          roomIndices.includes(index + 1) ? ["1", ""] : []
        ),
        proceed: true,
      })
    ),
    trackView(() => ({
        isLocationChange: true,
        proceed: true,
        event_name: "confirm_stay",
        page_type: "checkout",
      }))
  ),

  [viewPackagesEvt.type]: trackView(({ payload }, prevState, nextState) => {
    const { flexDatesCalendarPrices } =
      nextState.searchResults.createHotelSearchResponse;
    const searchedCheckInDate = payload.searchParams.dates.checkIn;
    const searchedCheckOutDate = payload.searchParams.dates.checkOut;
    const selectedCheckInDate = payload?.selectedFlexDate
      ? payload.selectedFlexDate.checkinDate
      : searchedCheckInDate;
    const selectedCheckOutDate = payload?.selectedFlexDate
      ? payload.selectedFlexDate.checkoutDate
      : searchedCheckOutDate;

    const proceed =
      !prevState.router?.location?.query?.promoCode?.includes("EMPECE");

    const flexDatesAnalyticsData = getFlexDatesAnalyticsData({
      flexDatesCalendarPrices,
      selectedCheckInDate,
      searchedCheckInDate,
      isAccessible: payload.isAccessible,
      exchangeRates: nextState.exchangeRates.data,
    });

    const { booking_availability: _bookingAvailability, ...availCheckData } =
      getAvailCheckData(prevState, nextState, payload);

    return {
      event_name: "select_package",
      proceed,

      ...availCheckData,
      booking_arrival_date: selectedCheckInDate,
      booking_departure_date: selectedCheckOutDate,
      ...flexDatesAnalyticsData,
    };
  }),

  [viewNextRoomEvt.type]: trackView(({ payload }, prevState, nextState) => ({
      event_name: "room_or_package_change",
      ...getAvailCheckData(prevState, nextState, {
        ...payload,
        roomCodes: [...payload.roomSelections],
      }),
      ...getMultiRoomData(prevState, nextState, payload),
    })),

  [changeRoomEvt.type]: trackEvent(() => ({
    event_name: "interaction",
    interaction_name: "change search",
  })),

  [singleRoomUpsellEvt.type]: trackEvent(() => ({
    event_name: "upsell_impression",
    upsell_location: "confirm stay",
  })),

  [multiRoomUpsellEvt.type]: trackEvent(() => ({
    event_name: "upsell_impression",
    upsell_location: "confirm stay popup",
  })),

  [sortResultsEvt.type]: trackEvent(({ payload: { sortValue } }) => ({
    event_name: "interaction",
    interaction_type: "booking_engine",
    interaction_name: "room_sort",
    interaction_detail: sortValue,
  })),

  [setCurrencyCode.type]: trackEvent(
    ({ payload: { currencyCode, locale } }) => ({
      event_name: "interaction",
      interaction_type: "booking_engine",
      interaction_name: "currency_conversion",
      interaction_detail: currencyCode,
      proceed: !!locale, // locale is only sent on currency change
    })
  ),

  [accessibleRoomsToggleEvt.type]: trackEvent(
    ({ payload: { isAccessible } }) => ({
      event_name: "interaction",
      interaction_type: "booking_engine",
      interaction_name: "accessibility filter",
      interaction_detail: isAccessible ? "is accessible" : "no filter",
    })
  ),

  [viewRoomDetailsEvt.type]: trackEvent(({ payload: { roomName } }) => ({
    event_name: "interaction",
    interaction_type: "booking_engine",
    interaction_name: "view room info dropdown",
    interaction_detail: roomName,
  })),

  [viewFloorplanEvt.type]: trackEvent(({ payload: { roomName } }) => ({
    event_name: "interaction",
    interaction_type: "booking_engine",
    interaction_name: "view floorplan",
    interaction_detail: roomName,
  })),

  [viewServicesAmenitiesEvt.type]: trackEvent(({ payload: { roomName } }) => ({
    event_name: "interaction",
    interaction_type: "booking_engine",
    interaction_name: "view services and amenities",
    interaction_detail: roomName,
  })),

  [viewPackageIncludedEvt.type]: trackEvent(({ payload: { packageName } }) => ({
    event_name: "interaction",
    interaction_type: "booking_engine",
    interaction_name: "rbf modal package detail",
    interaction_detail: packageName,
  })),

  [webCheckInStartEvt.type]: trackView(
    ({ payload: { hotelCode } }, prevState, nextState) => {
      const propertyData = getPropertyData(hotelCode, nextState);
      return {
        event_name: "checkin_start,interaction",
        interaction_name: `check in start:${propertyData.property_name || ""}`,
        page_section: `${propertyData.property_name || "unknown"}:check in`,
      };
    }
  ),

  [webCheckInErrorEvt.type]: trackView(
    ({ payload: { error, hotelCode } }, prevState, nextState) => {
      const propertyData = getPropertyData(hotelCode, nextState);
      return {
        event_name: "interaction",
        interaction_name: `check in error:${error}:${
          propertyData.property_name || ""
        }`,
        page_section: `${propertyData.property_name || "unknown"}:check in`,
      };
    }
  ),

  [webCheckInRegistrationEvt.type]: trackView(
    ({ payload: { isEarlyCheckIn, hotelCode } }, prevState, nextState) => {
      const propertyData = getPropertyData(hotelCode, nextState);
      return {
        event_name: "interaction",
        interaction_name: `check in registration_${
          isEarlyCheckIn ? "early" : "standard"
        }:${propertyData.property_name}`,
        page_section: `${propertyData.property_name || "unknown"}:check in`,
      };
    }
  ),

  [webCheckInCompleteEvt.type]: trackView(
    ({ payload: { hotelCode, noMarketing } }, prevState, nextState) => {
      const propertyData = getPropertyData(hotelCode, nextState);
      return {
        event_name: "checkin_complete,interaction",
        interaction_name: `check in confirmed_${
          noMarketing ? "optout" : "optin"
        }:${propertyData.property_name}`,
        page_section: `${propertyData.property_name || "unknown"}:check in`,
      };
    }
  ),

  [setViewState.type]: debounceEvent(
    2000,
    trackView(({ payload: viewState }) => {
      let formName;
      if (viewState === "ENTER_RESERVATION_DETAILS") {
        formName = "Register with reservation";
      } else if (viewState === "ENTER_REGISTRATION_DETAILS") {
        formName = "Register without reservation";
      } else {
        return {
          proceed: false,
        };
      }

      return {
        proceed:
          formName && !window.location.pathname.includes("employee-travel"),
        event_name: "form_view",
        form_name: formName,
      };
    })
  ),

  [createProfileFulfilled.type]: trackEvent(() => ({
    event_name: "register,form_submit",
    ...(window.location.pathname.match(employeeRegisterRegex)
      ? { form_name: "Employee Register" }
      : {}),
  })),

  [createProfileFailed.type]: trackEvent(
    ({ payload: { apiErrors, supplierErrors } }) => ({
        event_name: "form_error",
        form_error: apiErrors.length
          ? apiErrors
              .map((error) => error.userMessage || error.errorCode)
              .toString()
          : supplierErrors
              .map((error) => error.shortText || error.errorCode)
              .toString(),
        ...(window.location.pathname.match(employeeRegisterRegex)
          ? { form_name: "Employee Register" }
          : {}),
      })
  ),

  [submitOtpFulfilled.type]: trackEvent(({ payload }, prevState, nextState) => {
    const otpMethod = prevState?.signIn?.submitOTP.sendCodeVia;
    let loginMethod;
    if (otpMethod === "EMAIL_ADDRESS") {
      loginMethod = "email";
    } else if (otpMethod === "PHONE_NUMBER") {
      loginMethod = "phone";
    }
    const enablePersistentLogin = prevState?.signIn?.submitOTP.isUserPersistent
      ? "na"
      : prevState?.signIn?.submitOTP.persist
      ? "enabled"
      : "disabled";
    const loginType = prevState?.signIn?.submitOTP.isUserPersistent
      ? "re-authentication"
      : "full login";

    return {
      event_name: "login",
      login_method: loginMethod,
      login_state: "fully authenticated",
      enable_persistent_login: enablePersistentLogin,
      login_type: loginType,
      golden_id: getGoldenIdString() || "na",
    };
  }),

  [viewRequestInvoiceFormEvt.type]: trackView(() => ({
    form_name: "Invoice request from profile",
    event_name: "form_view",
  })),

  [requestInvoiceFulfilled.type]: trackView(() => ({
    form_name: "Invoice request from profile",
    event_name: "form_submit",
  })),

  [setIsOpen.type]: trackEvent(({ payload }) => ({
    form_name: "Add reservation to profile",
    event_name: "form_view",
    proceed: !!payload,
  })),

  [setIsSuccess.type]: trackEvent(({ payload }, prevState, nextState) => ({
    form_name: "Add reservation to profile",
    event_name: "form_submit,link_reservation",
    ...getProfileData(nextState),
  })),

  [updateUserEmailFulfilled.type]: trackEvent(
    ({ payload }, prevState, nextState) => ({
      event_name: "form_submit,profile_update",
      form_name: "update email address",
      profile_update_type: "update email",
      ...getProfileData(nextState),
    })
  ),

  [deleteUserEmailFulfilled.type]: trackEvent(
    ({ payload }, prevState, nextState) => ({
      event_name: "form_submit,profile_update",
      form_name: "delete email address",
      profile_update_type: "delete email",
      ...getProfileData(nextState),
    })
  ),

  [updateEmailSubscriptions.type]: trackEvent(
    ({ payload: { newValue } }, prevState, nextState) => ({
        event_name: "profile_update",
        profile_update_type: "update email subscriptions",
        ...getProfileData(nextState, newValue),
      })
  ),

  [updateUserPhone.type]: trackEvent(({ payload }, prevState, nextState) => ({
    event_name: "profile_update",
    profile_update_type: "update phone number",
    ...getProfileData(nextState),
  })),

  [deleteUserPhone.type]: trackEvent(({ payload }, prevState, nextState) => ({
    event_name: "profile_update",
    profile_update_type: "delete phone number",
    ...getProfileData(nextState),
  })),

  [deleteUserAddress.type]: trackEvent(({ payload }, prevState, nextState) => ({
    event_name: "profile_update",
    profile_update_type: "delete address",
    ...getProfileData(nextState),
  })),

  [updateUserAddress.type]: trackEvent(({ payload }, prevState, nextState) => ({
    event_name: "profile_update",
    profile_update_type: "update address",
    ...getProfileData(nextState),
  })),

  [updateCountryAndLanguage.type]: trackEvent(
    ({ payload }, prevState, nextState) => ({
      event_name: "profile_update",
      profile_update_type: "update country & language",
      ...getProfileData(nextState),
    })
  ),

  [toggleInterestFulfilled.type]: trackEvent(
    ({ payload }, prevState, nextState) => ({
      event_name: "profile_update",
      profile_update_type: "update interests",
      ...getProfileData(nextState),
    })
  ),

  [updateSleepPreferencesFulfilled.type]: trackEvent(
    ({ payload }, prevState, nextState) => ({
      event_name: "profile_update",
      profile_update_type: "update sleep preferences",
      ...getProfileData(nextState),
    })
  ),

  [updateRoomPreference.type]: trackEvent(
    ({ payload }, prevState, nextState) => ({
      event_name: "profile_update",
      profile_update_type: "update room preference",
      ...getProfileData(nextState),
    })
  ),

  [updateProfileComments.type]: trackEvent(
    ({ payload: { amenities, welcomeAmenitiesChanged, transferChanged } }) => {
      if (transferChanged) {
        return {
          proceed: !!transferChanged,
          event_name: "personalize_stay,personalize_transportation",
          personalize_type: "transportation",
        };
      }
      if (welcomeAmenitiesChanged) {
        return {
          proceed: !!welcomeAmenitiesChanged,
          event_name: "personalize_stay,personalize_amenities",
          room_amenities_array: amenities?.[0],
          personalize_type: "amenities",
        };
      }

      return {};
    }
  ),

  [updateChatStatus.type]: trackEvent(({ payload }, prevState, nextState) => {
    const webChatPrompted =
      prevState.appStatus.chatStatus === "hidden" &&
      nextState.appStatus.chatStatus === "open";

    return {
      proceed: !!webChatPrompted,
      event_name: payload?.eventName || "chat_invite",
      interaction_name: payload?.eventName || "chat_invite",
    };
  }),

  [webChatAccepted.type]: trackEvent(({ payload }, prevState, nextState) => ({
      proceed: true,
      event_name: "chat_accepted",
      interaction_name: "chat_accepted",
    })),

  [leadWithCareLinkClicked.type]: trackEvent(
    ({ payload }, prevState, nextState) => ({
        proceed: true,
        event_name: "interaction",
        interaction_type: "lead with care",
        interaction_name: payload?.linkName,
      })
  ),

  [leadWithCareTravelAdvisoryButtonClicked.type]: trackEvent(
    ({ payload }, prevState, nextState) => ({
        proceed: true,
        event_name: "interaction",
        interaction_type: "booking_engine",
        interaction_name: "travel advisory",
      })
  ),

  [leadWithCareCheckHotelStatusLinkClicked.type]: trackEvent(
    ({ payload }, prevState, nextState) => ({
        proceed: true,
        event_name: "interaction",
        interaction_type: "booking_engine",
        interaction_name: "hotel check status",
      })
  ),

  [itineraryActivityDetailsModal.type]: trackEvent(({ payload }) => ({
      proceed: true,
      event_name: "interaction",
      interaction_type: "itinerary",
      interaction_name: "experience modal open",
      property_code: payload?.hotelCode,
    })),

  [openTransportationRequestForm.type]: trackEvent(({ payload }) => {
    const booking = payload?.booking;

    return {
      proceed: true,
      event_name: "form_view",
      form_name: "Itinerary: Transportation Request",
      upcoming_stay: getUpcomingStayValue(booking),
    };
  }),

  [addRequestEventFulfilled.type]: trackEvent(({ payload = {} }) => {
    const { booking } = payload;
    const { eventProducts } = booking;

    const order_method = getSourceName(booking);

    return flow(
      flatMap((eventProduct) => {
        const isTransportation =
          eventProduct.onRequestEventProductType === "Transportation";

        const enhancement_name = eventProduct.name;
        const enhancement_type = eventProduct.merchantGroupType;
        const enhancement_category = eventProduct.merchantGroupCategory;
        const enhancement_subcategory = eventProduct.merchantGroupSubCategory;

        const form_name = isTransportation
          ? `${order_method}: Transportation Request`
          : `${order_method}: Amenities Request`;

        return flow(
          getOr([], ["price", "ticketBreakdown", "ticketTypes"]),
          map((ticketType) => {
            const useUsdPrice = Boolean(ticketType?.usdAmount);

            const isComplimentary =
              eventProduct?.complimentaryPricingInfo ||
              ticketType?.amount === 0;

            const enhancement_pricing_type = isComplimentary
              ? "Complimentary"
              : "Paid";

            return {
              enhancement_name,
              enhancement_type,
              enhancement_category,
              enhancement_subcategory,
              enhancement_details: "",
              enhancement_pricing_type,
              enhancement_quantity: ticketType.ticketCount,
              enhancement_price: (useUsdPrice
                ? ticketType.usdAmount
                : ticketType.amount
              ).toString(),
              booking_currency: useUsdPrice
                ? ticketType.usdCurrencyCode
                : ticketType.currencyCode,
              form_name,
            };
          })
        )(eventProduct);
      }),
      reduce(
        (acc, curr) => ({
            ...acc,
            proceed: true,
            enhancement_name: [...acc.enhancement_name, curr.enhancement_name],
            enhancement_type: [...acc.enhancement_type, curr.enhancement_type],
            enhancement_category: [
              ...acc.enhancement_category,
              curr.enhancement_category,
            ],
            enhancement_subcategory: [
              ...acc.enhancement_subcategory,
              curr.enhancement_subcategory,
            ],
            enhancement_details: [
              ...acc.enhancement_details,
              curr.enhancement_details,
            ],
            enhancement_pricing_type: [
              ...acc.enhancement_pricing_type,
              curr.enhancement_pricing_type,
            ],
            enhancement_quantity: [
              ...acc.enhancement_quantity,
              curr.enhancement_quantity,
            ],
            enhancement_price: [
              ...acc.enhancement_price,
              curr.enhancement_price,
            ],
            booking_currency: curr.booking_currency,
            form_name: curr.form_name,
          }),
        {
          proceed: false,
          event_name: "enhancement_order,form_submit",
          order_method,
          upcoming_stay: getUpcomingStayValue(booking),
          enhancement_name: [],
          enhancement_type: [],
          enhancement_category: [],
          enhancement_subcategory: [],
          enhancement_details: [],
          enhancement_pricing_type: [],
          enhancement_quantity: [],
          enhancement_price: [],
          booking_currency: "",
        }
      )
    )(eventProducts);
  }),

  [buildYourItineraryAction.type]: trackEvent(({ payload }) => ({
      proceed: true,
      event_name: "interaction",
      interaction_type: "itinerary",
      interaction_name: "build your itinerary",
      interaction_detail: payload.kind,
    })),

  [requestCtaClicked.type]: trackEvent(({ payload }) => ({
      proceed: true,
      event_name: "interaction",
      interaction_type: "upcoming trip",
      interaction_name: "request cta",
      interaction_detail: payload.kind,
    })),

  [welcomeAmentitiesAdded.type]: trackEvent(({ payload }) => {
    const { booking, amenityToAdd, amenityItem } = payload;

    const order_method = getSourceName(booking);

    return {
      proceed: true,
      event_name: "add_to_cart",
      order_method,
      enhancement_name: [amenityItem.name],
      enhancement_type: [amenityItem?.taxonomy?.type],
      enhancement_category: [amenityItem?.taxonomy?.category],
      enhancement_subcategory: [amenityItem?.taxonomy?.subCategory],
      enhancement_details: [""],
      enhancement_pricing_type: ["Paid"],
      enhancement_quantity: [amenityToAdd.numQuantity],
      enhancement_price: [amenityItem.priceInCurrency.amount.toString()],
      booking_currency: amenityItem.priceInCurrency.currencyCode,
      upcoming_stay: getUpcomingStayValue(booking),
    };
  }),

  [welcomeAmentitiesRemoved.type]: trackEvent(({ payload }) => {
    const { booking, amenityItem } = payload;

    return {
      proceed: true,
      event_name: "remove_from_cart",
      order_method: getSourceName(booking),
      enhancement_name: [amenityItem.name],
      enhancement_type: [amenityItem?.taxonomy?.type],
      enhancement_category: [amenityItem?.taxonomy?.category],
      enhancement_subcategory: [amenityItem?.taxonomy?.subCategory],
      enhancement_details: [""],
      enhancement_pricing_type: ["Paid"],
      enhancement_quantity: [0],
      enhancement_price: [amenityItem.priceInCurrency.amount.toString()],
      booking_currency: amenityItem.priceInCurrency.currencyCode,
    };
  }),

  [contextualLinkClick.type]: trackEvent(({ payload }) => {
    const { booking } = payload;
    return {
      proceed: true,
      event_name: "interaction",
      interaction_type: "itinerary",
      interaction_name: "contextual links",
      interaction_detail: payload.kind,
      upcoming_stay: getUpcomingStayValue(booking),
    };
  }),
};

export default createMiddleware(eventsMap, tealiumTarget());
