import { API_REQUEST_STATES } from "store/apiRequestStates/constants";
import { fetchWorkplaces } from "./workplaces.slice";

export const selectHasWorkplaces = (state) => state.workplaces.data.length > 0;

export const selectWorkplaces = (state) => state.workplaces.data;

export const selectIsLoadingWorkplaces = ({ apiRequestStates }) => {
  const apiRequestState = apiRequestStates[fetchWorkplaces.type];
  return (
    (apiRequestState && apiRequestState === API_REQUEST_STATES.REQUEST) || false
  );
};

export const selectHasRequestedWorkplaces = ({ apiRequestStates }) => {
  const apiRequestState = apiRequestStates[fetchWorkplaces.type];
  return (
    (apiRequestState && apiRequestState === API_REQUEST_STATES.FULFILLED) ||
    false
  );
};
