import get from "lodash/get";
import {
  add,
  isAfter,
  isBefore,
  isSameDay,
  max,
  min,
  parse,
  differenceInDays,
  isSameYear,
} from "date-fns";

import { EMPLOYEE_SEARCH_ERRORS } from "store/searchResults/checkEmployeeSearchParams";
import { getEmployeeRateByCode } from "Profile/utils/getEmployeeRate";

function sortStays(stays) {
  return stays.sort((a, b) => {
    if (isBefore(a.start, b.start)) {
      return -1;
    }
    if (isBefore(b.start, a.start)) {
      return 1;
    }
    if (isBefore(a.end, b.end)) {
      return -1;
    }
    return 1;
  });
}

function combineStays({ hotelCode, searchStay, stayHistory = [] }) {
  const propertyStays = stayHistory.reduce((stays, booking) => {
    if (
      !booking.startDate ||
      !booking.endDate ||
      get(booking, ["hotelProducts", 0, "fsCachedExistingReservation"]) ||
      hotelCode !== get(booking, ["hotelProducts", 0, "hotelCode"])
    ) {
      return stays;
    }
    const employeeRate = getEmployeeRateByCode(
      get(booking, ["hotelProducts", 0, "rooms", "room", 0, "ratePlanCode"], "")
    );
    if (!employeeRate) {
      return stays;
    }
    const bookingStart = parse(booking.startDate, "yyyy-MM-dd", new Date());
    let bookingEnd = parse(booking.endDate, "yyyy-MM-dd", new Date());
    if (isSameDay(bookingStart, bookingEnd)) {
      bookingEnd = add(bookingStart, { days: 1 });
    }

    return [
      ...stays,
      {
        start: bookingStart,
        end: bookingEnd,
        eceNights: employeeRate.compRate
          ? 0
          : differenceInDays(bookingEnd, bookingStart),
        cmpNights: employeeRate.compRate
          ? differenceInDays(bookingEnd, bookingStart)
          : 0,
        sameYear: isSameYear(searchStay[0].start, bookingStart),
        currentStay: false,
      },
    ];
  }, searchStay);

  let combined = true;
  let stayIndex = 0;
  let possbileStays = [propertyStays];
  while (combined) {
    const combinedStays = [];
    let didCombineAllStays = false;
    let hasMoreStaysToCheck = false;
    for (let ps = 0; ps < possbileStays.length; ps += 1) {
      const sortedStays = sortStays([...possbileStays[ps]]);
      if (sortedStays.length > stayIndex + 1) {
        hasMoreStaysToCheck = true;
        let didCombineThisStay = false;
        const sameEndDateIndexes = [stayIndex];
        for (let s = stayIndex + 1; s < sortedStays.length; s += 1) {
          if (isSameDay(sortedStays[stayIndex].end, sortedStays[s].end)) {
            sameEndDateIndexes.push(s);
          }
        }
        for (let s = stayIndex + 1; s < sortedStays.length; s += 1) {
          if (isSameDay(sortedStays[stayIndex].end, sortedStays[s].start)) {
            didCombineThisStay = true;
            sameEndDateIndexes.forEach((endDateIndex) => {
              combinedStays.push([
                {
                  start: sortedStays[endDateIndex].start,
                  end: sortedStays[s].end,
                  eceNights:
                    sortedStays[endDateIndex].eceNights +
                    sortedStays[s].eceNights,
                  cmpNights:
                    sortedStays[endDateIndex].cmpNights +
                    sortedStays[s].cmpNights,
                  sameYear:
                    sortedStays[endDateIndex].sameYear ||
                    sortedStays[s].sameYear,
                  currentStay:
                    sortedStays[endDateIndex].currentStay ||
                    sortedStays[s].currentStay,
                },
                ...sortedStays.filter((_value, index) => index !== endDateIndex && index !== s),
              ]);
            });
          }
        }
        if (didCombineThisStay) {
          didCombineAllStays = true;
        } else {
          combinedStays.push([...sortedStays]);
        }
      } else {
        combinedStays.push([...sortedStays]);
      }
      //
    }
    possbileStays = [...combinedStays];
    if (!didCombineAllStays) {
      stayIndex += 1;
      if (!hasMoreStaysToCheck) {
        combined = false;
      }
    }
  }
  return possbileStays;
}

function checkEmployeePropertyLimits({
  searchParams = {},
  stayHistory = [],
  globalSettings = {},
} = {}) {
  if (
    !get(searchParams, ["dates", "checkIn"]) ||
    !get(searchParams, ["dates", "checkOut"]) ||
    !searchParams.hotelCode ||
    !searchParams.promoCode ||
    !Array.isArray(stayHistory) ||
    !stayHistory.length ||
    (!globalSettings.employeeMaxConsecutiveRoomNightsSameHotel &&
      !globalSettings.employeeMaxStaysPerYearSameHotel)
  ) {
    return false;
  }

  const employeeRate = getEmployeeRateByCode(searchParams.promoCode);
  if (!employeeRate) {
    return false;
  }

  const checkInDate = parse(
    searchParams.dates.checkIn,
    "yyyy-MM-dd",
    new Date()
  );
  const checkOutDate = parse(
    searchParams.dates.checkOut,
    "yyyy-MM-dd",
    new Date()
  );

  const allCombinedStays = combineStays({
    hotelCode: searchParams.hotelCode,
    searchStay: [
      {
        start: checkInDate,
        end: checkOutDate,
        eceNights: employeeRate.compRate
          ? 0
          : differenceInDays(checkOutDate, checkInDate),
        cmpNights: employeeRate.compRate
          ? differenceInDays(checkOutDate, checkInDate)
          : 0,
        sameYear: true,
        currentStay: true,
      },
    ],
    stayHistory,
  });

  const isValidStay = allCombinedStays.some((combinedStays) => {
    const stays = combinedStays.reduce((accStays, stay) => {
      const sameStay = accStays.find(
        (findStay) =>
          isAfter(stay.end, findStay.start) &&
          isBefore(stay.start, findStay.end)
      );

      if (!sameStay) {
        return [
          ...accStays,
          {
            ...stay,
            count: 1,
          },
        ];
      }

      sameStay.start = min([sameStay.start, stay.start]);
      sameStay.end = max([sameStay.end, stay.end]);
      sameStay.eceNights += stay.eceNights;
      sameStay.cmpNights += stay.cmpNights;
      sameStay.sameYear = sameStay.sameYear || stay.sameYear;
      sameStay.currentStay = sameStay.currentStay || stay.currentStay;
      sameStay.count += 1;

      return accStays;
    }, []);

    if (
      globalSettings.employeeMaxStaysPerYearSameHotel &&
      stays.filter((stay) => stay.sameYear).length >
        globalSettings.employeeMaxStaysPerYearSameHotel
    ) {
      return false;
    }

    if (
      globalSettings.employeeMaxConsecutiveRoomNightsSameHotel &&
      stays.find(
        (stay) =>
          stay.currentStay &&
          stay.eceNights + stay.cmpNights >
            globalSettings.employeeMaxConsecutiveRoomNightsSameHotel
      )
    ) {
      return false;
    }

    if (
      globalSettings.employeeMaxComplimentaryRoomNightsSingleStay &&
      stays.find(
        (stay) =>
          stay.currentStay &&
          stay.cmpNights >
            globalSettings.employeeMaxComplimentaryRoomNightsSingleStay
      )
    ) {
      return false;
    }

    return true;
  }, false);

  return isValidStay
    ? false
    : {
        errorCode: EMPLOYEE_SEARCH_ERRORS.BOOKING_EXCEEDS_POLICY_LIMITATIONS,
      };
}

export default checkEmployeePropertyLimits;
