import React from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { Preferences, ToggleBlock } from "Profile/components";
import { SignInModal } from "Authentication";
import TripReasonForm from "./TripReasonForm";

export default function UpcomingTripPreferences({
  allowCancellation,
  defaultValues,
  onSubmit,
  showPreferences = false,
  showSavingSpinner,
  isUnauthenticated,
}) {
  const { t } = useTranslation();

  return (
    <div className="fs-trip__preferences">
      <h3>{t("Perfection in every detail")}</h3>
      <p>
        {t(
          "Knowing a little more will allow us to add just the right touches to your stay."
        )}
      </p>
      <div className="fs-your-preferences__preference fs-your-preferences__preference--reason">
        <ToggleBlock
          type="edit"
          label={({ isOpen, isOpening, toggle }) => (
            <div className="fs-toggle-block__label">
              <div className="fs-your-preferences__preference__title">
                <div className="icon icon--dark" />
                <h3>{t("What is the reason for your visit?")}</h3>
              </div>
              {allowCancellation && (
                isUnauthenticated ? (
                  <button
                    className={classNames(
                      "fs-toggle-button--light fs-toggle-button-light--edit",
                      {
                        "fs-toggle-button--light-active": isOpen || isOpening,
                      }
                    )}
                    onClick={toggle}
                    type="button"
                    aria-label={t("Edit your purpose for trip")}
                  >
                    {defaultValues?.tripPurpose ? t("Edit") : t("Add")}
                  </button>
                ) : (
                  <SignInModal
                    id="editPurpose"
                    onClick={toggle}
                    className={classNames(
                      "fs-toggle-button--light fs-toggle-button-light--edit",
                      {
                        "fs-toggle-button--light-active": isOpen || isOpening,
                      }
                    )}
                    type="CustomButton"
                    buttonLabel={
                      defaultValues?.tripPurpose ? t("Edit") : t("Add")
                    }
                    ariaLabel={t("Edit your purpose for trip")}
                  />
                )
              )}
            </div>
          )}
        >
          {allowCancellation &&
            (({ close }) => (
              <TripReasonForm
                close={close}
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                showSavingSpinner={showSavingSpinner}
              />
            ))}
        </ToggleBlock>
      </div>

      {defaultValues.tripPurpose && (
        <ul className="fs-your-preferences__preference__selected-preferences">
          <li>{defaultValues.tripPurpose}</li>
        </ul>
      )}

      {showPreferences && <Preferences canEdit={allowCancellation} />}

      {!allowCancellation && (
        <div className="fs-trip__no-changes">
          {t(
            "Trip details cannot be modified online within 48 hours of check-in date. Please call 1-800-819-5053 for assistance."
          )}
        </div>
      )}
    </div>
  );
}
