import filter from "lodash/fp/filter";
import flatMap from "lodash/fp/flatMap";
import flow from "lodash/fp/flow";
import getOr from "lodash/fp/getOr";
import has from "lodash/fp/has";
import reduce from "lodash/fp/reduce";

export const selectPropertyAccommodationsContent =
  (propertyCode) =>
  ({ accommodationsContent }) =>
    accommodationsContent?.data[propertyCode];

export const selectAllPropertyAccommodationsContent = ({
  accommodationsContent,
}) => accommodationsContent?.data;

export const selectAllPropertyAccommodationsContentErrors = ({
  accommodationsContent,
}) => accommodationsContent?.error;

export const selectAccommodationByRoomTypeIdMap = (hotelCode) =>
  flow(
    getOr(
      [],
      ["accommodationsContent", "data", hotelCode, "bookableAccommodations"]
    ),
    flatMap(getOr([], "sleepingArrangements")),
    filter(has("owsCode")),
    reduce(
      (acc, sleepingArrangement) => ({
        ...acc,
        [sleepingArrangement.owsCode]: sleepingArrangement,
        [sleepingArrangement.orsCode]: sleepingArrangement,
      }),
      {}
    )
  );

export const selectAccommodationByRoomTypeId =
  (hotelCode) => (roomTypeId) => (state) => selectAccommodationByRoomTypeIdMap(hotelCode)(state)[roomTypeId];
