import {
  updateUserEmail,
  updateUserEmailFulfilled,
} from "store/profile/profile.slice";
import { selectMailSubscriptionList } from "store/profile/profile.selectors";
import {
  prepareEmails,
  makeLoginEmail,
  makeUpdateSubscriptions,
} from "../profile.helpers";
import { updateProfileTemplate } from "./updateProfile";

export default updateProfileTemplate({
  actionType: updateUserEmail.type,
  fulfillAction: updateUserEmailFulfilled,
  updateFn: ({ emails = [], extension = {} } = {}, newValue, state) => ({
      emails: prepareEmails(emails, newValue),
      extension: {
        ...makeUpdateSubscriptions({
          extension: makeLoginEmail({ extension, emails, email: newValue }),
          mailSubscriptions: selectMailSubscriptionList(state),
          updateSubscriptions: newValue.updateSubscriptions,
        }),
      },
    }),
});
