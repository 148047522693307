import { ofType } from "redux-observable";
import { of, forkJoin } from "rxjs";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { getBookingById$ } from "api/tretail/booking";
import { retrieveBooking$ } from "api/tretail/bookingHistory";
import catchInternalServerError from "store/catchInternalServerError";
import { retrieveAllHotelProductPostBookingComments$ } from "api/tretail/bookingProductCust";
import get from "lodash/get";
import set from "lodash/set";
import find from "lodash/find";
import {
  fetchBooking,
  fetchBookingFailed,
  fetchBookingFulfilled,
  fetchBookingCancel,
  webCheckInErrorEvt,
} from "../webCheckIn.slice";

export function fetchBooking$({ reservationId, hotelCode, surname, locale }) {
  return retrieveBooking$({
    reservationId,
    hotelCode,
    surname,
    locale,
  }).pipe(
    switchMap(({ bookingId }) => {
      const parts = {
        booking: getBookingById$({
          bookingId,
          locale,
        }),
        comments: retrieveAllHotelProductPostBookingComments$({
          bookingId,
          locale,
        }),
      };

      return forkJoin(parts).pipe(
        map(({ booking = {}, comments = {} }) => {
          const childrenCount = get(
            booking,
            ["hotelProducts", 0, "guestCount", "numberChildren"],
            ""
          );

          if (childrenCount === 0) {
            const productId = get(
              booking,
              ["hotelProducts", 0, "productId"],
              ""
            );
            const comment = find(comments.comments, ["productId", productId]);

            const numberChildren = get(comment, ["roomGuests"], []).reduce(
              (acc, { type }) => (type === 2 ? acc + 1 : acc),
              0
            );
            set(
              booking,
              ["hotelProducts", 0, "guestCount", "numberChildren"],
              numberChildren
            );
          }

          return booking;
        }),

        catchInternalServerError()
      );
    })
  );
}

export default function fetchBookingEpic(action$) {
  return action$.pipe(
    ofType(fetchBooking.type),
    switchMap(({ payload: { reservationId, hotelCode, surname, locale } }) => ajaxWithHealthCheck$({
        locale,
      }).pipe(
        switchMap(() => fetchBooking$({
            reservationId,
            hotelCode,
            surname,
            locale,
          }).pipe(
            map(fetchBookingFulfilled),

            catchError(({ response }) =>
              of(
                fetchBookingFailed({
                  apiErrors: response?.apiErrors || [],
                  supplierErrors: response?.supplierErrors || [],
                }),
                webCheckInErrorEvt({
                  hotelCode,
                  error: "invalid request",
                })
              )
            ),

            takeUntil(action$.pipe(ofType(fetchBookingCancel.type)))
          ))
      ))
  );
}
