import React, { useState } from "react";

import { useTranslation } from "hooks";
import priceFormatter from "utils/formatPrice";
import { Button } from "Profile/components";
import formatDailyRateDate from "utils/formatDailyRateDate";

export default function BookingSummary({
  highlights,
  rates: {
    currency = "USD",
    feesDisclaimerCheckout,
    nightlyAverage,
    nightlyRates,
    total,
    base,
    taxes,
  },
  stayResortFees = [],
  estimatedTotalDisclaimer,
}) {
  const { t, locale } = useTranslation();

  const [showDailyRates, setShowDailyRates] = useState(false);
  const formatPrice = (price) => priceFormatter({ currency, value: price });

  return (
    <dl className="booking-summary">
      <dt>{t("Room Type")}</dt>
      <dd>{t(highlights.get("Room Type"))}</dd>
      <dt>{t("Offer")}</dt>
      <dd>{t(highlights.get("Offer Name"))}</dd>

      <dt className="booking-summary__average-daily-rate-label">
        {t("Average Daily Rate")}
      </dt>
      <dd>
        {formatPrice(nightlyAverage)}
        {feesDisclaimerCheckout && (
          <div className="booking-summary__fees-disclaimer">
            {feesDisclaimerCheckout}
          </div>
        )}
        <div className="booking-summary__toggle-daily-rates">
          <Button
            kind="link"
            onClick={() => setShowDailyRates(!showDailyRates)}
          >
            {showDailyRates ? t("Hide Daily Rates") : t("Show Daily Rates")}
          </Button>
        </div>
        {showDailyRates && (
          <dl className="daily-rates">
            {nightlyRates.map(({ date, amount, resortFees }) => {
              const formattedDate = formatDailyRateDate({
                date,
                locale,
              });

              return (
                <React.Fragment key={formattedDate}>
                  <dt>{formattedDate}</dt>
                  <dd>{formatPrice(amount)}</dd>
                  {resortFees &&
                    resortFees.map(({ code, description, cash }) => (
                        <React.Fragment key={code}>
                          <dt>{`+ ${description}`}</dt>
                          <dd>{formatPrice(cash.amount)}</dd>
                        </React.Fragment>
                      ))}
                </React.Fragment>
              );
            })}
            {stayResortFees.map(({ description, cash }) => (
                <React.Fragment key={description}>
                  <dt>{description}</dt>
                  <dd>{formatPrice(cash.amount)}</dd>
                </React.Fragment>
              ))}
          </dl>
        )}
      </dd>

      <dt>{t("Total Room Rate")}</dt>
      <dd>{formatPrice(base)}</dd>
      {taxes.map(({ description, amount }, i) => (
        <React.Fragment key={i}>
          <dt>{t(description)}</dt>
          <dd>{formatPrice(amount)}</dd>
        </React.Fragment>
      ))}
      <dt>
        <strong>{t("Estimated Room Total")}</strong>
        {!!estimatedTotalDisclaimer && (
          <strong>
            <sup>*</sup>
          </strong>
        )}
      </dt>
      <dd className="booking-summary__total">
        <strong>{formatPrice(total)}</strong>
      </dd>
    </dl>
  );
}
