import React from "react";
import classNames from "classnames";
import { Controller } from "react-hook-form";

import { useTranslation } from "hooks";
import {
  FormRow,
  FormRowPhoneNumber,
  PersistentReadOnlyRow,
} from "BookingFlow/components";
import { useSelector } from "react-redux";
import { selectIsUserPersistent } from "store/profile";

export default function TravelAgentDetails({
  register,
  control,
  errors = {},
  travelAgentDetails,
  travelAgentId,
  readOnlyFields = [],
  isPreferredPartners,
}) {
  const { t } = useTranslation();
  const isUserPersistent = useSelector(selectIsUserPersistent);
  const showReadOnlyRow = isUserPersistent && !isPreferredPartners;

  return (
    <div>
      <fieldset
        name="travelAgentDetails"
        className={classNames({
          "has-errors": Object.keys(errors).length > 0,
        })}
      >
        <legend>{t(travelAgentDetails.key, travelAgentDetails.params)}</legend>
        {showReadOnlyRow && readOnlyFields.includes("name") ? (
          <PersistentReadOnlyRow
            name="travelAgentDetails.name"
            className="agent-name-row"
            label={`${t("Name")}*`}
            ariaDescribedBy="ttAgentName"
            value={control?.defaultValuesRef?.current?.travelAgentDetails?.name}
          />
        ) : (
          <FormRow
            name="travelAgentDetails.name"
            className="agent-name-row"
            label={`${t("Name")}*`}
            errorMessage={errors.name && t("Please enter your agent name.")}
            ariaDescribedBy="ttAgentName"
            register={register()}
            readOnly={readOnlyFields.includes("name")}
          />
        )}

        {showReadOnlyRow && readOnlyFields.includes("travelAgencyName") ? (
          <PersistentReadOnlyRow
            name="travelAgentDetails.travelAgencyName"
            className="agency-name-row"
            label={`${t("Travel Agency Name")}`}
            ariaDescribedBy="ttAgencyName"
            value={
              control?.defaultValuesRef?.current?.travelAgentDetails
                ?.travelAgencyName
            }
          />
        ) : (
          <FormRow
            name="travelAgentDetails.travelAgencyName"
            className="agency-name-row"
            label={t("Travel Agency Name")}
            errorMessage={
              errors.travelAgencyName &&
              t("Please enter a valid Travel Agency name.")
            }
            ariaDescribedBy="ttAgencyName"
            register={register()}
            readOnly={readOnlyFields.includes("travelAgencyName")}
          />
        )}

        {showReadOnlyRow && readOnlyFields.includes("travelAgentId") ? (
          <PersistentReadOnlyRow
            name="travelAgentDetails.travelAgentId"
            className="agent-id-row"
            label={`${t(travelAgentId.key, travelAgentId.params)}*`}
            ariaDescribedBy="ttAgentID1"
            value={
              control?.defaultValuesRef?.current?.travelAgentDetails
                ?.travelAgentId
            }
          />
        ) : (
          <FormRow
            name="travelAgentDetails.travelAgentId"
            className="agent-id-row"
            label={`${t(travelAgentId.key, travelAgentId.params)}*`}
            errorMessage={
              errors.travelAgentId && t("Please enter a valid Traveller ID.")
            }
            ariaDescribedBy="ttAgentID1"
            register={register()}
            readOnly={readOnlyFields.includes("travelAgentId")}
          />
        )}

        {isPreferredPartners ? (
          <FormRow
            name="travelAgentDetails.fsAdvisorId"
            className="fs-advisor-id-row"
            label={`${t("Four Seasons Advisor ID")}*`}
            ariaDescribedBy="fsAdvisorId"
            register={register({ required: true })}
            readOnly={readOnlyFields.includes("fsAdvisorId")}
          />
        ) : (
          showReadOnlyRow &&
          readOnlyFields.includes("travelAgentIdConfirmation") ? (
            <PersistentReadOnlyRow
              name="travelAgentDetails.travelAgentIdConfirmation"
              className="agent-id-confirmation-row"
              label={`${t("Confirm travel agent ID")}*`}
              ariaDescribedBy="ttAgentID2"
              value={
                control?.defaultValuesRef?.current?.travelAgentDetails
                  ?.travelAgentIdConfirmation
              }
            />
          ) : (
            <FormRow
              name="travelAgentDetails.travelAgentIdConfirmation"
              className="agent-id-confirmation-row"
              label={`${t("Confirm travel agent ID")}*`}
              errorMessage={
                errors.travelAgentIdConfirmation &&
                t("The Agent ID's do not match.")
              }
              ariaDescribedBy="ttAgentID2"
              register={register()}
              readOnly={readOnlyFields.includes("travelAgentIdConfirmation")}
            />
          )
        )}

        {showReadOnlyRow && readOnlyFields.includes("agentEmail") ? (
          <PersistentReadOnlyRow
            name="travelAgentDetails.email"
            className="email-row"
            label={`${t("Email Address")}*`}
            ariaDescribedBy="ttGuestEmail1"
            value={
              control?.defaultValuesRef?.current?.travelAgentDetails?.agentEmail
            }
          />
        ) : (
          <FormRow
            name="travelAgentDetails.agentEmail"
            className="email-row"
            label={`${t("Email Address")}*`}
            errorMessage={
              errors.agentEmail && t("Please enter a email address.")
            }
            ariaDescribedBy="ttGuestEmail1"
            type="email"
            register={register()}
            readOnly={readOnlyFields.includes("agentEmail")}
            popupMessage={
              isPreferredPartners && readOnlyFields.includes("agentEmail")
                ? `${t(
                    "Contact your account director to update your details."
                  )}`
                : undefined
            }
          />
        )}

        {isPreferredPartners ? (
          <FormRow
            name="travelAgentDetails.phoneNumber"
            className="telephone-row"
            label={`${t("Telephone")}*`}
            errorMessage={
              errors.phoneNumber && t("Please enter a telephone number.")
            }
            ariaDescribedBy="ttAgentPhone2"
            type="tel"
            register={register()}
            readOnly={readOnlyFields.includes("phoneNumber")}
          />
        ) : (
          showReadOnlyRow && readOnlyFields.includes("phoneNumber") ? (
            <PersistentReadOnlyRow
              name="travelAgentDetails.phoneNumber"
              label={`${t("Mobile Phone Number")}*`}
              ariaDescribedBy="ttGuestPhone2"
              value={
                control?.defaultValuesRef?.current?.travelAgentDetails
                  ?.phoneNumber
              }
            />
          ) : (
            <Controller
              name="travelAgentDetails.phoneNumber"
              control={control}
              rules={{ required: true }}
              render={({ ref: _ignoredRef, ...props }) => (
                <FormRowPhoneNumber
                  label="Telephone"
                  errorMessage={
                    errors.phoneNumber &&
                    t("Please enter a mobile phone number.")
                  }
                  readOnly={readOnlyFields.includes("phoneNumber")}
                  {...props}
                />
              )}
            />
          )
        )}
      </fieldset>
    </div>
  );
}
