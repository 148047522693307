import get from "lodash/fp/get";
import filter from "lodash/fp/filter";
import flow from "lodash/fp/flow";
import map from "lodash/fp/map";
import update from "lodash/fp/update";
import set from "lodash/fp/set";

import { INPUT_TYPES } from "Profile/components/TransportationAmenities/FieldSelector";

const processField = (booking) => (field) => {
  if (field.type === INPUT_TYPES.DATE && field.name.endsWith("Arrival_Date")) {
    const checkInDate = get(["hotelProducts", 0, "checkInDate"])(booking);
    return {
      ...field,
      value: checkInDate,
      min: checkInDate,
    };
  }

  if (field.type === INPUT_TYPES.DATE) {
    return {
      ...field,
      min: new Date().toISOString().split("T")[0],
    };
  }

  if (
    field.type === INPUT_TYPES.NUMBER &&
    field.name.endsWith("Number_of_Items")
  ) {
    return {
      ...field,
      min: 0,
      step: 1,
    };
  }

  if (field.type === INPUT_TYPES.TEXTAREA) {
    return {
      ...field,
      label: null,
      placeholder: field.label,
    };
  }

  if (
    field.type === INPUT_TYPES.NUMBER &&
    (field?.name?.endsWith("Quantity") || field?.name?.includes("Number_of"))
  ) {
    return {
      ...field,
      type: INPUT_TYPES.QUANTITY,
      min: 0,
      step: 1,
    };
  }

  return field;
};

const processTravelArrangementsFields = (booking) =>
  map(
    update(
      "products",
      map(
        update(
          "prices",
          map(
            update(
              "fieldSets",
              map(update("fields", map(processField(booking))))
            )
          )
        )
      )
    )
  );

const filterProducts = flow(
  map(
    update(
      ["products"],
      filter(({ sku, prices = [] }) => Boolean(sku) && prices.length > 0)
    )
  ),
  filter(({ products }) => products.length > 0)
);

// eslint-disable-next-line no-unused-vars
const addPriceInCurrency = ({ currencyCode }) =>
  map(
    update(
      ["products"],
      map((product) => set("priceInCurrency", product.prices[0])(product))
    )
  );

export function selectTravelArrangements({ reservationId, currencyCode }) {
  return (state) => {
    const booking = get(["bookings", "byReservationId", reservationId])(state);

    return flow(
      get([
        "transportationAmenitiesContent",
        "byReservationId",
        reservationId,
        "productCategories",
        "travelArrangements",
      ]),
      (travelArrangements) => {
        if (!travelArrangements) {
          return travelArrangements;
        }

        return update(
          ["categories"],
          flow(
            filterProducts,
            processTravelArrangementsFields(booking),
            addPriceInCurrency({ currencyCode })
          )
        )(travelArrangements);
      }
    )(state);
  };
}

export function selectWelcomeAmentities({ reservationId, currencyCode }) {
  return (state) => {
    const booking = get(["bookings", "byReservationId", reservationId])(state);

    return flow(
      get([
        "transportationAmenitiesContent",
        "byReservationId",
        reservationId,
        "productCategories",
        "welcomeAmenities",
      ]),
      (welcomeAmenities) => {
        if (!welcomeAmenities) {
          return welcomeAmenities;
        }

        return update(
          ["categories"],
          flow(
            filterProducts,
            processTravelArrangementsFields(booking),
            addPriceInCurrency({ currencyCode })
          )
        )(welcomeAmenities);
      }
    )(state);
  };
}

export function selectBookingConfig({ reservationId }) {
  return get([
    "transportationAmenitiesContent",
    "byReservationId",
    reservationId,
    "bookingConfig",
  ]);
}

export function selectPhoneNumberPrimary({ reservationId }) {
  return get([
    "transportationAmenitiesContent",
    "byReservationId",
    reservationId,
    "phoneNumberPrimary",
  ]);
}
