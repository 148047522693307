import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { getErrorCodeForTranslations } from "utils/errorCodes";
import { getPrivacyLink } from "utils/externalLinks";
import * as regexPatterns from "utils/regexPatterns";
import { isMobileApp, isIOS } from "utils";
import { isNotBlank } from "utils/isBlank";
import trimStringValues from "utils/trimStringValues";
import validPhoneNumber from "utils/validPhoneNumber";
import useCountries from "hooks/useCountries";
import useWorkplaces from "hooks/useWorkplaces";
import { useTranslation } from "hooks";
import env from "config/env";
import * as authenticationRoutes from "Authentication/authenticationRoutes";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";

import {
  Button,
  Checkbox,
  Dropdown,
  ErrorMessage,
  PhoneNumber,
  TextInput,
  Link,
} from "Profile/components";
import {
  selectProfileError,
  selectRegistrationFieldErrors,
} from "store/profile";
import TITLES from "Profile/utils/userProfileTitleOptions";
import useRecaptcha from "../../BookingFlow/ConfirmYourStay/hooks/useRecaptcha";

const { GOOGLE_RECAPTCHA_PROFILE_ENABLED } = env;

function EnterRegistrationDetails({
  defaultValues: defaultValuesOverrides = {},
  employeeMode,
  pageTitle = "Create Profile",
  onSubmit = () => {},
  onCancel = () => {},
}) {
  const { t, locale } = useTranslation();

  const readOnlyFields = Object.keys(defaultValuesOverrides);

  const defaultValues = {
    workdayId: "",
    title: "",
    firstName: "",
    surname: "",
    countryCode: "",
    phoneNumber: "",
    email: "",
    workPlace: "",
    ...defaultValuesOverrides,
  };

  const {
    register,
    handleSubmit,
    control,
    errors: initialErrors,
    formState,
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const error = useSelector(selectProfileError);
  const formErrors = useSelector(selectRegistrationFieldErrors);
  const errors = { ...initialErrors, ...formErrors };
  const { countries, isLoading: isLoadingCountries } = useCountries();
  const { workplaces, isLoading: isLoadingWorkplaces = false } = useWorkplaces({
    employeeMode,
  });

  const {
    recaptchaElementId,
    recaptchaCallbackName,
    isReady,
    recaptchaExecute,
  } = useRecaptcha({ locale });

  const onSubmitTrimValues = (formValues) => {
    onSubmit(trimStringValues(formValues));
  };

  const onSubmitWithRecaptcha = (formValues) => {
    recaptchaExecute().then((gRecaptchaResponse) => {
      onSubmitTrimValues({ ...formValues, gRecaptchaResponse });
    });
  };

  return (
    <div className="view view--register-details">
      <h1>{t(pageTitle)}</h1>

      <form
        onSubmit={handleSubmit(
          GOOGLE_RECAPTCHA_PROFILE_ENABLED
            ? onSubmitWithRecaptcha
            : onSubmitTrimValues
        )}
        noValidate
        autoComplete="off"
      >
        {error && (
          <ErrorMessage>
            {t(getErrorCodeForTranslations({ errorCode: error.errorCode }))}
          </ErrorMessage>
        )}

        {employeeMode && (
          <TextInput
            label={t("Workday Employee Id")}
            name="workdayId"
            register={register({
              required: true,
              validate: {
                isNotBlank,
              },
            })}
            required
            error={
              errors.workdayId && t("Please enter your Workday Employee Id.")
            }
            value={defaultValues.workdayId}
            readOnly={readOnlyFields.includes("workdayId")}
          />
        )}

        <div className="view--register-details__inline-selects">
          <Dropdown
            label={t("Title")}
            name="title"
            options={TITLES.map(({ label, ...rest }) => ({
              label: t(label),
              ...rest,
            }))}
            register={register({ required: true })}
            required
            error={errors.title && t("Please select a title")}
            value={defaultValues.title}
          />

          <TextInput
            label={t("First name")}
            name="firstName"
            register={register({
              required: true,
              validate: {
                isNotBlank,
              },
            })}
            required
            error={errors.firstName && t("Please enter your first name")}
            value={defaultValues.firstName}
            readOnly={readOnlyFields.includes("firstName")}
          />
        </div>

        <TextInput
          label={t("Last name")}
          name="surname"
          register={register({
            required: true,
            validate: {
              isNotBlank,
            },
          })}
          required
          error={errors.surname && t("Please enter your last name")}
          value={defaultValues.surname}
          readOnly={readOnlyFields.includes("surname")}
        />

        <Dropdown
          label={t("Country/Region")}
          name="countryCode"
          options={countries.map(({ iso2, shortName }) => ({
            value: iso2,
            label: shortName,
          }))}
          register={
            defaultValues.countryCode
              ? register()
              : register({ required: true })
          }
          required
          error={errors.countryCode && t("Please enter your country")}
          value={defaultValues.countryCode}
        />

        <Controller
          name="phoneNumber"
          control={control}
          rules={{
            required: true,
            pattern: regexPatterns.telephoneNumber,
            validate: { validPhoneNumber },
          }}
          as={PhoneNumber}
          countries={countries}
          isLoadingCountries={isLoadingCountries}
          countryCodeName="phoneCountryCode"
          required
          error={errors.phoneNumber && t("Please enter your phone number")}
          value={defaultValues.phoneNumber}
        />

        <TextInput
          label={t("Email")}
          type="email"
          name="email"
          register={register({
            required: true,
            pattern: regexPatterns.email,
          })}
          required
          error={errors.email && t("Please enter your email address")}
          value={defaultValues.email}
        />

        {employeeMode && (
          <Dropdown
            label={t("Work Place")}
            name="workPlace"
            options={workplaces.map(({ title, location }) => ({
              value: location,
              label: title,
            }))}
            register={register({ required: true })}
            required
            error={errors.workPlace && t("Please enter your work place.")}
            value={defaultValues.workPlace}
          />
        )}

        <Checkbox name="consent" register={register}>
          {t(
            "I consent to Four Seasons sending me electronic communications so that Four Seasons can keep me informed of upcoming promotions and exclusive offers and to conduct marketing research."
          )}
        </Checkbox>

        <div className="legal-text">
          <p
            dangerouslySetInnerHTML={{
              __html: t(
                'I acknowledge that Four Seasons will handle my personal information as set out in the <a href="{0}" target="_blank">Four Seasons Privacy Notice</a>. I understand that if I provided my consent to receive certain electronic communications above, I can withdraw that consent at any time by emailing <a href="mailto:guest.historian@fourseasons.com">guest.historian@fourseasons.com</a>.',
                [getPrivacyLink(locale)]
              ),
            }}
          />
        </div>
        <div className="view__actions">
          <div className="view__action">
            {employeeMode ? (
              <Link
                to={authenticationRoutes.employeeSignIn.to({ locale })}
                kind="secondary"
              >
                {t("Back")}
              </Link>
            ) : (
              <Button kind="secondary" onClick={onCancel}>
                {t("Back")}
              </Button>
            )}
          </div>
          <div className="view__action">
            <Button type="submit" disabled={!formState.isValid}>
              {t("Next")}
            </Button>
          </div>
        </div>
        {GOOGLE_RECAPTCHA_PROFILE_ENABLED && isReady && (
          <div
            id={recaptchaElementId}
            className={classNames("g-recaptcha", {
              iosBottom: isIOS() || isMobileApp(),
            })}
            data-callback={recaptchaCallbackName}
          />
        )}
        {(isLoadingCountries ||
          isLoadingWorkplaces ||
          (GOOGLE_RECAPTCHA_PROFILE_ENABLED && !isReady)) && (
          <BookingFlowLoadingIndicator />
        )}
      </form>
    </div>
  );
}
export default EnterRegistrationDetails;
