import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import env from "config/env";

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use({
    type: "postProcessor",
    name: "doubleSingleQuoteReplacer",
    process: (value) =>
      (value && value.replaceAll && value.replaceAll("''", "'")) || value,
  })
  .init({
    detection: {
      order: ["localStorage"],
    },

    postProcess: "doubleSingleQuoteReplacer",
    fallbackLng: ["en"],
    nsSeparator: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false,
      prefix: "{",
      suffix: "}",
    },
    debug: false,
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        // LocalStorageBackend options:
        {
          expirationTime: 24 * 60 * 60 * 1000, // 1 day
        },

        // HttpApi options:
        {
          loadPath(lngs) {
            const lng = (typeof lngs === "string" ? [lngs] : lngs)[0];
            return `${env.CONTENT_API_BASE_URL}/i18n/dict.${lng}.json`;
          },

          parse(data) {
            if (data === '{"error":"invalid dictionary reference"}') {
              return {};
            }

            const parsed = JSON.parse(data);

            // replace all incorrect interpolation prefix / suffix (<#>) with correct ({0})
            return Object.keys(parsed)
              .filter((key) => key.includes("<#>"))
              .reduce((acc, key) => ({
                  ...acc,
                  [key.replaceAll("<#>", "{0}")]: parsed[key].replaceAll(
                    "<#>",
                    "{0}"
                  ),
                }), parsed);
          },
        },
      ],
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
