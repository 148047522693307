import { ofType } from "redux-observable";
import { catchError, mergeMap, switchMap, takeUntil } from "rxjs/operators";

import { errorCodes } from "utils/errorCodes";
import { retrieveBooking$ } from "api/tretail/bookingHistory";
import { addExistingReservation$ } from "api/email";
import {
  setIsSubmitting,
  setIsSuccess,
  setAddReservationError,
} from "store/addReservationModal/addReservationModal.slice";
import catchInternalServerError from "store/catchInternalServerError";
import {
  addReservation,
  addReservationFailed,
  addReservationFulfilled,
  addReservationCancel,
} from "../bookings.slice";

export default function addReservationEpic(action$) {
  return action$.pipe(
    ofType(addReservation.type),
    switchMap(({ payload: { surname, hotelCode, reservationId, locale } }) => retrieveBooking$({
        surname,
        hotelCode,
        reservationId,
        locale,
      }).pipe(
        switchMap(({ bookingId }) =>
          addExistingReservation$({ bookingId }).pipe(
            mergeMap(() => [setIsSuccess(true), addReservationFulfilled])
          )
        ),

        catchInternalServerError(),

        catchError(({ response } = {}) => {
          const error =
            (response?.supplierErrors || []).length > 0
              ? response.supplierErrors[0]
              : (response?.apiErrors || []).length > 0
              ? response.apiErrors[0]
              : { errorCode: errorCodes.COULD_NOT_RETRIEVE };

          return [
            addReservationFailed(),
            setAddReservationError(error),
            setIsSubmitting(false),
          ];
        }),

        takeUntil(action$.pipe(ofType(addReservationCancel.type)))
      ))
  );
}
