import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import * as dateFnLocales from "date-fns/locale";

import { useTranslation } from "hooks";
import { getDateFnsLocale } from "config/languages";
import Month from "./Month";
import NavButton from "./NavButton";
import FlexDatesInput from "./FlexDatesInput";
import DatepickerContext from "./datepickerContext";
import useCalendar from "../hooks/useCalendar";
import {
  getCalendarMonthLabel,
  getWeekdayLabelFormatter,
} from "./calendarHelpers";

const formatDate = (date, locale) => {
  const f = (formatString) => format(date, formatString, {
      locale: dateFnLocales[getDateFnsLocale(locale)],
    });

  switch (locale) {
    case "zh":
    case "zh_hant":
    case "jp":
      return f("yyyy'年'MM'月'dd'日'");

    case "kr":
      return f("yyyy'년'MM'월'dd'일'");

    // case "ar":
    //   return f("eeee d MMMM yy");

    // case "es":
    // case "es-es":
    //   return f("eee d MMM'.' yy");

    // case "de":
    //   return f("eee',' d MMM'.' ''yy");

    // case "it":
    //   return f("eee d MMM yy")
    //     .split("")
    //     .map((a, i) => (i === 0 ? a.toUpperCase() : a))
    //     .join("");

    // case "br":
    // case "pt":
    //   return f("eee d MMM yy")
    //     .split(" ")
    //     .map((a) => a[0].toUpperCase() + a.substring(1, a.length))
    //     .join(" ");

    // case "tr":
    //   return f("d MMM yyyy eee");

    default:
      return f("MM/dd/yyyy");
  }
};

export default function DateRangePicker({
  availabilityEnabled,
  availabilityLastUpdated,
  checkIn,
  checkInEnd,
  checkInStart,
  checkOut,
  checkOutEnd,
  hasCheckInError,
  hasCheckOutError,
  hasUserSelectedCheckIn,
  maxNumberOfNights,
  minNumberOfNights,
  nextControlOutsideWidget,
  onChange,
  restrictedDates,
  setCheckIn,
  setCheckOut,
  setHasUserSelectedCheckIn,
  unavailableDates,
  initialFocus,
  flexDateOptions = [],
  flexDatesWindow,
  setFlexDatesWindow,
}) {
  const { t, locale } = useTranslation();

  const {
    activeMonths,
    availabilityLastUpdatedDescription,
    canNavigateToNextMonth,
    canNavigateToPreviousMonth,
    // checkType,
    componentOnBlur,
    componentRef,
    endDate,
    // endDateInputRef,
    firstMonth,
    focusedDate,
    // formatedEndDate,
    // formatedStartDate,
    goToNextMonths,
    goToPreviousMonths,
    // handleEndDateOnClick,
    // handleEndDateOnFocus,
    handleNavigatingAwayFromCalendar,
    // handleNavigatingAwayFromEndDateInput,
    handleNavigatingAwayFromNextMonth,
    handleNavigatingAwayFromPrevMonth,
    handleNavigatingAwayFromStartDateInput,
    handleOnMouseDown,
    handleStartDateOnClick,
    handleStartDateOnFocus,
    hideCalendar,
    firstDayOfWeek,
    isCalendarVisible,
    isDateAvailable,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isEndDate,
    isFirstOrLastSelectedDate,
    isRestricted,
    isStartDate,
    isUnselectable,
    isOutsideCheckInRange,
    nextMonthRef,
    // numberOfSelectedDays,
    onDateFocus,
    onDateSelect,
    prevMonthRef,
    startDate,
    startDateInputRef,
    validInterval,
    includeDaysFromOtherMonths,
    weekdayLabelFormatter,
  } = useCalendar({
    availabilityEnabled,
    availabilityLastUpdated,
    checkIn,
    checkInEnd,
    checkInStart,
    checkOut,
    checkOutEnd,
    hasCheckInError,
    hasCheckOutError,
    hasUserSelectedCheckIn,
    locale,
    maxNumberOfNights,
    minNumberOfNights,
    nextControlOutsideWidget,
    onChange,
    restrictedDates,
    setCheckIn,
    setCheckOut,
    setHasUserSelectedCheckIn,
    unavailableDates,
    weekdayLabelFormatter: getWeekdayLabelFormatter(locale),
    initialFocus,
    hideCalendarOnEndDateSelection: false,
  });

  const formatedStartDate = formatDate(startDate, locale);
  const formatedEndDate = formatDate(endDate, locale);
  const displayedValue = [
    `${formatedStartDate} - ${formatedEndDate}`,
    flexDatesWindow ? `| ±  ${flexDatesWindow} ${t("Days")}` : null,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div ref={componentRef} onBlur={componentOnBlur}>
      <div
        className={classNames(`datepicker`, {
          "calendar-open": isCalendarVisible,
        })}
      >
        <input
          id="checkin"
          type="text"
          className={classNames(
            "fs-select-date fs-select-date--check-in-check-out emulated-flex-gap-component",
            {
              "search-panel-error": hasCheckInError || hasCheckOutError,
            }
          )}
          value={displayedValue}
          onClick={handleStartDateOnClick}
          onFocus={handleStartDateOnFocus}
          onKeyDown={handleNavigatingAwayFromStartDateInput}
          aria-label={t("Check In")}
          readOnly
          ref={startDateInputRef}
        />
      </div>

      {isCalendarVisible && (
        <DatepickerContext.Provider
          value={{
            focusedDate,
            handleNavigatingAwayFromCalendar,
            handleNavigatingAwayFromNextMonth,
            isDateAvailable,
            isDateBlocked,
            isDateFocused,
            isDateHovered,
            isDateSelected,
            isEndDate,
            isFirstOrLastSelectedDate,
            isRestricted,
            isStartDate,
            isUnselectable,
            isOutsideCheckInRange,
            onDateFocus,
            startDate,
            onDateSelect: (a) => {
              setHasUserSelectedCheckIn(true);
              onDateSelect(a);
            },
            validInterval,
          }}
        >
          <div
            className="fs-calendar-wrapper"
            onMouseDown={handleOnMouseDown}
            role="presentation"
          >
            <div className="fs-calendar" role="application">
              <div>
                <div className="ui-datepicker-header">
                  <NavButton
                    onClick={goToPreviousMonths}
                    isEnabled={canNavigateToPreviousMonth}
                    ref={prevMonthRef}
                    handleOnKeyDown={handleNavigatingAwayFromPrevMonth}
                  />
                  <div className="ui-datepicker-title">
                    {getCalendarMonthLabel(firstMonth, locale)}
                  </div>
                  <NavButton
                    direction="right"
                    onClick={goToNextMonths}
                    isEnabled={canNavigateToNextMonth}
                    ref={nextMonthRef}
                    handleOnKeyDown={handleNavigatingAwayFromNextMonth}
                  />
                </div>
                <Month
                  firstDayOfWeek={firstDayOfWeek}
                  month={activeMonths[0]}
                  includeDaysFromOtherMonths={includeDaysFromOtherMonths}
                  weekdayLabelFormatter={weekdayLabelFormatter}
                />
              </div>
              <footer className="datepicker-footer">
                {flexDateOptions.length > 0 && (
                  <div>
                    <p className="flex-dates-flexible-label ty-c2">
                      {t("Are your dates flexible?")}
                    </p>
                    <FlexDatesInput
                      flexDateOptions={flexDateOptions}
                      value={flexDatesWindow}
                      onChange={setFlexDatesWindow}
                    />
                  </div>
                )}

                <div className="flex-dates-nights-selected">
                  {displayedValue}

                  <button
                    type="button"
                    onClick={hideCalendar}
                    className="btn btn--primary"
                  >
                    {t("Done")}
                  </button>
                </div>

                {availabilityEnabled && (
                  <div className="flex-dates-availability-legend">
                    <ul className="legend">
                      <li className="availability" title="No Availability">
                        {t("noavailability")}
                      </li>
                      <li
                        className="restrictions"
                        title={t("Restrictions may apply")}
                      >
                        {t("Restrictions may apply")}
                      </li>
                    </ul>
                    <div className="flex-dates-availability-legend__LastUpdatedDescription">
                      {availabilityLastUpdatedDescription}
                    </div>
                  </div>
                )}
              </footer>
            </div>
          </div>
        </DatepickerContext.Provider>
      )}
    </div>
  );
}
