import { ofType } from "redux-observable";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";
import { of } from "rxjs";

import { retrieveMailSubscriptionTemplates$ } from "api/tretail/mailSubscriptions";
import {
  fetchGlobalMailSubscriptions,
  fetchGlobalMailSubscriptionsCancel,
  fetchGlobalMailSubscriptionsFailed,
  fetchGlobalMailSubscriptionsFulfilled,
} from "../globalMailSubscriptions.slice";

export const fetchGlobalMailSubscriptions$ = ({ locale }) => retrieveMailSubscriptionTemplates$({
    locale,
    subscriptionTypeSfApiName: "Global",
  }).pipe(
    map(fetchGlobalMailSubscriptionsFulfilled),

    catchError(({ response = {} }) =>
      of(
        fetchGlobalMailSubscriptionsFailed({
          apiErrors: response?.apiErrors || [],
          supplierErrors: response?.supplierErrors || [],
        })
      )
    )
  );

export default function fetchGlobalMailSubscriptionsEpic(action$) {
  return action$.pipe(
    ofType(fetchGlobalMailSubscriptions.type),
    switchMap(({ payload: { locale } }) => fetchGlobalMailSubscriptions$({ locale }).pipe(
        takeUntil(action$.pipe(ofType(fetchGlobalMailSubscriptionsCancel.type)))
      ))
  );
}
