import { combineEpics, ofType } from "redux-observable";
import { switchMap, takeUntil, mergeMap } from "rxjs/operators";
import { of } from "rxjs";

import getProperties$ from "api/content/getProperties/getProperties";
import {
  fetchProperties,
  fetchPropertiesCancel,
  fetchPropertiesFailed,
  fetchPropertiesFulfilled,
} from "./properties.slice";

export const fetchProperties$ = ({ locale }) => getProperties$({ locale }).pipe(
    mergeMap(({ regions, error }) =>
      error
        ? of(fetchPropertiesFailed(error))
        : of(fetchPropertiesFulfilled(regions))
    )
  );

const fetchPropertiesEpic = (action$) =>
  action$.pipe(
    ofType(fetchProperties.type),
    switchMap(({ payload: { locale } }) =>
      fetchProperties$({ locale }).pipe(
        takeUntil(action$.pipe(ofType(fetchPropertiesCancel.type)))
      )
    )
  );

export default combineEpics(fetchPropertiesEpic);
