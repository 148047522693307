import filter from "lodash/fp/filter";
import flatten from "lodash/fp/flatten";
import flow from "lodash/fp/flow";
import get from "lodash/fp/get";
import head from "lodash/fp/head";
import map from "lodash/fp/map";
import reverse from "lodash/fp/reverse";
import zipAll from "lodash/fp/zipAll";

const RESULT_NOT_AVAILABLE = "N/A";

const getNearestAvailableResultSetId = (checkinDate) => flow(
    (hotelSearchResultSets) => {
      const splitIndex = hotelSearchResultSets.findIndex(
        (hotelSearchResultSet) =>
          hotelSearchResultSet.checkinDate === checkinDate
      );
      return [
        reverse(hotelSearchResultSets.slice(0, splitIndex + 1)),
        hotelSearchResultSets.slice(splitIndex + 1),
      ];
    },
    zipAll,
    flatten,
    map(get("resultSetId")),
    filter((rsId) => rsId && rsId !== RESULT_NOT_AVAILABLE),
    head
  );

export default getNearestAvailableResultSetId;
