import React, { useContext } from "react";
import { useTranslation } from "hooks";
import { TextInput } from "Profile/components";
import { MediaContext } from "contexts/MediaContext";
import { PROFILE } from "utils/dateFormats";
import { generateNumbersList } from "utils/utils";
import { parse, isValid as dateFnsIsValid } from "date-fns";
import get from "lodash/get";
import { useWatch } from "react-hook-form";
import SmokingOptions from "./SmokingOptions";
import Occupancy from "../BookingFields/Occupancy";

function RoomDetail({
  includeHeader,
  formNamePrefix,
  onDelete,
  index,
  formValues = {},
  formControls,
  searchData,
  smokeFree,
}) {
  const { t } = useTranslation();
  const media = useContext(MediaContext);
  const { register, errors, control } = formControls;

  const formNames = {
    smoking: `${formNamePrefix}.smoking`,
    adults: `${formNamePrefix}.totalAdults`,
    children: `${formNamePrefix}.totalChildren`,
  };

  const { totalAdults, totalChildren } = useWatch({
    control,
    defaultValue: formValues,
    name: formNamePrefix,
  });

  const adultNames = generateNumbersList(totalAdults).map((humanIdx, idx) => {
    const relativeName = `adults[${idx}].name`;
    const name = `${formNamePrefix}.${relativeName}`;

    return {
      name,
      value: get(formValues, relativeName, ""),
      label: t("Adult {0} Name", [humanIdx]),
      required: idx === 0,
      error:
        get(errors, name) && t("Please enter the guest first and last names."),
    };
  });

  const childrenFields = generateNumbersList(totalChildren).map(
    (humanIdx, idx) => {
      const relativeBirthday = `children[${idx}].birthday`;
      const relativeName = `children[${idx}].name`;
      const birthday = `${formNamePrefix}.${relativeBirthday}`;
      const name = `${formNamePrefix}.${relativeName}`;

      return {
        name: {
          name,
          value: get(formValues, relativeName, ""),
          label: t("Child {0} Name", [humanIdx]),
        },
        birthday: {
          name: birthday,
          value: get(formValues, relativeBirthday, ""),
          label: t("Child {0} Birthday", [humanIdx]),
          error:
            get(errors, birthday) &&
            t("Please enter a valid birthdate. (MM/DD/YYYY)"),
        },
      };
    }
  );

  const isValidBirthday = (str) => (
      !str || dateFnsIsValid(parse(str, PROFILE.EMP_CHILD_BIRTHDAY, new Date()))
    );

  return (
    <div className="room-detail">
      <hr className={includeHeader ? "sub-divider" : ""} />
      {includeHeader && (
        <div className="room-number">
          <h3 className="ty-c3">{t("Room {0}", [`${index + 1}`])}</h3>
        </div>
      )}
      {index > 0 && (
        <button className="btn-close" type="button" onClick={onDelete}>
          <span>{t("Delete")}</span>
        </button>
      )}
      <Occupancy
        formNames={formNames}
        formValues={{
          adults: totalAdults,
          children: totalChildren,
        }}
        searchData={searchData}
        formControls={formControls}
      />
      <div className="row">
        <div className="col-sm-12 col-md-6">
          {adultNames.map(({ name, label, required, error, value }) => (
            <TextInput
              key={name}
              label={label}
              defaultValue={value}
              placeholder={label}
              name={name}
              register={register({
                required,
              })}
              required={required}
              error={error}
            />
          ))}

          {media.isGreaterThan.sm && !smokeFree && (
            <SmokingOptions
              name={formNames.smoking}
              key={formNames.smoking}
              formControls={formControls}
              defaultValue={formValues.smoking}
            />
          )}
        </div>

        <div className=" col-sm-12 col-md-6">
          {childrenFields.map(({ name, birthday }) => (
            <React.Fragment key={`${name.name}-${birthday.name}`}>
              <TextInput
                key={name.name}
                label={name.label}
                placeholder={name.label}
                name={name.name}
                defaultValue={name.value}
                register={register()}
              />
              <TextInput
                key={birthday.name}
                label={birthday.label}
                placeholder={birthday.label}
                name={birthday.name}
                defaultValue={birthday.value}
                register={register({
                  validate: { isValidBirthday },
                })}
                error={birthday.error}
              />
            </React.Fragment>
          ))}
        </div>

        {!media.isGreaterThan.sm && !smokeFree && (
          <div className="col-sm-12 col-md-6">
            <SmokingOptions
              name={formNames.smoking}
              key={formNames.smoking}
              formControls={formControls}
              defaultValue={formValues.smoking}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default RoomDetail;
