import React from "react";

import { useTranslation } from "hooks";

function ItineraryAction({ href, children, ...props }) {
  if (href) {
    return (
      <a href={href} className="itinerary__quick-link ty-c4" {...props}>
        {children}
      </a>
    );
  }
  return (
    <button type="button" className="itinerary__quick-link ty-c4" {...props}>
      {children}
    </button>
  );
}

export default function ItineraryQuickLinks({ itineraryQuickLinks }) {
  const { t } = useTranslation();

  return (
    <div className="itinerary__block">
      <div className="itinerary__block__title">
        {t("We are here to help you perfect your stay")}
      </div>
      <div className="itinerary__block__cards">
        {itineraryQuickLinks.map(({ title, icon, href }) => (
            <ItineraryAction href={href} key={title}>
              {icon}
              {title?.html ? (
                <span dangerouslySetInnerHTML={{ __html: title.html }} />
              ) : (
                title
              )}
            </ItineraryAction>
          ))}
      </div>
    </div>
  );
}
