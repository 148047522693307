import React from "react";
import chunk from "lodash/chunk";
import { add, getMonth, getYear, sub } from "date-fns";

import { useMonth } from "@datepicker-react/hooks";

import { useTranslation } from "hooks";
import Day from "./Day";
import { generateDays, formatDateWithLocale } from "./calendarHelpers";

function useMonthData({
  month: monthData,
  firstDayOfWeek,
  includeDaysFromOtherMonths,
  weekdayLabelFormat,
  locale,
}) {
  const month = new Date(monthData.year, monthData.month);

  const previousMonthInfo = sub(month, { months: 1 });
  const nextMonthInfo = add(month, { months: 1 });

  const dayLabelFormat = formatDateWithLocale("d", locale);

  const { days: previousMonthDays } = useMonth({
    year: getYear(previousMonthInfo),
    month: getMonth(previousMonthInfo),
    dayLabelFormat,
  });
  const { days: nextMonthDays } = useMonth({
    year: getYear(nextMonthInfo),
    month: getMonth(nextMonthInfo),
    firstDayOfWeek,
    dayLabelFormat,
  });
  const { days: currentMonthDays, weekdayLabels } = useMonth({
    year: getYear(month),
    month: getMonth(month),
    firstDayOfWeek,
    weekdayLabelFormat,
    dayLabelFormat,
  });

  const days = includeDaysFromOtherMonths
    ? generateDays(currentMonthDays, previousMonthDays, nextMonthDays)
    : currentMonthDays;

  return {
    days,
    weekdayLabels,
  };
}

function Month({
  firstDayOfWeek,
  includeDaysFromOtherMonths,
  month,
  weekdayLabelFormatter,
}) {
  const { locale } = useTranslation();

  const { days, weekdayLabels } = useMonthData({
    month,
    firstDayOfWeek,
    includeDaysFromOtherMonths,
    weekdayLabelFormat: weekdayLabelFormatter,
    locale,
  });

  return (
    <table className="ui-datepicker-calendar">
      <thead>
        <tr>
          {weekdayLabels.map((dayLabel, i) => (
            <th className="datepicker-day-label" key={`${dayLabel}:${i}`}>
              {dayLabel}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {chunk(days, 7).map((sevenDays, i) => (
          <tr key={i}>
            {sevenDays.map((day, j) =>
              !day || typeof day !== "object" ? (
                <td key={j} />
              ) : (
                <Day key={j} day={day} />
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Month;
