import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";
import { of } from "rxjs";

import { retrieveAllHotelProductPostBookingComments$ } from "api/tretail/bookingProductCust";
import catchInternalServerError from "store/catchInternalServerError";
import {
  fetchBookingComments,
  fetchBookingCommentsFulfilled,
  fetchBookingCommentsFailed,
  fetchBookingCommentsCancel,
} from "./bookingComments.slice";
import updatePostBookingCommentsEpic from "./epics/updatePostBookingComments";
import updateProfileCommentsEpic from "./epics/updateProfileComments";

const fetchBookingCommentsEpic = (action$) =>
  action$.pipe(
    ofType(fetchBookingComments.type),

    switchMap(({ payload: { bookingId, reservationId } }) =>
      retrieveAllHotelProductPostBookingComments$({
        bookingId,
      }).pipe(
        map((postBookingComments) => fetchBookingCommentsFulfilled({
            bookingId,
            reservationId,
            postBookingComments,
          })),

        catchInternalServerError(),

        catchError(({ response = {} }) =>
          of(
            fetchBookingCommentsFailed({
              apiErrors: response?.apiErrors || [],
              supplierErrors: response?.supplierErrors || [],
            })
          )
        ),

        takeUntil(action$.pipe(ofType(fetchBookingCommentsCancel.type)))
      )
    )
  );

export default combineEpics(
  fetchBookingCommentsEpic,
  updateProfileCommentsEpic,
  updatePostBookingCommentsEpic
);
