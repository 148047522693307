import { ofType } from "redux-observable";
import { concatMap, switchMap, takeUntil } from "rxjs/operators";

import { getHotelSearchResults$ } from "api/tretail/hotel";
import { getOffersTermsAndConditionsContent$ } from "api/content";
import { fetchOffersTermsAndConditionsContentFulfilled } from "store/offersTermsAndConditionsContent";
import {
  fetchSearchResultsForResultSetId,
  fetchSearchResultsForResultSetIdFulfilled,
  fetchSearchResultsCancel,
} from "../searchResults.slice";
import getOfferCodesFromHotelResultsResponse from "../getOfferCodesFromHotelResultsResponse";

export default function fetchSearchResultsForResultSetIdEpic(action$) {
  return action$.pipe(
    ofType(fetchSearchResultsForResultSetId.type),
    switchMap(({ payload: { locale, resultSetId, searchParams } }) => getHotelSearchResults$({
        locale,
        resultSetId,
      }).pipe(
        switchMap((getHotelSearchResultsResponse) => {
          const roomTypeIds = getOfferCodesFromHotelResultsResponse(
            getHotelSearchResultsResponse
          );
          return getOffersTermsAndConditionsContent$({
            locale,
            hotelCode: searchParams.hotelCode,
            checkinDate: searchParams.dates.checkIn,
            checkoutDate: searchParams.dates.checkOut,
            promoCode: searchParams.promoCode,
            roomTypeIds,
          }).pipe(
            concatMap((offersTermsAndConditionsContent) => [
              fetchSearchResultsForResultSetIdFulfilled({
                data: getHotelSearchResultsResponse,
              }),
              fetchOffersTermsAndConditionsContentFulfilled(
                offersTermsAndConditionsContent
              ),
            ])
          );
        }),
        takeUntil(action$.pipe(ofType(fetchSearchResultsCancel.type)))
      ))
  );
}
