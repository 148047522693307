import React from "react";
import { groupBy } from "lodash/fp";

import { useTranslation } from "hooks";
import { AncillaryCards } from "Profile/components/AncillaryCards";
import ArrivalCard from "./ArrivalCard";
import DepartureCard from "./DepartureCard";
import ActivityCard from "./ActivityCard";
import ItineraryQuickLinks from "./ItineraryQuickLinks";
import {
  ARRIVAL_TYPE,
  ANCILLARY_CARDS,
  DEPARTURE_TYPE,
} from "../getItineraryItems";

export default function ItineraryDay({
  hotelCode,
  date,
  itineraryItems: initialItineraryItems = [],
  isWebChatEnabled,
  openWebChat,
  itineraryQuickLinks,
}) {
  const { ancillaryCards = [], itineraryItems = [] } = groupBy(
    (itineraryItem) => {
      if (itineraryItem.requestSubtype === ANCILLARY_CARDS) {
        return "ancillaryCards";
      }
      return "itineraryItems";
    }
  )(initialItineraryItems);

  const { t, formatDateInLocale } = useTranslation();

  const formattedDate = formatDateInLocale(date, "MMM d");

  const isEmptyDay = itineraryItems[0]?.emptyDay;
  const isFirstEmptyDay = itineraryItems[0]?.firstEmptyDay;

  if (isEmptyDay)
    return (
      <li>
        <div className="itinerary__date">{formattedDate}</div>
        <div className="ty-h4" style={{ fontWeight: 400 }}>
          {t("No Items planned for the day.")}
        </div>
        {isFirstEmptyDay && (
          <ItineraryQuickLinks itineraryQuickLinks={itineraryQuickLinks} />
        )}
      </li>
    );

  return (
    <>
      <li>
        <div className="itinerary__date">{formattedDate}</div>
        <ul>
          {itineraryItems.map((itineraryItem) => {
            const { id, requestSubtype } = itineraryItem;

            const formattedTime =
              itineraryItem.time &&
              formatDateInLocale(itineraryItem.time, "h:mma", "HH:mm:ss.SSS");

            return (
              <li key={id || requestSubtype}>
                {itineraryItem.status === "Requested" && (
                  <div className="itinerary__status">
                    {t(itineraryItem.status)}
                  </div>
                )}

                {formattedTime &&
                  requestSubtype !== ANCILLARY_CARDS &&
                  requestSubtype !== DEPARTURE_TYPE && (
                    <div className="itinerary__time">{formattedTime}</div>
                  )}

                {requestSubtype === ARRIVAL_TYPE && (
                  <ArrivalCard
                    adultsCount={itineraryItem.guests.adults}
                    childCount={itineraryItem.guests.child}
                    priceViewable={itineraryItem.priceViewable}
                    id={itineraryItem.id}
                    highlightsMap={itineraryItem.highlightsMap}
                    bookingMessages={itineraryItem.bookingMessages}
                    rates={itineraryItem.rates}
                    estimatedTotalDisclaimer={
                      itineraryItem.estimatedTotalDisclaimer
                    }
                    isWebChatEnabled={isWebChatEnabled}
                    openWebChat={openWebChat}
                  />
                )}

                {requestSubtype === DEPARTURE_TYPE && <DepartureCard />}

                {![ARRIVAL_TYPE, ANCILLARY_CARDS, DEPARTURE_TYPE].includes(
                  requestSubtype
                ) && (
                  <ActivityCard
                    hotelCode={hotelCode}
                    formattedDate={formattedDate}
                    formattedTime={formattedTime}
                    itineraryItem={itineraryItem}
                    isWebChatEnabled={isWebChatEnabled}
                    openWebChat={openWebChat}
                  />
                )}
              </li>
            );
          })}
        </ul>
      </li>

      {ancillaryCards.map(({ restaurants }, index) => (
          <li key={index} className="itinerary__ancillary-cards">
            <div className="itinerary__ancillary-cards__title">
              {t("Reserve a table")}
            </div>
            <AncillaryCards ancillaries={restaurants} />
          </li>
        ))}
    </>
  );
}
