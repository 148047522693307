import React from "react";
import { useTranslation } from "hooks";
import { webCheckinMapOptions } from "utils/utils";
import _get from "lodash/get";

import { TextInput, Dropdown } from "Profile/components";
import * as regexPatterns from "utils/regexPatterns";

function CustomField({ field = {}, domestic = false, register, errors, idx }) {
  const { t } = useTranslation();

  const required =
    field.validation === "requiredBoth" ||
    (field.validation === "requiredDomestic" && domestic) ||
    (field.validation === "requiredInternational" && !domestic) ||
    false;

  const pattern =
    (field.additionalValidation === "email" && regexPatterns.email) ||
    (field.additionalValidation === "date" && regexPatterns.dateMMDDYYYY);

  const fieldName = (which) => `additionalInformation.${idx}.${which}`;

  const error =
    _get(errors, fieldName("value").split(".")) &&
    ((field.additionalValidation === "email" && t("Please enter an email.")) ||
      (field.additionalValidation === "date" && t("Please enter a date.")) ||
      t("This field is required."));

  return (
    <>
      {field.type === "TEXTFIELD" && (
        <TextInput
          label={field.label}
          placeholder={field.label}
          name={fieldName("value")}
          register={register({
            required,
            pattern,
          })}
          required={required}
          error={error}
        />
      )}
      {field.type === "DROPDOWN" && (
        <Dropdown
          label={field.label}
          name={fieldName("value")}
          register={register({
            required,
          })}
          required={required}
          error={error}
          options={webCheckinMapOptions(field.options)}
        />
      )}
      <input
        type="hidden"
        value={field.label}
        name={fieldName("label")}
        ref={register()}
      />
    </>
  );
}

export default CustomField;
