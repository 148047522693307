import { ofType } from "redux-observable";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { of } from "rxjs";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { getProfileDetails$ } from "api/tretail/profile";
import {
  fetchProfile,
  fetchProfileFailed,
  fetchProfileFulfilled,
  fetchProfileCancel,
} from "store/profile/profile.slice";
import { fetchBookingHistory$ } from "store/bookingHistory/epics/fetchBookingHistory";
import { fetchBookingHistoryFulfilled } from "store/bookingHistory";
import { fetchMemberDetails$ } from "store/memberDetails/epics/fetchMemberDetails";
import { fetchMemberDetailsFulfilled } from "store/memberDetails";
import catchInternalServerError from "store/catchInternalServerError";

export function fetchProfileWithBookingHistory$({ locale }) {
  return getProfileDetails$({ locale }).pipe(
    switchMap((profile) => {
      const accountId = profile?.goldenId;
      return fetchBookingHistory$({ locale }).pipe(
        mergeMap((bookingHistory) => fetchMemberDetails$({ accountId }).pipe(
            map((memberDetails) => ({
              profile,
              bookingHistory,
              memberDetails,
            }))
          ))
      );
    })
  );
}

export const handleProfileError = ({ response = {} }) =>
  of(
    fetchProfileFailed({
      apiErrors: response?.apiErrors || [],
      supplierErrors: response?.supplierErrors || [],
    })
  );

export default function fetchProfileEpic(action$) {
  return action$.pipe(
    ofType(fetchProfile.type),
    switchMap(({ payload: { locale } }) => ajaxWithHealthCheck$({
        locale,
      }).pipe(
        switchMap(() => fetchProfileWithBookingHistory$({ locale }).pipe(
            mergeMap(({ profile, bookingHistory, memberDetails }) => [
              fetchMemberDetailsFulfilled(memberDetails),
              fetchBookingHistoryFulfilled(bookingHistory),
              fetchProfileFulfilled(profile),
            ]),

            catchInternalServerError(),

            catchError(handleProfileError),

            takeUntil(action$.pipe(ofType(fetchProfileCancel.type)))
          ))
      ))
  );
}
