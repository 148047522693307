import React from "react";
import classNames from "classnames";
import { format, parse } from "date-fns";
import * as dateFnLocales from "date-fns/locale";
import { getDateFnsLocale } from "config/languages";

import { useTranslation } from "hooks";

function formatDay(date, locale) {
  const f = (formatString) => format(date, formatString, {
      locale: dateFnLocales[getDateFnsLocale(locale)],
    });

  switch (locale) {
    case "zh":
      return f("eee");

    case "it":
    case "pt":
      return f("eeee")
        .split("")
        .map((a, i) => (i === 0 ? a.toUpperCase() : a))
        .join("");

    default:
      return f("eeee");
  }
}

function formatDate(date, locale) {
  const f = (formatString) => format(date, formatString, {
      locale: dateFnLocales[getDateFnsLocale(locale)],
    });

  switch (locale) {
    case "jp":
    case "zh_hant":
    case "zh":
      return f("d '日'");

    case "kr":
      return f("d '일'");

    default:
      return f("d");
  }
}

function formatMonthYear(date, locale) {
  const f = (formatString) => format(date, formatString, {
      locale: dateFnLocales[getDateFnsLocale(locale)],
    });

  switch (locale) {
    case "zh":
    case "zh_hant":
    case "jp":
      return f("yyyy '年' M '月'");

    case "kr":
      return f("M '월' yyyy '년'");

    case "ar":
      return f("MMMM yyyy");

    case "es":
    case "es-es":
    case "de":
      return f("MMM'.' yyyy");

    case "br":
    case "pt":
      return f("MMM yyyy")
        .split("")
        .map((a, i) => (i === 0 ? a.toUpperCase() : a))
        .join("");

    default:
      return f("MMM yyyy");
  }
}

export default function MobileCheckInCheckOutDates({ checkIn, checkOut }) {
  const { t, locale } = useTranslation();

  return (
    <div className="date-triggers">
      {[checkIn, checkOut]
        .map((d) => parse(d, "yyyy-MM-dd", new Date()))
        .map((d, index) => (
          <div
            className={classNames("select-date", {
              "check-in": index === 0,
              "check-out": index === 1,
            })}
            key={d}
          >
            <div className="date-trigger date-trigger-stage">
              {index === 0 ? t("Check in") : t("Check out")}
            </div>
            <div className="date-trigger">
              <span className="date-trigger-day">
                {t(formatDay(d, locale))}
              </span>
              <div className="date-trigger__date-month">
                <span className="date-trigger-date">
                  {formatDate(d, locale)}
                </span>
                <span className="date-trigger-month">
                  {formatMonthYear(d, locale)}
                </span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
