import React, { useState } from "react";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { pluralize, formatDate } from "utils/utils";

export default function Accordion({
  dailyRates,
  stayResortFees,
  numberOfNights,
  index,
}) {
  const { t, locale, formatPrice } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <dl className="costs">
      <dt>
        <button
          type="button"
          onClick={toggle}
          aria-controls={`nightlyRates:${index}`}
          aria-expanded={isOpen}
        >
          <span>{isOpen ? t("Hide Daily Rates") : t("Show Daily Rates")}</span>
          <span dir="ltr">{pluralize(t, numberOfNights, "Night")}</span>
          <span
            className={`icon ${isOpen ? "icon-arrow-up" : "icon-arrow-down"}`}
          />
        </button>
      </dt>
      <div className={classNames("accordion-item", { collapsed: !isOpen })}>
        {dailyRates.map(({ date, price, dailyResortFees }) => (
            <React.Fragment key={date}>
              <dd>
                <span className="label">
                  {formatDate(date, "eeee, dd MMMM", locale)}
                </span>
                <strong className="amount">
                  {formatPrice({
                    currency: price.currency,
                    value: price.amount.withDecimal,
                    decimalPlaces: 2,
                  })}
                </strong>
              </dd>
              {dailyResortFees?.length > 0 &&
                dailyResortFees?.map(({ code, description, price: fee }) => (
                    <dd className="daily-fee" key={code}>
                      <span className="label">{`+ ${description}`}</span>
                      <strong className="amount">
                        {formatPrice({
                          currency: fee.currency,
                          value: fee.amount.withDecimal,
                          decimalPlaces: 2,
                        })}
                      </strong>
                    </dd>
                  ))}
            </React.Fragment>
          ))}
        {stayResortFees?.map(({ description, price }, j) => (
          <dd key={j} id={`resortFees:${index}:${j}`}>
            <span className="label">{description}</span>
            <strong className="amount">
              {formatPrice({
                currency: price.currency,
                value: price.amount.withDecimal,
                decimalPlaces: 2,
              })}
            </strong>
          </dd>
        ))}
      </div>
    </dl>
  );
}
