import React from "react";

import { useTranslation } from "hooks";
import AdultGuestRow from "./AdultGuestRow";
import ChildGuestRow from "./ChildGuestRow";

const guestIsAdult = ({ type }) => type === 1;

export default function GuestsFields({
  roomIndex,
  guests,
  register,
  control,
  errors,
  isMultiRoomBooking,
  isBookingCancelled,
}) {
  const { t } = useTranslation();

  return (
    <div className="guests">
      <fieldset>
        <legend>{t("Guest Names")}</legend>
        {guests.map((guest, index) => guestIsAdult(guest) ? (
            <AdultGuestRow
              key={index}
              roomIndex={roomIndex}
              index={index}
              register={register}
              errors={errors[index]}
              isMultiRoomBooking={isMultiRoomBooking}
              disabled={isBookingCancelled}
            />
          ) : (
            <ChildGuestRow
              key={index}
              roomIndex={roomIndex}
              index={index}
              register={register}
              control={control}
              errors={errors[index]}
              disabled={isBookingCancelled}
            />
          ))}
      </fieldset>
    </div>
  );
}
