import { ofType } from "redux-observable";
import { catchError, mergeMap, switchMap, takeUntil } from "rxjs/operators";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { proceedCheckIn$ } from "api/webCheckIn";
import { TECHNICAL_ERROR_MESSAGE } from "fixtures/constants";
import {
  confirmCheckIn,
  confirmCheckInFailed,
  confirmCheckInFulfilled,
  confirmCheckInCancel,
  webCheckInErrorEvt,
  webCheckInCompleteEvt,
} from "../webCheckIn.slice";

export default function confirmCheckInEpic(action$) {
  return action$.pipe(
    ofType(confirmCheckIn.type),
    switchMap(({ payload }) => ajaxWithHealthCheck$({
        locale: payload.locale,
      }).pipe(
        switchMap(() => proceedCheckIn$(payload).pipe(
            mergeMap(() => [
              confirmCheckInFulfilled(),
              webCheckInCompleteEvt(payload),
            ]),

            catchError(({ response }) => [
              confirmCheckInFailed({
                error: response?.error?.message || TECHNICAL_ERROR_MESSAGE,
              }),
              webCheckInErrorEvt({
                hotelCode: payload.hotelCode,
                error: `confirmation failed ${
                  response?.error?.errorCode || "unknown"
                }`,
              }),
            ]),

            takeUntil(action$.pipe(ofType(confirmCheckInCancel.type)))
          ))
      ))
  );
}
