import { ofType } from "redux-observable";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";
import { of } from "rxjs";

import { errorCodes } from "utils/errorCodes";
import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import {
  createHistoricBookingSummaries$,
  getHistoricBookingSummaries$,
} from "api/tretail/bookingHistory";
import {
  fetchStayHistory,
  fetchStayHistoryFulfilled,
  fetchStayHistoryFailed,
  fetchStayHistoryCancel,
} from "../stayHistory.slice";

export function fetchStayHistory$({ locale, startDate }) {
  return createHistoricBookingSummaries$({
    locale,
    startDate,
  }).pipe(
    switchMap(({ id: resultSetId }) =>
      getHistoricBookingSummaries$({ resultSetId, locale })
    ),

    catchError(({ response = {} }) => {
      const apiErrors = response?.apiErrors || [];
      if (
        apiErrors.map((e) => e.errorCode).includes(errorCodes.NO_RESULTS_CODE)
      ) {
        return of({ totalResults: 0 });
      }
      return of(response);
    })
  );
}

export default function fetchStayHistoryEpic(action$) {
  return action$.pipe(
    ofType(fetchStayHistory.type),
    switchMap(({ payload: { locale, startDate } }) => ajaxWithHealthCheck$({
        locale,
      }).pipe(
        switchMap(() => fetchStayHistory$({ locale, startDate }).pipe(
            map(fetchStayHistoryFulfilled),

            catchError(({ response = {} }) =>
              of(
                fetchStayHistoryFailed({
                  apiErrors: response?.apiErrors || [],
                  supplierErrors: response?.supplierErrors || [],
                })
              )
            ),

            takeUntil(action$.pipe(ofType(fetchStayHistoryCancel.type)))
          ))
      ))
  );
}
