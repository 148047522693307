import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useDispatchWithLocale } from "hooks";
import {
  dismissOccupancyError as clearOccupancyError,
  selectOccupancyError,
} from "store/searchResults";

export default function useCheckOccupancy() {
  const dispatchWithLocale = useDispatchWithLocale();
  const occupancyError = useSelector(selectOccupancyError);

  const dismissOccupancyError = () => dispatchWithLocale(clearOccupancyError);

  useEffect(() => () => {
      dismissOccupancyError();
    }, []);

  return {
    occupancyError,
    dismissOccupancyError,
  };
}
