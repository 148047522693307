import { ofType } from "redux-observable";
import {
  catchError,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import { of, iif } from "rxjs";
import get from "lodash/get";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { createUserProfile$ } from "api/tretail/profile";
import { validateEmployeeDetails$ } from "api/workday";
import { retrieveBooking } from "store/bookings/epics/fetchHistoricBooking";
import { fetchHistoricBookingFulfilled } from "store/bookings";
import {
  createProfile,
  createProfileFulfilled,
  createProfileFailed,
  createProfileCancel,
} from "store/profile/profile.slice";
import {
  setViewState,
  setRegistrationFormValues,
  REGISTER_VIEW_STATES,
} from "store/registration/registration.slice";
import { setSendCodeTo } from "store/signIn/signIn.slice";
import { selectFoundBooking } from "store/registration/registration.selectors";
import cleanPhoneNumber from "utils/cleanPhoneNumber";
import catchInternalServerError from "store/catchInternalServerError";

export default function createProfileEpic(action$, state$) {
  return action$.pipe(
    ofType(createProfile.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { locale, employeeMode = false, ...formValues },
        },
        state,
      ]) => ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() => iif(
              () => employeeMode,
              validateEmployeeDetails$(formValues),
              of(undefined)
            ).pipe(
              switchMap(() => {
                const foundBooking = selectFoundBooking(state);
                if (!foundBooking) {
                  return of(undefined);
                }

                const reservationId = get(foundBooking, [
                  "hotelProducts",
                  0,
                  "reservationId",
                ]);
                const hotelCode = get(foundBooking, [
                  "hotelProducts",
                  0,
                  "hotelCode",
                ]);
                const surname = get(foundBooking, [
                  "customers",
                  0,
                  "name",
                  "surname",
                ]);

                return retrieveBooking({
                  reservationId,
                  hotelCode,
                  surname,
                  locale,
                });
              }),

              switchMap((foundBooking) => {
                const { orsNameId, salesForceId, address, emailsFS, phones } =
                  get(foundBooking, ["customers", 0], {});

                const formValuesWithSalesForceIds = {
                  ...formValues,
                  address: {
                    country: {
                      code: formValues.countryCode,
                    },
                    salesForceId: get(address, [0, "salesForceId"]),
                  },
                  email: {
                    email: formValues.email,
                    salesForceId: get(emailsFS, [0, "salesForceId"]),
                  },
                  phoneNumber: {
                    number: cleanPhoneNumber(formValues.phoneNumber),
                    phoneType: "MOBILE",
                    salesForceId: get(phones, [0, "salesForceId"]),
                  },
                };

                return createUserProfile$({
                  formValues: formValuesWithSalesForceIds,
                  orsNameId,
                  salesForceId,
                  employeeMode,
                  locale,
                }).pipe(
                  mergeMap((profileFormValues) =>
                    [
                      foundBooking &&
                        fetchHistoricBookingFulfilled(foundBooking),
                      createProfileFulfilled(),
                      setSendCodeTo(profileFormValues),
                      setRegistrationFormValues(formValues),
                      setViewState(REGISTER_VIEW_STATES.SEND_CODE),
                    ].filter(Boolean)
                  ),
                  catchInternalServerError(),
                  catchError(({ response = {} }) =>
                    of(
                      createProfileFailed({
                        apiErrors: response.apiErrors || [],
                        supplierErrors: response.supplierErrors || [],
                      })
                    )
                  ),
                  takeUntil(action$.pipe(ofType(createProfileCancel.type)))
                );
              }),
              catchInternalServerError(),
              catchError(({ response = {} }) =>
                of(
                  createProfileFailed({
                    apiErrors: response.apiErrors || [],
                    supplierErrors: response.supplierErrors || [],
                    errors: response.error
                      ? [{ errorCode: response.error }]
                      : [],
                  })
                )
              )
            ))
        )
    )
  );
}
