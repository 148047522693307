import { useEffect, useState } from "react";
import { fromEvent, from, asyncScheduler } from "rxjs";
import { throttleTime, map, filter, take, switchMap } from "rxjs/operators";
import { useLocation } from "react-router-dom";

const visibleElement =
  ({ navigations, topAdjust = 0 }) =>
  () => from(navigations).pipe(
      map(({ hash }) => `#${hash}`),
      map((id) => {
        const rec = document.querySelector(id)?.getBoundingClientRect();
        return {
          id,
          rec,
        };
      }),
      filter(({ rec }) => {
        if (!rec) {
          return false;
        }

        // IF height is less than the viewHeight
        // THEN top and bottom >= 0 - entire block is in view
        if (rec.height <= window.innerHeight - topAdjust) {
          return rec.top >= 0 + topAdjust && rec.bottom >= 0 + topAdjust;
        }

        return rec.top >= 0 + topAdjust || rec.bottom >= window.innerHeight / 2;
      }),
      map(({ id }) => id.substring(1)),
      take(1)
    );

const THROTTLE_TIME = 100;

const cleanHash = (hash) => hash.replace(/^#/, "");

export default function useNavigation({ navigations, topAdjust = 0 }) {
  const location = useLocation();

  const [scrolledToSection, setScrolledToSection] = useState(
    cleanHash(location.hash)
  );

  useEffect(() => {
    const subscription$ = fromEvent(window, "scroll")
      .pipe(
        throttleTime(THROTTLE_TIME, asyncScheduler, {
          leading: true,
          trailing: true,
        }),
        switchMap(visibleElement({ navigations, topAdjust }))
      )
      .subscribe(setScrolledToSection);

    return () => {
      if (subscription$.unsubscribe) {
        subscription$.unsubscribe();
      }
    };
  }, [JSON.stringify(navigations), topAdjust]);

  const scrollToSection = (id, config = { behavior: "smooth" }) => {
    const element = id && document.querySelector(`#${cleanHash(id)}`);
    if (element) {
      window.scrollTo({
        top:
          element.getBoundingClientRect().top + window.pageYOffset - topAdjust,
        ...config,
      });
    }
  };

  // set section on load
  useEffect(() => {
    scrollToSection(scrolledToSection, { behavior: "auto" });
  }, []);

  return {
    scrolledToSection,
    scrollToSection,
  };
}
