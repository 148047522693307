import React from "react";
import { filter, flow, get, flatMap } from "lodash/fp";

import { useTranslation } from "hooks";
import Room from "./Room";

export default function AvailableRooms({
  searchResults = [],
  onSelectRoom,
  propertyUseGlobalNumber,
  tollFreeNumbers,
}) {
  const { t } = useTranslation();

  const numberOfBookableRooms = flow(
    flatMap(get("rooms")),
    filter(({ bookableOnline }) => bookableOnline),
    get("length")
  )(searchResults);

  return (
    <>
      {searchResults.map(({ name: accommodationType, rooms }) => (
        <ul key={accommodationType} className="search-results layout-list">
          <h2 className="ty-c1 accommodation-type-title">
            {t(accommodationType)}
          </h2>

          {rooms.map((availableRoom) => (
              <li key={availableRoom.name} className="search-result">
                <Room
                  accessibleFeaturesList={availableRoom.accessibleFeaturesList}
                  bathroomDescription={availableRoom.bathroom}
                  bedOptions={availableRoom.bedOptions}
                  bedTypes={availableRoom.bedTypes}
                  isBookableOnline={availableRoom.bookableOnline}
                  bookDirectData={availableRoom.bookDirectData}
                  propertyUseGlobalNumber={propertyUseGlobalNumber}
                  tollFreeNumbers={tollFreeNumbers}
                  extraBed={availableRoom.extraBed}
                  feesDisclaimer={availableRoom.feesDisclaimer}
                  floorPlanImagePath={availableRoom.floorPlanImage?.path}
                  images={availableRoom.images}
                  location={availableRoom.location}
                  lowestRate={availableRoom.lowestRate}
                  occupancy={availableRoom.occupancy}
                  onSelectRoom={onSelectRoom}
                  priceLabel={availableRoom.priceLabel}
                  roomName={availableRoom.name}
                  servicesAndAmenityGroups={
                    availableRoom.servicesAndAmenityGroups
                  }
                  size={availableRoom.size}
                  unitLongDescription={availableRoom.description}
                  view={availableRoom.view}
                  isBestRate={
                    numberOfBookableRooms > 1 && availableRoom.isBestRate
                  }
                />
              </li>
            ))}
        </ul>
      ))}
    </>
  );
}
