import { ofType } from "redux-observable";
import { concat, onErrorResumeNext } from "rxjs";
import { mergeMap, map, toArray } from "rxjs/operators";

import {
  deleteContact$,
  deleteAllCustomers$,
  removeAllPayments$,
} from "api/tretail/booking";
import { NEXT_BOOKING_PHASES } from "fixtures/constants";
import {
  removeContactCustomerPayment,
  removeContactCustomerPaymentFailed,
  removeContactCustomerPaymentFulfilled,
} from "../bookings.slice";

const phases = [
  NEXT_BOOKING_PHASES.ADD_CONTACT,
  NEXT_BOOKING_PHASES.ADD_CUSTOMERS,
  NEXT_BOOKING_PHASES.ADD_ALL_PAYMENT,
  NEXT_BOOKING_PHASES.VALIDATE_BOOKING,
  NEXT_BOOKING_PHASES.MAKE_RESERVATION,
];

export default function removeContactCustomerPaymentEpic(action$) {
  return action$.pipe(
    ofType(removeContactCustomerPayment.type),
    mergeMap(({ payload: { bookingId, locale, failedPhase = "" } }) => {
      const removeRequests = [];

      if (
        phases.indexOf(failedPhase) >=
        phases.indexOf(NEXT_BOOKING_PHASES.ADD_CONTACT)
      ) {
        removeRequests.push(
          deleteContact$({
            bookingId,
            locale,
          })
        );
      }
      if (
        phases.indexOf(failedPhase) >=
        phases.indexOf(NEXT_BOOKING_PHASES.ADD_CUSTOMERS)
      ) {
        removeRequests.push(
          deleteAllCustomers$({
            bookingId,
            locale,
          })
        );
      }
      if (
        phases.indexOf(failedPhase) >=
        phases.indexOf(NEXT_BOOKING_PHASES.ADD_ALL_PAYMENT)
      ) {
        removeRequests.push(
          removeAllPayments$({
            bookingId,
            locale,
          })
        );
      }

      return concat(onErrorResumeNext(...removeRequests)).pipe(
        toArray(),
        map((removeResponses) => removeResponses?.length === removeRequests.length
            ? removeContactCustomerPaymentFulfilled()
            : removeContactCustomerPaymentFailed())
      );
    })
  );
}
