import capatalize from "lodash/capitalize";
import pickBy from "lodash/fp/pickBy";
import head from "lodash/head";

import {
  otherPreferencesOptions,
  roomPreferences,
  sleepPreferences,
  yourInterestsOptions,
} from "fixtures/preferences";
import {
  MATTRESS,
  NEW_ADDRESS_ID,
  NEW_ADDRESS,
  NEW_EMAIL_ID,
  NEW_EMAIL,
  NEW_PHONE_NUMBER_ID,
  NEW_PHONE_NUMBER,
  OPTING_OPTIONS,
  PILLOW,
  ROOM,
  RANGE_TYPE,
  subscriptions,
  hiddenSubscriptions,
  SIGN_IN_STATES,
} from "fixtures/constants";
import {
  addSelection,
  isMattressPreference,
  isPillowPreference,
  isRoomPreference,
  mapToViewPreference,
  matchTitle,
  buildLocalSubscriptionKey,
} from "./profile.helpers";

export { getLoginEmail as selectLoginEmail } from "./profile.helpers";

export const selectUserState = ({ profile }) => {
  if (!profile?.data?.id) {
    return SIGN_IN_STATES.ANONYMOUS;
  }
  return profile.data?.dataRedacted
    ? SIGN_IN_STATES.PERSISTENT
    : SIGN_IN_STATES.FULLY_SIGNED_IN;
};

export const selectIsUserLoggedIn = ({ profile }) =>
  selectUserState({ profile }) > 0;

export const selectIsUserPersistent = ({ profile }) =>
  selectUserState({ profile }) === SIGN_IN_STATES.PERSISTENT;

export const selectIsUserFullySignedIn = ({ profile }) =>
  selectUserState({ profile }) === SIGN_IN_STATES.FULLY_SIGNED_IN;

export const selectProfile = ({ profile }) => profile.data;

export const selectProfileErrors = ({ profile }) => [
    ...profile.supplierErrors,
    ...profile.apiErrors,
    ...profile.errors,
  ].filter((error) => !error.field);

export const selectAllProfileErrors = ({ profile }) => [...profile.supplierErrors, ...profile.apiErrors, ...profile.errors];

export const selectRegistrationFieldErrors = ({ profile }) => {
  const fieldMap = {
    "address[0].country.code": "countryCode",
    "emails[0].email": "email",
    "name.firstName": "firstName",
    "name.surname": "surname",
    "phones[0].number": "phoneNumber",
  };
  return [...profile.supplierErrors, ...profile.apiErrors]
    .filter((error) => error.field)
    .reduce(
      (acc, { errorCode, ...error }) => ({
        ...acc,
        [fieldMap[error.field] || error.field]: errorCode,
      }),
      {}
    );
};

export const selectProfileError = (state) =>
  (selectProfileErrors(state) || [])[0];

export const selectName = ({ profile }) => profile?.data?.name || {};

export const selectSurname = ({ profile }) => profile?.data?.name?.surname;

export const selectProfileName = (t) => (state) => {
  const profile = selectProfile(state);
  return [
    t(profile?.name?.title),
    profile?.name?.firstName,
    profile?.name?.middleName,
    profile?.name?.surname,
  ]
    .filter(Boolean)
    .map(capatalize)
    .join(" ");
};

export const selectPhoneNumbers = ({ profile }) => {
  if (!profile.data?.phones) {
    return [];
  }
  const digitalProfileLogins =
    profile.data?.extension.digitalProfileLoginList.map((d) =>
      d.userName.replace(/^\+|\*/g, "")
    );
  return profile.data?.phones.map((p) => ({
    ...p,
    isLogin: digitalProfileLogins.includes(p.number?.replace(/\*/g, "")),
  }));
};

export const selectLoginPhoneNumber = (state) =>
  selectPhoneNumbers(state)?.find(({ isLogin }) => isLogin)?.number || "";

export const selectEmails = ({ profile }) =>
  profile.data?.emails?.map((a) => ({
    ...a,
    isLogin:
      profile.data?.extension?.digitalProfileLoginList?.some(
        ({ userName }) => userName === a.email
      ) || profile.data?.emails?.length === 1,
  })) || [];

export const selectPhones = ({ profile }) => profile.data?.phones || [];

export const selectAddresses = ({ profile }) =>
  profile.data?.address?.map(({ addressLines, ...rest }) => ({
    addressLines: addressLines
      .map((s) => s.trim())
      .filter(Boolean)
      .join(", "),
    ...rest,
  })) || [];

const selectCountryOfResidence = (profile) =>
  profile.data?.extension?.countryOfResidence || "";

const selectPreferredLanguage = (profile) =>
  profile.data?.extension?.preferredLanguage || "";

export const selectCountryAndLanguage = ({ profile }) => ({
  countryOfResidence: selectCountryOfResidence(profile),
  preferredLanguage: selectPreferredLanguage(profile),
});

export const selectMailSubscriptionList = (state) => {
  const mailSubscriptionList =
    state?.profile.data?.extension?.mailSubscriptionList || [];
  const globalSubscriptions = (
    state?.globalMailSubscriptions?.data || []
  ).filter(
    (subscription) => !hiddenSubscriptions.includes(subscription?.mailingList)
  );

  const getOptionByMailingList = (mailingList) =>
    mailSubscriptionList.find((option) => option?.mailingList === mailingList);

  const localSubscriptions = mailSubscriptionList
    .filter(
      ({ range, optInOption } = {}) =>
        range === RANGE_TYPE.LOCAL && optInOption === OPTING_OPTIONS.IN
    )
    .reduce(
      (acc, localSubscription) => ({
        ...acc,
        [buildLocalSubscriptionKey(localSubscription)]: {
          ...localSubscription,
          optInOption: true,
          text: localSubscription.mailingList,
        },
      }),
      {}
    );

  return (
    globalSubscriptions.reduce((acc, { mailingList, ...rest }) => {
      const persistedOptInOption =
        getOptionByMailingList(mailingList)?.optInOption;

      return {
        ...acc,
        [mailingList]: {
          optInOption: persistedOptInOption === OPTING_OPTIONS.IN,
          mailingList,
          text:
            subscriptions.find((sub) => sub.mailingList === mailingList)
              ?.text || mailingList,
          range: RANGE_TYPE.GLOBAL,
          ...rest,
        },
      };
    }, localSubscriptions) || {}
  );
};

export const selectGlobalMailSubscriptionList = (state) => pickBy(
    ({ range }) => range === RANGE_TYPE.GLOBAL,
    selectMailSubscriptionList(state)
  );

export const selectHasExplicitOptInGlobalMailSubscription = (state) => Object.values(selectGlobalMailSubscriptionList(state) || {}).some(
    (subscription) => subscription.optInOption
  );

export const selectExtensions = ({ profile }) => profile.data?.extension || {};

export const selectPropertyOwnedList = (state) =>
  selectExtensions(state).propertyOwnedList || [];

export const selectHasOwnedProperties = (state) =>
  selectPropertyOwnedList(state).length > 0 ||
  selectExtensions(state).propertyOwned;

const selectGuestPreferenceList = (state) =>
  selectExtensions(state)?.guestPreferenceList || [];

const selectPreference =
  (preferenceList) => (matchingString, matchingFunction) => (state) =>
    selectGuestPreferenceList(state).some(matchingFunction)
      ? mapToViewPreference(
          selectGuestPreferenceList(state).find(matchingFunction)
        )
      : head(preferenceList.find(matchTitle(matchingString)).options);

const getSleepPreference = selectPreference(sleepPreferences);

export const selectPillowPreferences = getSleepPreference(
  PILLOW,
  isPillowPreference
);

export const selectMattressPreferences = getSleepPreference(
  MATTRESS,
  isMattressPreference
);

export const selectSleepPreferences = (state) => ({
  [PILLOW]: selectPillowPreferences(state).value,
  [MATTRESS]: selectMattressPreferences(state).value,
});

export const selectRoomPreference = (state) =>
  selectPreference([roomPreferences])(ROOM, isRoomPreference)(state).value;

export const selectYourInterests = (state) =>
  addSelection(selectGuestPreferenceList(state));

export const selectUserTitle = ({ profile }) => profile.data?.name?.title || "";

export const selectEmail =
  (salesForceId) =>
  ({ profile }) =>
    salesForceId === NEW_EMAIL_ID
      ? NEW_EMAIL
      : selectEmails({ profile }).find(
          (email) => email.salesForceId === salesForceId
        ) || {};

export const selectPhone = (salesForceId) => (state) =>
  salesForceId === NEW_PHONE_NUMBER_ID
    ? NEW_PHONE_NUMBER
    : selectPhoneNumbers(state).find(
        (phone) => phone.salesForceId === salesForceId
      ) || {};

export const selectAddress =
  (salesForceId) =>
  ({ profile }) => {
    if (salesForceId === NEW_ADDRESS_ID) {
      return NEW_ADDRESS;
    }

    const address = profile.data?.address.find(
      (a) => a.salesForceId === salesForceId
    );

    if (address) {
      return {
        ...address,
        addressLines: address.addressLines
          .map((s) => s.trim())
          .filter(Boolean)
          .join(", "),
      };
    }
    return {};
  };

export const selectCompletePercentage = (state) => {
  const guestPreferenceList = selectGuestPreferenceList(state);
  const emailsPresent = selectEmails(state).length > 0;
  const phonesPresent = selectPhones(state).length > 0;
  const addressesPresent = selectAddresses(state).length > 0;
  const preferredLanguagePresent = !!selectPreferredLanguage(state.profile);
  const countryOfResidencePresent = !!selectCountryOfResidence(state.profile);
  const mailSubscriptionPresent =
    (state?.profile.data?.extension?.mailSubscriptionList || []).filter(
      ({ optInOption }) => optInOption === OPTING_OPTIONS.IN
    ).length > 0;
  const name = selectName(state);
  const allNamesPresent = !!(name.firstName && name.surname && name.title);

  const tenPercents = [
    emailsPresent,
    phonesPresent,
    allNamesPresent,
    addressesPresent,
    preferredLanguagePresent,
    countryOfResidencePresent,
    mailSubscriptionPresent,
  ]
    .filter(Boolean)
    .reduce((acc) => acc + 10, 0);

  const yourInterestsPresent =
    guestPreferenceList.filter(
      ({ masterCode, active }) =>
        active && yourInterestsOptions.includes(masterCode)
    ).length > 0;

  const otherPreferencesPresent =
    guestPreferenceList.filter(
      ({ masterCode, active }) =>
        active && otherPreferencesOptions.includes(masterCode)
    ).length > 0;

  const fifteenPercents = [yourInterestsPresent, otherPreferencesPresent]
    .filter(Boolean)
    .reduce((acc) => acc + 15, 0);

  return tenPercents + fifteenPercents;
};

export const selectVerifyYourEmailError = ({ profile }) => [
    ...profile.verifyYourEmailErrors.supplierErrors,
    ...profile.verifyYourEmailErrors.apiErrors,
  ][0];

export const selectSendFFEmailStatus = ({ profile }) =>
  profile?.sendFFEmailStatus;

export const selectEmployeeId = ({ profile }) =>
  profile?.data?.extension?.employeeId || "";
