import React, { useEffect } from "react";

export default function TripTease({
  searchResults,
  searchParams,
  propertyTripTeaseHotelKey,
  currencyCode,
}) {
  const adultsCount = searchParams.rooms.reduce((totalAdults, room) => totalAdults + room.adults, 0);
  const childrenCount = searchParams.rooms.reduce((totalChildren, room) => totalChildren + room.children, 0);
  const childrenAges = new Array(childrenCount).fill("7").join(","); // childrenAges not captured but is required for TripTease's price-check

  const allRooms = searchResults.map(({ rooms }) => rooms).flat();
  const [lowestPricedRoom] = allRooms.sort((a, b) => !a.lowestRate || !b.lowestRate
      ? 0
      : a.lowestRate.amount?.withoutDecimal -
          b.lowestRate.amount?.withoutDecimal);
  const directPrice = lowestPricedRoom?.lowestRate?.amount?.withoutDecimal;

  useEffect(() => {
    document.body.classList.add("has-triptease");
    return () => {
      document.body.classList.remove("has-triptease");
      const tripTeaseFrame = document.getElementById("tt_price-check");
      if (tripTeaseFrame) tripTeaseFrame.remove();
    };
  });

  return (
    <div
      className="price-fighter-widget"
      {...{
        "data-pf-autoopen": "on",
        "data-pf-hotelkey": propertyTripTeaseHotelKey,
        "data-pf-direct-price": directPrice,
        "data-pf-currency": currencyCode,
        "data-pf-checkin": searchParams.dates.checkIn,
        "data-pf-checkout": searchParams.dates.checkOut,
        "data-pf-adults": adultsCount,
        "data-pf-children": childrenCount,
        "data-pf-children-ages": childrenAges,
      }}
    />
  );
}
