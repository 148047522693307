import React, { useContext } from "react";
import { useSelector } from "react-redux";
import get from "lodash/fp/get";
import { transducer } from "rxjs-transducer";
import { from } from "rxjs";
import { map, distinct, mergeMap } from "rxjs/operators";

import { selectAllPropertyContent } from "store/propertyContent";
import { selectHasOwnedProperties, selectProfile } from "store/profile";
import { selectBookingByReservationId } from "store/bookings";
import { selectUpcomingTripsOnly } from "store/bookingHistory/bookingHistory.selectors";
import { completedRequestStates } from "store/selectors";
import {
  selectMemberDetails,
  selectHasMemberDetails,
} from "store/memberDetails";

import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { getInfoToRetrieveSoonestBooking } from "utils/datesHelpers";
import ProfileLayout from "Profile/ProfileLayout";
import { MediaContext } from "contexts/MediaContext";
import { useView } from "hooks";
import TopBar from "./TopBar";
import ExclusiveOffers from "./ExclusiveOffers";
import MemberDetails from "./MemberDetails";
import YourResidence from "./YourResidence";
import YourPreferences from "./YourPreferences";
import YourInterests from "./YourInterests";
import InvoiceRequest from "./InvoiceRequest";
import UpcomingTrips from "./UpcomingTrips";

function ProfileView() {
  useView("ProfileView");
  const { isMobileApp } = useContext(MediaContext);
  const hasMemberDetails = useSelector(selectHasMemberDetails);
  const memberDetails = useSelector(selectMemberDetails);
  const upcomingTrips = useSelector(selectUpcomingTripsOnly);
  const allPropertyContent = useSelector(selectAllPropertyContent);
  const hasOwnedProperties = useSelector(selectHasOwnedProperties);

  const { reservationId = "" } = getInfoToRetrieveSoonestBooking(upcomingTrips);
  const soonestBooking = useSelector(
    selectBookingByReservationId({ reservationId })
  );

  const selectHasContentForUpcomingTrips = (state) => [
      ...transducer(from(upcomingTrips))(
        map(get(["hotelProducts", "0", "hotelCode"])),
        distinct(),
        mergeMap((hotelCode) => [
          get(["propertyContent", "data", hotelCode])(state) ||
            completedRequestStates.includes(
              get(["propertyContent", "requestStates", hotelCode])(state)
            ),
          get(["accommodationsContent", "data", hotelCode])(state) ||
            completedRequestStates.includes(
              get(["accommodationsContent", "requestStates", hotelCode])(state)
            ),
          get(["offersContent", "data", hotelCode])(state) ||
            completedRequestStates.includes(
              get(["offersContent", "requestStates", hotelCode])(state)
            ),
        ])
      ),
    ].every(Boolean);

  // REQUIRED DATA FOR VIEW
  // profile
  // property content for all upcomgin trips
  const hasProfile = Boolean(useSelector(selectProfile));
  const hasContentForUpcomingTrips = useSelector(
    selectHasContentForUpcomingTrips
  );

  if (!hasProfile || !hasContentForUpcomingTrips) {
    return <BookingFlowLoadingIndicator opaque />;
  }

  return (
    <ProfileLayout>
      <div className="view--profile">
        <TopBar soonestBooking={soonestBooking} reservationId={reservationId} />

        {hasMemberDetails && <MemberDetails memberDetails={memberDetails} />}

        {!isMobileApp && <ExclusiveOffers />}

        {hasOwnedProperties && !isMobileApp && <YourResidence />}

        {upcomingTrips.length > 0 && !isMobileApp && (
          <UpcomingTrips
            upcomingTrips={upcomingTrips}
            allPropertyContent={allPropertyContent}
          />
        )}

        <YourPreferences />

        <YourInterests />

        {!isMobileApp && <InvoiceRequest />}
      </div>
    </ProfileLayout>
  );
}

export default ProfileView;
