import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { useTranslation, useDispatchWithLocale } from "hooks";
import { Button, Checkbox, TextInput } from "Profile/components";
import { selectProperties } from "store/properties";
import {
  selectName,
  selectLoginEmail,
  selectLoginPhoneNumber,
} from "store/profile";
import {
  requestInvoice,
  selectRequestInvoiceError,
  viewRequestInvoiceFormEvt,
} from "store/bookings";
import {
  selectPropertyTimeZone,
  fetchPropertyContent,
} from "store/propertyContent";
import {
  selectIsRequestFulfilled,
  selectIsRequestLoading,
} from "store/apiRequestStates";
import { isNotBlank } from "utils/isBlank";
import {
  validateInvoiceDate,
  formatFolioRequestTimeStamp,
  validateLast4CC,
} from "utils/utils";
import ErrorMessage from "../components/ErrorMessage";
import Typeahead from "./Typeahead";

export default function RequestInvoiceForm({ close }) {
  const { t } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const properties = useSelector(selectProperties);
  const name = useSelector(selectName);
  const email = useSelector(selectLoginEmail);
  const number = useSelector(selectLoginPhoneNumber);
  const error = useSelector(selectRequestInvoiceError);

  const { watch, register, control, handleSubmit, formState, errors, trigger } =
    useForm({
      mode: "onBlur",
      defaultValues: {
        hotelCode: "",
        checkInDate: "",
        reservationId: "",
        last4CC: "",
        confirmation: false,
      },
    });
  const { isValid } = formState;

  const isInvoiceRequestDone = useSelector(
    selectIsRequestFulfilled(requestInvoice.type)
  );
  const showLoadingIndicator = useSelector(
    selectIsRequestLoading(requestInvoice.type)
  );

  const showSuccess = isInvoiceRequestDone && !error;
  const hotelCode = watch("hotelCode");
  const timeZone = useSelector(selectPropertyTimeZone(hotelCode));

  const onSubmit = (formValues) =>
    dispatchWithLocale(
      requestInvoice({
        ...formValues,
        firstName: name?.firstName,
        lastName: name?.surname,
        email,
        number: number.startsWith("+") ? number : `+${number}`,
        timeStamp: formatFolioRequestTimeStamp({
          timeZone,
        }),
      })
    );

  useEffect(() => {
    dispatchWithLocale(fetchPropertyContent({ propertyCode: hotelCode }));
  }, [hotelCode]);

  useEffect(() => {
    dispatchWithLocale(viewRequestInvoiceFormEvt());
  }, []);

  return (
    <div className="view--profile-folio">
      {showLoadingIndicator && <BookingFlowLoadingIndicator />}
      {showSuccess && (
        <div className="container view--profile-folio--success">
          <h1>{t("Request an Invoice")}</h1>
          <p>{t("Your request has been submitted.")}</p>
          <Button onClick={close}>
            <span>{t("Close")}</span>
          </Button>
        </div>
      )}

      {!showSuccess && (
        <div className="container">
          <div className="view--profile-folio__header">
            <h1>{t("Request an Invoice")}</h1>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            {error && <ErrorMessage>{t(error)}</ErrorMessage>}

            <Controller
              control={control}
              name="hotelCode"
              rules={{ required: true }}
              render={({ ref: _ignoredRef, ...renderProps }) => (
                  <Typeahead
                    options={properties}
                    {...renderProps}
                    identifier="input-hotelCode"
                    label={t("Hotel Name")}
                    placeholder={t("Hotel Name")}
                    error={
                      Boolean(errors?.hotelCode) && t("Please select a hotel")
                    }
                    required
                  />
                )}
            />

            <TextInput
              identifier="checkInDate"
              name="checkInDate"
              label={t("Date of Arrival")}
              placeholder="MM/DD/YYYY"
              maxLength={10}
              required
              register={register({
                maxLength: 10,
                validate: {
                  match: (date) => validateInvoiceDate({ date, timeZone }),
                },
              })}
              error={errors?.checkInDate && t(errors.checkInDate.message)}
            />

            <TextInput
              identifier="reservationId"
              name="reservationId"
              label={t("Confirmation Number")}
              placeholder="12345678"
              register={register({ required: true, validate: { isNotBlank } })}
              error={
                errors?.reservationId &&
                t("Please enter your reservation number")
              }
              required
            />

            <TextInput
              identifier="last4CC"
              name="last4CC"
              label={t("Card Number")}
              placeholder={t("last 4 digits")}
              register={register({
                validate: {
                  match: validateLast4CC,
                },
              })}
              error={errors?.last4CC && t(errors.last4CC.message)}
              required
              maxLength={4}
            />

            <Checkbox
              identifier="input-confirmation"
              name="confirmation"
              register={register({ required: true })}
              onChange={() => trigger()}
            >
              {t(
                "I confirm that I am the guest, another individual authorized and responsible for the requested reservation and payment details, or I have otherwise been authorized to update this reservation."
              )}
            </Checkbox>

            <div className="view--profile-folio__actions">
              <Button type="submit" disabled={!isValid}>
                {t("Request Invoice")}
              </Button>
            </div>
          </form>
        </div>
      )}

      <button className="btn-close" type="button" onClick={close}>
        <span>{t("Back")}</span>
      </button>
    </div>
  );
}
