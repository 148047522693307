/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import { isMobileDevice, getCreditCardName } from "utils";
import { from, interval } from "rxjs";
import { switchMap, filter, take } from "rxjs/operators";
import { differenceInCalendarDays } from "date-fns";
import { subscriptions, hiddenSubscriptions } from "fixtures/constants";
import { getFsInternal } from "api/analytics";
import get from "lodash/fp/get";
import flow from "lodash/fp/flow";
import parseInt from "lodash/parseInt";
import parseISO from "date-fns/fp/parseISO";
import addDays from "date-fns/fp/addDays";
import subDays from "date-fns/fp/subDays";
import differenceInDays from "date-fns/fp/differenceInDays";
import format from "date-fns/fp/format";
import isAfter from "date-fns/fp/isAfter";
import queryString from "query-string";
import startOfDay from "date-fns/fp/startOfDay";
import exposeBookingInProgressData from "utils/exposeBookingInProgessData";
import { createHash } from "crypto";

import env from "config/env";
import { getDecodedFSSecurityMXValue } from "utils/FSSecurityMX";

const {
  EMPLOYEE_RATES,
  ENABLE_ANALYTICS,
  ENABLE_ANALYTICS_LOGGING,
  TEALIUM_SCRIPT_PATH,
} = env;

export function log(...args) {
  if (ENABLE_ANALYTICS_LOGGING) {
    console.log("[ANALYTICS]", ...args);
  }
}

const CHECK_PERIOD = 100;
const CORP_PACKAGE_CODE = "corp";
const GROUP_PACKAGE_CODE = "group";

const ppInternalRegex = /fourseasons\.com$/;
const employeeTravelRegex = /\/employee-travel\//;
const employeeRegisterRegex = /\/employee-travel\/register/;

function addTealiumScriptTag({ scriptPath }) {
  const scriptTag = document.createElement("script");
  scriptTag.src = scriptPath;
  scriptTag.type = "text/javascript";
  scriptTag.async = true;
  document.body.appendChild(scriptTag);
  return scriptTag;
}

function getHashedValue(value) {
  return createHash("sha256").update(value).digest("hex");
}

export function getRoomFindingMethod(prevState, nextState) {
  let { roomFindingMethod } = queryString.parse(window.location.search || "");
  roomFindingMethod =
    roomFindingMethod ||
    prevState?.router?.location?.analyticsData?.roomFindingMethod ||
    nextState?.router?.location?.analyticsData?.roomFindingMethod ||
    nextState?.app?.roomFindingMethod ||
    prevState?.app?.roomFindingMethod;
  if (!roomFindingMethod || !roomFindingMethod.length) {
    roomFindingMethod = [];
  }
  return Array.isArray(roomFindingMethod)
    ? roomFindingMethod
    : [roomFindingMethod];
}

export function getAvailCheckTemplate(prevState, nextState) {
  let { availCheckTemplate } = queryString.parse(window.location.search || "");

  availCheckTemplate =
    availCheckTemplate ||
    prevState?.router?.location?.analyticsData?.availCheckTemplate ||
    nextState?.router?.location?.analyticsData?.availCheckTemplate ||
    nextState?.app?.availCheckTemplate ||
    prevState?.app?.availCheckTemplate;

  availCheckTemplate = availCheckTemplate || "reservations.fourseasons.com";
  return Array.isArray(availCheckTemplate)
    ? availCheckTemplate
    : [availCheckTemplate];
}

export function userIsEmployee(nextState) {
  return nextState.employeeProfile?.data?.workdayId;
}

export function userIsPreferredPartner(state) {
  return (
    (state.router.location.pathname.includes("/plan-your-stay") &&
      state.router.location.query.ppMode) ||
    (state.preferredPartnerDetails?.data?.success === "true" &&
      [
        "/choose-your-room",
        "/confirm-your-stay",
        "/personalize-your-stay",
      ].some((str) => state.router.location.pathname.includes(str)))
  );
}

export function isInRbf() {
  return [
    "/plan-your-stay",
    "/choose-your-room",
    "/confirm-your-stay",
    "/personalize-your-stay",
  ].some((str) => window.location.href.includes(str));
}

export function isRouteToSkipAppStart() {
  return [
    "/profile",
    "/sign-in",
    "/register",
    "/find-reservations",
    "/upcoming-trip",
    "/check-in",
  ].some((str) => window.location.href.includes(str));
}

export function isInWebCheckIn() {
  return window.location.href.includes("/check-in");
}

export function isOnStepOne() {
  return window.location.href.includes("/plan-your-stay");
}

export function isInProfile() {
  return ["/profile", "/register", "/sign-in", "/employee-travel/signin"].some(
    (str) => window.location.href.includes(str)
  );
}

export function amountInUsd(amt, currencyCode, nextState) {
  try {
    if (currencyCode === "USD") {
      return parseFloat(amt.toFixed(2));
    }
    const exgRate = nextState.exchangeRates.data.find(
      (rate) => rate.code === currencyCode
    )?.rate;

    return parseFloat((amt / exgRate).toFixed(2));
  } catch {
    return false;
  }
}

export function getSerialId() {
  if (!isInRbf()) return "";

  const dateStr = new Date().valueOf().toString();
  const rndm = Math.floor(Math.random() * 100000 + 1).toString();
  return dateStr + rndm;
}

export function getSiteTemplate() {
  if (isInRbf()) {
    return "BE 2021";
  }
  if (isInProfile()) {
    return "PROFILES 2021";
  }
  return "";
}

export function getDefaultData(language = "en") {
  return {
    aem_url: "",
    page_language: language,
    serial_id: getSerialId(),
    site_prefix: isMobileDevice() ? "fsmob" : "fs",
    site_template: getSiteTemplate(),
    user_id: "",
    user_type: "visitor",
  };
}

export function getAccount(nextState) {
  const account = [];
  if (
    process.env.NODE_ENV === "production" &&
    window.location.href.includes("reservations.fourseasons.com")
  ) {
    const internal =
      (nextState.profile?.data?.id &&
        nextState.employeeProfile?.data?.workdayId) ||
      window.location.pathname.match(employeeTravelRegex);
    account.push(internal ? "fshglobalinternal" : "fshglobal");
    if (userIsPreferredPartner(nextState)) {
      account.push("fshppexternal");
    }
  } else {
    account.push("fshglobaldev");
  }
  return {
    aa_account: account.join(","),
  };
}

function getBookingInProgressData(hotelCode = "", nextState) {
  const { resultSetId } = nextState.searchResults;
  const propertyHotelCode =
    hotelCode ||
    nextState.router.location.query.hotelCode ||
    (nextState.searchResults.data[resultSetId] || {}).hotelOptions?.[0]
      .hotelCode;
  const propertyData = nextState.propertyContent.data[propertyHotelCode];

  const employeeMode = userIsEmployee(nextState);
  const ppMode = userIsPreferredPartner(nextState);

  return {
    propertyHotelCode,
    city: propertyData?.city,
    employeeMode,
    ppMode,
  };
}

export function getPropertyData(hotelCode = "", nextState) {
  const { resultSetId } = nextState.searchResults;
  const propertyHotelCode =
    hotelCode ||
    nextState.router.location.query.hotelCode ||
    (nextState.searchResults.data[resultSetId] || {}).hotelOptions?.[0]
      .hotelCode;
  const propertyData = nextState.propertyContent.data[propertyHotelCode];

  const isInUpcomingTrip = window.location.href.includes("/upcoming-trip");

  if (!propertyData || (!isInRbf() && !isInWebCheckIn() && !isInUpcomingTrip)) {
    return {};
  }

  return {
    property_name: propertyData?.analyticsContent?.channel,
    property_code: propertyData?.analyticsContent?.propertyCode,
    property_region: propertyData?.analyticsContent?.region,
    property_subregion: propertyData?.analyticsContent?.subRegion,
    property_ows_code: propertyHotelCode,
  };
}

export function getUserData(nextState) {
  return {
    ...(nextState.profile?.data?.id
      ? {
          user_id: nextState.profile.data.id,
          user_type: nextState.employeeProfile?.data?.workdayId
            ? "employee"
            : "member",
        }
      : window.location.pathname.match(employeeTravelRegex)
      ? { user_type: "employee" }
      : {}),
    ...(userIsPreferredPartner(nextState)
      ? nextState.preferredPartnerDetails?.data?.agentEmail
        ? {
            user_type: nextState.preferredPartnerDetails.data.agentEmail.match(
              ppInternalRegex
            )
              ? "pp internal"
              : "preferred partner",
            user_partner_name:
              nextState.preferredPartnerDetails.data.agentEmail,
          }
        : { user_type: "preferred partner" }
      : {}),
  };
}

export function getUpsellBookedData(nextState) {
  const currentBookingId = nextState.bookings.bookingInProgressId;
  const currentBooking =
    nextState.bookings.bookingsInProgress[currentBookingId];
  const preUpgradeBooking =
    nextState.bookings.preupgradedBooking[currentBookingId];
  const preUpgradeRoomAdrs = preUpgradeBooking.hotelProducts.map(
    (product) =>
      product.roomRate?.averageNightlyPriceExcludingTaxesFees?.cash
        ?.usdAmount || 0
  );
  const currentRoomAdrs = currentBooking.hotelProducts.map(
    (product) =>
      product.roomRate?.averageNightlyPriceExcludingTaxesFees?.cash
        ?.usdAmount || 0
  );
  const roomLiftArray = currentRoomAdrs.map((newRoomAdr, index) => {
    const adrDiff = newRoomAdr - preUpgradeRoomAdrs[index];
    return adrDiff?.toFixed(2);
  });

  return {
    room_lift_array: roomLiftArray,
  };
}

function getRoomAndPackageArrays({
  numberOfRooms,
  accommodationsContent = { bookableAccommodations: [] },
  offersContent = { bookableOffers: [] },
  hotelOptions = { roomTypes: [], roomRates: [] },
  promoCode = "",
  roomSelections = [],
  selectedPackageOrsCodes = [],
}) {
  const room_code_array = Array(numberOfRooms)
    .fill("")
    .map((_, i) => roomSelections[i] || "");

  const room_name_array = room_code_array.map((roomCode) => {
    const nameFromAccommodations =
      accommodationsContent.bookableAccommodations
        .flatMap((a) => a.sleepingArrangements)
        .find(({ owsCode }) => owsCode === roomCode)?.title || "";

    const nameFromHotelOptions =
      hotelOptions.roomTypes.find(({ roomTypeId }) => roomTypeId === roomCode)
        ?.name || "";

    return nameFromHotelOptions || nameFromAccommodations;
  });

  const room_package_code_array = Array(numberOfRooms)
    .fill("")
    .map((_, i) => selectedPackageOrsCodes[i] || "");

  const room_package_ows_code_array = room_package_code_array.map(
    (selectedPackageOrsCode) => {
      if (promoCode) {
        return promoCode;
      }

      const codeFromOffersContent =
        offersContent.bookableOffers.find(
          ({ orsCode }) => orsCode === selectedPackageOrsCode
        )?.owsCode || "";

      const codeFromHotelOptions =
        hotelOptions.roomRates.find(
          ({ ratePlanCode }) => ratePlanCode === selectedPackageOrsCode
        )?.ratePlanCode || "";

      return selectedPackageOrsCode
        ? codeFromHotelOptions || codeFromOffersContent
        : "";
    }
  );

  const room_package_name_array = room_package_code_array.map(
    (selectedPackageOrsCode, index) => {
      const foundEmployeeRate = EMPLOYEE_RATES?.rateDetails?.find(
        (rateCodeDetails) =>
          rateCodeDetails.rateCodes.includes(
            selectedPackageOrsCode.toUpperCase()
          )
      );
      if (foundEmployeeRate) {
        return foundEmployeeRate.title;
      }
      const nameFromSelectedOrsCode =
        offersContent.bookableOffers.find(
          ({ orsCode }) => orsCode === selectedPackageOrsCode
        )?.title || "";

      const nameFromHotelOptions =
        hotelOptions.roomRates.find(
          ({ ratePlanCode }) =>
            ratePlanCode === room_package_ows_code_array[index]
        )?.ratePlanTitle || "";

      return nameFromHotelOptions || nameFromSelectedOrsCode;
    }
  );

  return {
    room_code_array,
    room_name_array,
    room_package_name_array,
    room_package_code_array,
    room_package_ows_code_array,
  };
}

export function getBookingData(data, prevState, nextState) {
  if (
    data.proceed === true &&
    (data.event_name === "purchase" || data.event_name === "confirm_stay")
  ) {
    const currentBookingParams =
      nextState.bookings.bookingInProgressSearchParams;
    const promoCode = currentBookingParams?.promoCode;
    const ppMode = userIsPreferredPartner(nextState);
    const employeeMode = !!nextState.employeeProfile?.data?.workdayId;
    const promoPackageCode = promoCode
      ? employeeMode
        ? promoCode.toLowerCase()
        : promoCode.match(/^[a-zA-Z]+$/)
        ? CORP_PACKAGE_CODE
        : GROUP_PACKAGE_CODE
      : undefined;
    const isRegularPromoCode = !!promoCode && !employeeMode && !ppMode;
    const currentBookingId = nextState.bookings.bookingInProgressId;
    const currentBooking =
      nextState.bookings.bookingsInProgress[currentBookingId];
    const reservationId = Object.entries(
      nextState.bookings.byReservationId
    ).find(
      ([, reservation]) => reservation.bookingId === currentBookingId
    )?.[0];
    const roomRateAry = currentBooking?.hotelProducts?.map((product) =>
      product.roomRate?.averageNightlyPriceExcludingTaxesFees?.cash?.usdAmount?.toFixed(
        2
      )
    );
    const roomNightsAry = Array(currentBookingParams?.rooms?.length ?? 0)
      .fill(
        differenceInCalendarDays(
          new Date(currentBookingParams?.dates.checkOut ?? Date()),
          new Date(currentBookingParams?.dates.checkIn ?? Date())
        )
      )
      .map((n) => n.toString());
    const roomArrivalAry = currentBooking?.hotelProducts?.map(
      (product) => product.checkInDate
    );
    const roomDepartureAry = currentBooking?.hotelProducts?.map(
      (product) => product.checkOutDate
    );
    const bookingTaxes = currentBooking?.hotelProducts?.reduce(
      (acc, product) =>
        (product.price.prepay.taxesFeesChargesTotal?.cash?.usdAmount || 0) +
        acc,
      0
    );
    const { property_code } = getPropertyData(
      currentBookingParams.hotelCode,
      nextState
    );
    const previousLocation =
      window.document.referrer ||
      nextState?.router?.location?.analyticsData?.from;
    const accommodationsContent =
      nextState.accommodationsContent.data[currentBookingParams.hotelCode];
    const offersContent =
      nextState.offersContent.data[currentBookingParams.hotelCode];
    const roomSelections = (currentBooking?.hotelProducts || []).map(
      get(["roomTypes", 0, "roomTypeId"])
    );
    const selectedPackageOrsCodes = (currentBooking?.hotelProducts || []).map(
      (product) =>
        promoPackageCode ||
        (ppMode &&
        product.roomRate?.ratePlanCode &&
        product.roomRate.ratePlanCode.startsWith("PP")
          ? product.roomRate.ratePlanCode
          : nextState?.offersContent?.data[
              currentBookingParams?.hotelCode
            ]?.bookableOffers?.find(
              (offer) => offer.owsCode === product.roomRate.ratePlanCode
            )?.orsCode) ||
        ""
    );

    const { resultSetId } = nextState.searchResults;
    const hotelOptions = nextState.searchResults.data[
      resultSetId
    ]?.hotelOptions.find(
      (hotelOption) => hotelOption.hotelCode === currentBookingParams.hotelCode
    );

    const {
      room_code_array,
      room_name_array,
      room_package_name_array,
      room_package_code_array,
      room_package_ows_code_array,
    } = getRoomAndPackageArrays({
      numberOfRooms: (currentBooking?.hotelProducts || []).length,
      accommodationsContent,
      offersContent,
      hotelOptions,
      promoCode,
      roomSelections,
      selectedPackageOrsCodes,
    });
    const roomFindingMethod = getRoomFindingMethod(prevState, nextState);

    return {
      booking_currency: "USD",
      room_nights_array: roomNightsAry,
      room_property_array: Array(currentBookingParams.rooms.length).fill(
        property_code
      ),
      room_code_array,
      room_name_array,
      room_rate_array: roomRateAry,
      room_package_code_array,
      room_package_ows_code_array,
      room_package_name_array,
      room_adults_array: currentBookingParams.rooms.map((room) =>
        String(room.adults)
      ),
      room_children_array: currentBookingParams.rooms.map((room) =>
        String(room.children)
      ),
      promocode: isRegularPromoCode ? "Yes" : "No",
      ...(data.event_name === "purchase" && {
        booking_id: reservationId || currentBookingId,
        booking_taxes: bookingTaxes.toFixed(2),
        booking_fees: "",
        room_arrival_array: roomArrivalAry,
        room_departure_array: roomDepartureAry,
        ...(nextState.bookings.upgradedRooms.length > 0 && {
          event_name: "purchase,upsell_booked",
          ...getUpsellBookedData(nextState),
        }),
      }),
      ...(data.event_name === "confirm_stay" && {
        booking_arrival_array: roomArrivalAry,
        booking_departure_array: roomDepartureAry,
        room_finding_method_array: roomFindingMethod.length
          ? roomFindingMethod
          : [previousLocation],
        avail_check_template: getAvailCheckTemplate(prevState, nextState),
      }),
    };
  }

  return {};
}

export const hasMatchingBooking = (nextState, id) => !!nextState?.bookingHistory?.data?.bookingSummaries?.find(
    ({ hotelProducts }) =>
      hotelProducts?.find(({ reservationId }) => reservationId === id)
  );

export const getBookingRole = (customers, prevState) => {
  if (customers?.find((customer) => customer?.contactType === "BOOK_FOR")) {
    return "someone else";
  }
  if (customers?.find((customer) => customer?.contactType === "TRAVEL_AGENT")) {
    if (userIsPreferredPartner(prevState)) {
      return "partner";
    }
    return "agent";
  }
  return "myself";
};

export function getUpsellData(data, prevState, nextState) {
  if (data.event_name !== "upsell_accept") return {};

  const currentBookingId = nextState.bookings.bookingInProgressId;

  const preUpsellBooking =
    prevState.bookings.bookingsInProgress[currentBookingId];

  const postUpsellBooking =
    nextState.bookings.bookingsInProgress[currentBookingId];

  const promoCode = preUpsellBooking?.searchParams?.promoCode;

  const prevStateRoomAdrs = preUpsellBooking.hotelProducts.map(
    (product) =>
      product.roomRate?.averageNightlyPriceExcludingTaxesFees?.cash
        ?.usdAmount || 0
  );

  const nextStateRoomAdrs = postUpsellBooking.hotelProducts.map(
    (product) =>
      product.roomRate?.averageNightlyPriceExcludingTaxesFees?.cash
        ?.usdAmount || 0
  );

  const roomLiftArray = nextStateRoomAdrs.flatMap((newRoomAdr, index) => {
    const adrDiff = newRoomAdr - prevStateRoomAdrs[index];
    if (adrDiff > 0) {
      return [(0).toFixed(2), adrDiff.toFixed(2)];
    }
    return [];
  });

  const upsoldRoomPairsAry = preUpsellBooking.hotelProducts.flatMap(
    (product, i) =>
      product.roomTypes[0].roomTypeId !==
      postUpsellBooking.hotelProducts[i].roomTypes[0].roomTypeId
        ? [product, postUpsellBooking.hotelProducts[i]]
        : []
  );

  const roomCodeArray = upsoldRoomPairsAry.map(
    (product) => product.roomTypes[0].roomTypeId
  );

  const roomFindingMethod = getRoomFindingMethod(prevState, nextState);

  let roomFindingMethodArray =
    postUpsellBooking.hotelProducts.length > 1
      ? Array(roomLiftArray.length).fill("multiroom")
      : Array(roomLiftArray.length).fill("singleroom");

  roomFindingMethodArray = [...roomFindingMethodArray, ...roomFindingMethod];

  const roomNameArray = upsoldRoomPairsAry.map(
    (product) => product.roomTypes[0].name
  );

  const roomNightsArray = upsoldRoomPairsAry.map((product) =>
    product.duration.toString()
  );

  const roomPackageCodeArray = upsoldRoomPairsAry.map(
    (product) => product.roomRate.ratePlanCode
  );

  const roomPackageNameArray = upsoldRoomPairsAry.map(
    (product) =>
      (promoCode
        ? product.roomRate?.ratePlanCode
        : product.roomRate?.ratePlanTitle) || ""
  );

  const roomPropertyArray = upsoldRoomPairsAry.map((product) => {
    const { property_code } = getPropertyData(product.hotelCode, nextState);
    return property_code;
  });

  const roomRateArray = upsoldRoomPairsAry.map(
    (product) =>
      product.roomRate?.averageNightlyPriceExcludingTaxesFees?.cash?.usdAmount
  );

  return {
    room_lift_array: roomLiftArray,
    room_code_array: roomCodeArray,
    room_finding_method_array: roomFindingMethodArray,
    room_name_array: roomNameArray,
    room_nights_array: roomNightsArray,
    room_package_code_array: roomPackageCodeArray,
    room_package_name_array: roomPackageNameArray,
    room_property_array: roomPropertyArray,
    room_rate_array: roomRateArray,
  };
}

export function getDefaultPropertyName() {
  const pathMatch = window.location.pathname
    .replace(/^\/\w{2}(_\w+)?(\/|$)/, "/")
    .match(/^\/([^?#]+)/);
  const modifiedUrl = (pathMatch ? pathMatch[1] : "").replace("/", ":");

  const colonIndex = modifiedUrl.indexOf(":");
  return colonIndex !== -1 ? modifiedUrl.substring(0, colonIndex) : modifiedUrl;
}

export function getInitialPageData() {
  return {
    page_section: "booking engine",
    page_type: "generic",
    page_url: window.location.href,
    property_code: "",
    property_name: getDefaultPropertyName(),
    property_region: "corporate",
    ...(window.location.pathname.match(employeeRegisterRegex)
      ? { form_name: "Employee Register", event_name: "form_view" }
      : {}),
  };
}

export function getAvailCheckData(
  prevState,
  nextState,
  { roomCodes = [], selectedPackageOrsCodes = [] }
) {
  const hotelSearchResultSetsResponse =
    nextState.searchResults?.createHotelSearchResponse?.hotelSearchResultSets ||
    [];

  const { resultSetId } = nextState.searchResults;
  const hotelCode =
    nextState.router.location.query.hotelCode ||
    (nextState.searchResults.data[resultSetId] || {})?.hotelOptions?.[0]
      .hotelCode;

  const accommodationsContent = nextState.accommodationsContent.data[hotelCode];
  const offersContent = nextState.offersContent.data[hotelCode];

  const numberOfRooms = (
    nextState.router.location.search.match(/adults=/g) || []
  ).length;

  const hotelOptions = (
    nextState.searchResults.data[resultSetId] || {}
  )?.hotelOptions?.find((hotelOption) => hotelOption.hotelCode === hotelCode);

  const { query } = nextState.router.location;

  const searchStr = nextState?.router?.location?.search || "";

  const { property_code } = getPropertyData(query.hotelCode, nextState);

  const { promoCode } = query;
  const ppMode = userIsPreferredPartner(nextState);
  const employeeMode = !!nextState.employeeProfile?.data?.workdayId;
  const promoPackageCode = promoCode
    ? employeeMode
      ? promoCode.toLowerCase()
      : promoCode.match(/^[a-zA-Z]+$/)
      ? CORP_PACKAGE_CODE
      : GROUP_PACKAGE_CODE
    : undefined;
  const isRegularPromoCode = !!promoCode && !employeeMode && !ppMode;

  const usablePackageOrsCodes = (selectedPackageOrsCodes || []).map(
    (room, index) =>
      promoPackageCode ||
      (ppMode &&
      room.roomRate?.ratePlanCode &&
      room.roomRate.ratePlanCode.startsWith("PP")
        ? room.roomRate.ratePlanCode
        : selectedPackageOrsCodes[index]) ||
      ""
  );

  const {
    room_code_array,
    room_name_array,
    room_package_name_array,
    room_package_code_array,
    room_package_ows_code_array,
  } = getRoomAndPackageArrays({
    numberOfRooms,
    accommodationsContent,
    offersContent,
    hotelOptions,
    promoCode,
    roomSelections: [...roomCodes].reverse(),
    selectedPackageOrsCodes: usablePackageOrsCodes,
  });

  const flexDatesWindow = parseInt(query.flexDatesWindow || "0");

  const adjustDateByNumberOfDays = (numberOfDays) =>
    flow(
      parseISO,
      subDays(flexDatesWindow),
      addDays(numberOfDays),
      format("yyyy-MM-dd")
    );

  const hotelSearchResultSets = [...Array(1 + 2 * flexDatesWindow)]
    .map((_, index) => ({
      checkinDate: adjustDateByNumberOfDays(index)(query.checkIn),
      checkoutDate: adjustDateByNumberOfDays(index)(query.checkOut),
      resultSetId: "N/A",
    }))
    .filter(({ checkinDate }) =>
      isAfter(subDays(1)(new Date()))(parseISO(checkinDate))
    )
    .map((hotelSearchResultSet) => ({
      ...hotelSearchResultSet,
      resultSetId:
        hotelSearchResultSetsResponse.find(
          ({ checkInDate }) => checkInDate === hotelSearchResultSet.checkInDate
        )?.resultSetId || "N/A",
    }));

  const splitIndex = hotelSearchResultSets.findIndex(
    ({ checkinDate }) => checkinDate === query.checkIn
  );
  const futureFlexDates =
    hotelSearchResultSets.length > 1
      ? hotelSearchResultSets.slice(splitIndex + 1)
      : [];
  const pastFlexDates =
    hotelSearchResultSets.length > 1
      ? hotelSearchResultSets.slice(0, splitIndex)
      : [];
  const flexDates = [
    hotelSearchResultSets[splitIndex],
    ...futureFlexDates,
    ...pastFlexDates,
  ].filter(Boolean);

  const prevUrl =
    window.document.referrer ||
    prevState?.router?.location?.analyticsData?.from;

  const roomFindingMethod = getRoomFindingMethod(prevState, nextState);

  return {
    booking_arrival_date: flexDates.map((fd) => fd.checkinDate),
    booking_departure_date: flexDates.map((fd) => fd.checkoutDate),
    booking_availability: flexDates.map((fd) =>
      fd.resultSetId === "N/A" ? "NO_AVAILABILITY" : "available"
    ),
    booking_number_rooms: String(numberOfRooms),
    booking_discount_code: query.promoCode,
    flex_count: flexDatesWindow.toString(),
    room_nights_array: Array(numberOfRooms)
      .fill(
        differenceInCalendarDays(
          new Date(query.checkOut),
          new Date(query.checkIn)
        )
      )
      .map((n) => n.toString()),
    room_adults_array: (searchStr.match(/adults=[0-9]+/g) || []).map(
      (adultsQueryParam) => adultsQueryParam.replace("adults=", "")
    ),
    room_children_array: (searchStr.match(/children=[0-9]+/g) || []).map(
      (childrenQueryParam) => childrenQueryParam.replace("children=", "")
    ),
    room_property_array: Array(numberOfRooms).fill(property_code),
    room_finding_method_array: roomFindingMethod.length
      ? roomFindingMethod
      : [prevUrl],
    room_code_array,
    room_name_array,
    room_package_name_array,
    room_package_code_array,
    room_package_ows_code_array,
    avail_check_template: getAvailCheckTemplate(prevState, nextState),
    promocode: isRegularPromoCode ? "Yes" : "No",
  };
}

export const getLocationChangeData = (pathname) => {
  if (pathname.includes("/confirm-your-stay")) {
    return {
      event_name: "confirm_stay",
      page_type: "checkout",
    };
  }
  if (pathname.includes("/personalize-your-stay")) {
    return {
      event_name: "purchase",
    };
  }
  if (pathname.includes("/plan-your-stay")) {
    return {
      event_name: "plan_stay",
    };
  }

  return {};
};

export const getPurchaseData = (prevState, nextState) => {
  if (nextState.router.location.pathname === "/personalize-your-stay") {
    const currentBookingId = nextState.bookings.bookingInProgressId;
    const currentBooking =
      nextState.bookings.bookingsInProgress[currentBookingId];

    const roomFindingMethod = getRoomFindingMethod(prevState, nextState);
    const previousLocation =
      window.document.referrer ||
      prevState?.router?.location?.analyticsData?.from;
    const numberOfRooms = currentBooking?.hotelProducts?.length || 0;
    const hasUpsells = nextState.bookings.upgradedRooms.length > 0;

    let room_finding_method_array = [];
    if (roomFindingMethod.length && hasUpsells) {
      room_finding_method_array = [
        ...roomFindingMethod,
        ...Array(numberOfRooms).fill("multiroom"),
      ];
    } else if (roomFindingMethod.length) {
      room_finding_method_array = roomFindingMethod;
    } else {
      room_finding_method_array = Array(numberOfRooms).fill(
        hasUpsells ? "multiroom" : previousLocation
      );
    }
    const booking_role = getBookingRole(currentBooking?.customers, prevState);
    const pp_mode = booking_role === "partner";
    const employeeMode = !!nextState.employeeProfile?.data?.workdayId;
    const hashedGuestEmail = getHashedValue(
      currentBooking?.contactDetails?.email
    );
    let hashedBookerEmail = "";
    if (booking_role === "someone else") {
      const booker = currentBooking?.customers?.find((customer) => customer?.contactType === "BOOK_FOR");
      if (Array.isArray(booker?.emails)) {
        hashedBookerEmail = getHashedValue(booker?.emails[0]?.email);
      } else if (booker?.email) {
        hashedBookerEmail = getHashedValue(booker?.email);
      }
    }

    return {
      booking_role,
      guest_email_hashed: !employeeMode && !pp_mode ? hashedGuestEmail : "",
      booker_email_hashed: !employeeMode && !pp_mode ? hashedBookerEmail : "",
      booking_payment_method: getCreditCardName(
        currentBooking?.hotelProducts?.[0].guarantees?.[0].cardCode
      ),
      room_finding_method_array,
    };
  }

  return {};
};

export const getProfileData = (nextState, subscriptionFormValues) => {
  if (!nextState.router.location.pathname.includes("/profile")) {
    return {};
  }
  if (!nextState.profile?.data?.address) {
    return {};
  }

  const profileData = nextState.profile.data;

  const address =
    profileData.address?.find(
      (singleAddress) => singleAddress.defaultAddress === true
    ) || profileData.address?.slice(-1)[0];

  const mailSubscriptionList =
    profileData?.extension?.mailSubscriptionList || [];

  const globalMailSubscriptions =
    nextState?.globalMailSubscriptions?.data || [];

  const optIns = globalMailSubscriptions.reduce((acc, globalSub) => {
    if (hiddenSubscriptions.includes(globalSub.mailingList)) {
      return acc;
    }
    const analyticsCode =
      subscriptions.find((sub) => sub.mailingList === globalSub.mailingList)
        ?.analyticsCode || globalSub.mailingList;
    if (subscriptionFormValues) {
      if (subscriptionFormValues[globalSub.mailingList]?.optInOption) {
        return [...acc, analyticsCode];
      }
    } else if (
      mailSubscriptionList
        .find((sub) => sub.mailingList === globalSub.mailingList)
        ?.optInOption.includes("Opt In")
    ) {
      return [...acc, analyticsCode];
    }
    return acc;
  }, []);

  let newOptinStr;
  if (optIns.length && optIns.length === globalMailSubscriptions.length) {
    newOptinStr = "all";
  } else {
    newOptinStr = optIns.join("|");
  }

  return {
    profile_completion: `${
      window?.utag_data?.profileCompletionPercentage ?? 0
    }%`,
    user_profile_regions: address?.country.code,
    user_postal: address?.postCode,
    user_country: address?.country.code,
    user_profile_interests: (profileData.extension?.guestPreferenceList || [])
      .map((interest) => interest.masterCode)
      .join(","),
    user_optin_status: newOptinStr,
  };
};

export const getMultiRoomData = (
  prevState,
  nextState,
  { roomSelections = [], selectedPackageOrsCodes = [] }
) => {
  const { resultSetId } = nextState.searchResults;
  const hotelCode =
    nextState.router.location.query.hotelCode ||
    (nextState.searchResults.data[resultSetId] || {})?.hotelOptions?.[0]
      .hotelCode;
  const accommodationsContent = nextState.accommodationsContent.data[hotelCode];
  const offersContent = nextState.offersContent.data[hotelCode];
  const { query } = nextState.router.location;
  const { promoCode } = query;
  const ppMode = userIsPreferredPartner(nextState);

  const hotelOptions = (
    nextState.searchResults.data[resultSetId] || {}
  )?.hotelOptions.find((hotelOption) => hotelOption.hotelCode === hotelCode);

  const employeeMode = !!nextState.employeeProfile?.data?.workdayId;
  const promoPackageCode = promoCode
    ? employeeMode
      ? promoCode.toLowerCase()
      : promoCode.match(/^[a-zA-Z]+$/)
      ? CORP_PACKAGE_CODE
      : GROUP_PACKAGE_CODE
    : undefined;
  const isRegularPromoCode = !!promoCode && !employeeMode && !ppMode;

  const usablePackageOrsCodes = (selectedPackageOrsCodes || []).map(
    (product, index) =>
      promoPackageCode ||
      (ppMode &&
      product.roomRate?.ratePlanCode &&
      product.roomRate.ratePlanCode.startsWith("PP")
        ? product.roomRate.ratePlanCode
        : selectedPackageOrsCodes[index]) ||
      ""
  );

  const numberOfRooms = hotelOptions.roomOptions.length;

  const {
    room_code_array,
    room_name_array,
    room_package_name_array,
    room_package_code_array,
  } = getRoomAndPackageArrays({
    numberOfRooms,
    accommodationsContent,
    offersContent,
    hotelOptions,
    promoCode,
    roomSelections: [...roomSelections].reverse(),
    selectedPackageOrsCodes: usablePackageOrsCodes,
  });
  let room_finding_method_array = Array(numberOfRooms).fill("multiroom");
  const roomFindingMethod = getRoomFindingMethod(prevState, nextState);
  room_finding_method_array = [
    ...room_finding_method_array,
    ...roomFindingMethod,
  ];
  return {
    room_code_array,
    room_name_array,
    room_package_name_array,
    room_package_code_array,
    room_finding_method_array,
    promocode: isRegularPromoCode ? "Yes" : "No",
  };
};

export const trackView = (eventDef) => (action, prevState, nextState) => {
  try {
    const lsAnalyticsStatus =
      sessionStorage.getItem("tealiumAnalyticsStatus") || "";
    const analyticsStatus = lsAnalyticsStatus
      ? JSON.parse(lsAnalyticsStatus)
      : [];

    const data = eventDef(action, prevState, nextState);
    const initialPageData = getInitialPageData(nextState);
    const currentBookingParams =
      nextState.bookings.bookingInProgressSearchParams;
    const propertyData = getPropertyData(
      action.payload?.hotelCode || currentBookingParams?.hotelCode,
      nextState
    );
    const bookingInProgressData = getBookingInProgressData(
      action.payload?.hotelCode || currentBookingParams?.hotelCode,
      nextState
    );

    const userData = getUserData(nextState);
    const bookingData = getBookingData(data, prevState, nextState);
    const account = getAccount(nextState);
    const purchaseData = getPurchaseData(prevState, nextState);
    const profileData = getProfileData(nextState);

    let shouldProceed = true;
    let noPurchaseEventData = false;
    if (
      nextState.apiRequestStates["searchResults/fetchSearchResults"] ===
        "FAILED" &&
      nextState.router.location.pathname === "/plan-your-stay"
    ) {
      // no results trackView is already captured in fetchSearchResultsFailed action
      shouldProceed = false;
    } else if (data.proceed === false) {
      shouldProceed = false;
    } else if (
      nextState.router.location.pathname === "/personalize-your-stay"
    ) {
      // Determine if this purchase "view" event has already been tracked.
      // If so, don't track again.  if not, store the details in case we get here again for the same purchase.
      const reservationId = bookingData.booking_id;
      const currentBookingId = nextState.bookings.bookingInProgressId;

      const reservationStatusItem = Object.entries(analyticsStatus).find(
        ([, statusItem]) =>
          statusItem.pathname === "/personalize-your-stay" &&
          (statusItem.reservationId === reservationId ||
            statusItem.currentBookingId === currentBookingId)
      )?.[1];

      if (reservationStatusItem) {
        noPurchaseEventData = true;
        delete data.event_name;
      } else {
        const analyticsViewItem = {
          pathname: nextState.router.location.pathname,
          event_name: data.event_name,
          reservationId,
          currentBookingId,
        };

        analyticsStatus.push(analyticsViewItem);
        sessionStorage.setItem(
          "tealiumAnalyticsStatus",
          JSON.stringify(analyticsStatus)
        );
      }
    }

    const upcomingTripPathnameRegex =
      /^\/profile\/upcoming-trip\/(\S{6})\/(\d+)/;

    const [_, _propertyCode, reservationId] =
      nextState.router.location.pathname.match(upcomingTripPathnameRegex) || [];

    const booking =
      reservationId && nextState.bookings.byReservationId[reservationId];

    const upcomingTripValues = {};

    if (booking) {
      upcomingTripValues.upcoming_trip = differenceInDays(
        startOfDay(new Date())
      )(parseISO(booking.startDate));
    }

    if (shouldProceed && data.event_name === "confirm_stay") {
      exposeBookingInProgressData({
        initialPageData,
        bookingData,
        hotelCode: bookingInProgressData?.propertyHotelCode,
        city: bookingInProgressData?.city,
        employeeMode: bookingInProgressData?.employeeMode,
        ppMode: bookingInProgressData?.ppMode,
      });
    }
    // if (shouldProceed) {
    //   console.log("TRACK VIEW", {
    //     type: "VIEW",
    //     proceed: shouldProceed,
    //     language: nextState?.app?.locale,
    //     data: {
    //       ...initialPageData,
    //       ...data,
    //       ...propertyData,
    //       ...userData,
    //       ...(noPurchaseEventData ? {} : bookingData),
    //       ...account,
    //       ...(noPurchaseEventData ? {} : purchaseData),
    //       ...profileData,
    //       ...upcomingTripValues,
    //     },
    //   });
    // }

    const isMatchingUser = hasMatchingBooking(nextState, reservationId);
    const parsedFsCookie = getDecodedFSSecurityMXValue();
    let loginState = "anonymous";
    if (parsedFsCookie.persisted) {
      loginState = "persistent";
    } else if (
      !!nextState.router.location.pathname.match(/\/upcoming-trip/) &&
      !isMatchingUser &&
      parsedFsCookie.authorized
    ) {
      loginState = "partially authenticated";
    } else if (parsedFsCookie.valid) {
      if (
        parsedFsCookie.persistAt &&
        isAfter(new Date(parsedFsCookie.persistAt))(new Date())
      ) {
        loginState = "persistent";
      } else {
        loginState = "fully authenticated";
      }
    }

    const payload = {
      type: "VIEW",
      proceed: shouldProceed,
      language: nextState?.app?.locale,
      data: {
        ...initialPageData,
        ...data,
        ...propertyData,
        ...userData,
        ...(noPurchaseEventData ? {} : bookingData),
        ...account,
        ...(noPurchaseEventData ? {} : purchaseData),
        ...profileData,
        ...upcomingTripValues,
        login_state: loginState,
        golden_id: parsedFsCookie.goldenIdString || "na",
      },
    };

    log("[TRACK VIEW]", `[${action.type}]`, payload);

    return payload;
  } catch {
    return {
      type: "VIEW",
      proceed: false,
    };
  }
};

export const trackEvent = (eventDef) => (action, prevState, nextState) => {
  try {
    const data = eventDef(action, prevState, nextState);
    const currentBookingParams =
      nextState.bookings.bookingInProgressSearchParams;
    const propertyData = getPropertyData(
      action.payload?.hotelCode || currentBookingParams?.hotelCode,
      nextState
    );
    const userData = getUserData(nextState);
    const bookingData = getBookingData(data, prevState, nextState);
    const upsellData = getUpsellData(data, prevState, nextState);
    const account = getAccount(nextState);

    const payload = {
      type: "LINK",
      proceed: data.proceed !== false,
      language: nextState?.app?.locale,
      data: {
        ...data,
        ...propertyData,
        ...userData,
        ...bookingData,
        ...upsellData,
        ...account,
      },
    };

    log("[TRACK EVENT]", `[${action.type}]`, payload);

    return payload;
  } catch {
    return {
      type: "LINK",
      proceed: false,
    };
  }
};

const handleEvent = (event) => {
  if (event.proceed) {
    const defaultValues = getDefaultData(event.language);

    switch (event.type) {
      case "VIEW":
        window.utag.view({
          ...defaultValues,
          ...event.data,
        });
        break;
      case "LINK":
        window.utag.link({ ...defaultValues, ...event.data });
        break;
      default:
        break;
    }
  }
};

const tealiumTarget = () => (events) => {
  if (ENABLE_ANALYTICS) {
    if (!window.utag?.view) {
      window.utag_data = window.utag_data || {};
      window.utag_cfg_ovrd = { noview: true };
      addTealiumScriptTag({ scriptPath: TEALIUM_SCRIPT_PATH });
      getFsInternal();

      return interval(CHECK_PERIOD)
        .pipe(
          filter(() => !!window.utag?.view),
          take(1),
          switchMap(() => from(events))
        )
        .subscribe(handleEvent);
    }
    return from(events).subscribe(handleEvent);
  }
  return null;
};

export default tealiumTarget;
