import { ofType } from "redux-observable";
import {
  catchError,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import { of } from "rxjs";
import { push } from "connected-react-router";
import queryString from "query-string";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import * as authenticationRoutes from "Authentication/authenticationRoutes";
import { generateOTP$ } from "api/tretail/authentication";
import { LOGIN_TYPES } from "api/tretail/authentication/generateOTP";
import {
  generateOtp,
  generateOtpFulfilled,
  generateOtpFailed,
  generateOtpCancel,
} from "store/signIn/signIn.slice";
import { resetRegistration } from "store/registration/registration.slice";
import catchInternalServerError from "store/catchInternalServerError";
import cleanPhoneNumber from "utils/cleanPhoneNumber";

export default function generateOtpEpic(action$, state$) {
  return action$.pipe(
    ofType(generateOtp.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: {
            sendCodeTo,
            sendCodeVia,
            isUserPersistent,
            gRecaptchaResponse,
            shouldRedirectToSignIn = false,
            afterSignInRedirectTo,
            employeeMode = false,
            locale,
            queryParams = {},
          },
        },
        {
          signIn: { requestOTP },
        },
      ]) => ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() => {
            const codeToSend = !sendCodeTo
              ? requestOTP.sendCodeTo[sendCodeVia]
              : sendCodeTo;
            const sanitisedCode =
              sendCodeVia === LOGIN_TYPES.PHONE_NUMBER
                ? `+${cleanPhoneNumber(codeToSend)}`
                : codeToSend;
            return generateOTP$({
              sendCodeTo: sanitisedCode,
              sendCodeVia,
              isUserPersistent,
              locale,
              gRecaptchaResponse,
            }).pipe(
              mergeMap(() =>
                [
                  generateOtpFulfilled(),
                  resetRegistration(),
                  shouldRedirectToSignIn &&
                    push({
                      pathname: employeeMode
                        ? authenticationRoutes.employeeSignIn.to({ locale })
                        : authenticationRoutes.signIn.to({
                            locale,
                          }),
                      search: queryString.stringify(queryParams),
                      state: {
                        afterSignInRedirectTo,
                      },
                    }),
                ].filter(Boolean)
              ),

              catchInternalServerError(),

              catchError(({ response = {} }) =>
                of(
                  generateOtpFailed({
                    apiErrors: response?.apiErrors || [],
                    supplierErrors: response?.supplierErrors || [],
                  })
                )
              ),
              takeUntil(action$.pipe(ofType(generateOtpCancel.type)))
            );
          })
        )
    )
  );
}
