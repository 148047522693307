import React from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

import { useTranslation } from "hooks";
import { getPathnameWithoutLocale } from "config/languages";
import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";

export default function BookingFlowSteps({ isPreferredPartners }) {
  const { t } = useTranslation();
  const location = useLocation();

  const bookingFlowSteps = [
    bookingFlowRoutes.planYourStay,
    bookingFlowRoutes.chooseYourRoom,
    bookingFlowRoutes.confirmYourStay,
    bookingFlowRoutes.personalizeYourStay,
  ].map(({ path, to, getPageTitle }) => ({
    path,
    title: getPageTitle({ isPreferredPartners }),
    isCurrentStep: getPathnameWithoutLocale(location.pathname) === to(),
  }));

  return (
    <div className="fs-header__bottom-bar">
      <div className="fs-header__breadcrumbs__container">
        <ol className="fs-header__breadcrumbs">
          {bookingFlowSteps.map(({ title, isCurrentStep }, index) => (
              <li
                key={title}
                aria-current={isCurrentStep ? "step" : undefined}
                className={classNames({ "is-active": isCurrentStep })}
              >
                <span className="fs-header__breadcrumbs__number">
                  {index + 1}
                </span>
                {isCurrentStep ? <h1>{t(title)}</h1> : t(title)}
              </li>
            ))}
        </ol>
      </div>
    </div>
  );
}
