const fuzzy = {};

// Return all elements of `array` that have a fuzzy
// match against `pattern`.
fuzzy.simpleFilter = function (pattern, array) {
	return array.filter((str) => fuzzy.test(pattern, str));
};

// Does `pattern` fuzzy match `str`?
fuzzy.test = function (pattern, str) {
	return fuzzy.match(pattern, str) !== null;
};
// If `pattern` matches `str`, wrap each matching character
// in `opts.pre` and `opts.post`. If no match, return null
fuzzy.match = function (pattern, str, opts = {}) {
	if (Array.isArray(str)) {
		const matches = str.map(s => fuzzy.match(pattern, s, opts));
		const match = matches[0] || { rendered: str[0], score: 0 };
		matches.forEach(m => {
			match.score = Math.max(match.score, m && m.score ? m.score : 0);
		});
		return match;
	}

	let patternIdx = 0;
		const result = [];
		const len = str.length;
		let totalScore = 0;
		let currScore = 0;
		// prefix
		const pre = opts.pre || '';
		// suffix
		const post = opts.post || '';
		// String to compare against. This might be a lowercase version of the
		// raw string
		const compareString = (opts.caseSensitive && str) || str.toLowerCase();
		let ch;

	const usePattern = (opts.caseSensitive && pattern) || pattern.toLowerCase();

	// For each character in the string, either add it to the result
	// or wrap in template if it's the next string in the pattern
	// eslint-disable-next-line no-plusplus
	for (let idx = 0; idx < len; idx++) {
		ch = str[idx];
		if (compareString[idx] === usePattern[patternIdx]) {
			ch = pre + ch + post;
			patternIdx += 1;

			// consecutive characters should increase the score more than linearly
			currScore += 1 + currScore;
		} else {
			currScore = 0;
		}
		totalScore += currScore;
		result[result.length] = ch;
	}

	// return rendered string if we have a match for every char
	if (patternIdx === usePattern.length) {
		// if the string is an exact match with pattern, totalScore should be maxed
		totalScore = compareString === usePattern ? Infinity : totalScore;
		return { rendered: result.join(''), score: totalScore };
	}

	return null;
};

export default fuzzy;
