import queryString from "query-string";

function hasSearchQueryParams(string) {
  const params = queryString.parse(string);
  const requiredParams = [
    "hotelCode",
    "checkIn",
    "checkOut",
    "adults",
    "children",
  ];

  const invalidParams = ["focusOn"];
  return (
    requiredParams.reduce((acc, requiredParam) => acc && Object.prototype.hasOwnProperty.call(params, requiredParam), true) &&
    invalidParams.reduce((acc, invalidParam) => acc && !Object.prototype.hasOwnProperty.call(params, invalidParam), true)
  );
}

export default hasSearchQueryParams;
