import React, { useState } from "react";

import { useTranslation } from "hooks";
import { pluralize } from "utils/utils";
import ArrivalDetailsModal from "./ArrivalDetailsModal";
import ChevronRightIcon from "./ChevronRightIcon";

export default function ArrivalCard({
  adultsCount,
  childCount,
  priceViewable,
  id,
  highlightsMap,
  bookingMessages,
  rates,
  estimatedTotalDisclaimer,
  isWebChatEnabled,
  openWebChat,
}) {
  const { t } = useTranslation();
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const arrivalItemContent = (
    <div className="itinerary__item__header">
        <p className="itinerary__item__title">{t("Arrival")}</p>

        <div className="itinerary__item__status-row">
          <span className="itinerary__item__guests">
            {[
              adultsCount > 0 && pluralize(t, adultsCount, "Adult"),
              childCount > 0 && pluralize(t, childCount, "Child", "Children"),
            ]
              .filter(Boolean)
              .join(" ")}
          </span>
        </div>
      </div>
  );

  return (
    <>
      {priceViewable && showDetailsModal && (
        <ArrivalDetailsModal
          highlightsMap={highlightsMap}
          bookingMessages={bookingMessages}
          rates={rates}
          estimatedTotalDisclaimer={estimatedTotalDisclaimer}
          isWebChatEnabled={isWebChatEnabled}
          openWebChat={openWebChat}
          close={() => setShowDetailsModal(false)}
        />
      )}

      {priceViewable ? (
        <button
          type="button"
          onClick={() => setShowDetailsModal(true)}
          className="itinerary__item"
          id={id}
        >
          <div className="itinerary__item itinerary__item--arrival">
            {arrivalItemContent}
          </div>
          <div className="icon-container">
            <ChevronRightIcon />
          </div>
        </button>
      ) : (
        <div className="itinerary__item itinerary__item--arrival">
          {arrivalItemContent}
        </div>
      )}
    </>
  );
}
