import { ofType } from "redux-observable";
import { switchMap, takeUntil, mergeMap, catchError } from "rxjs/operators";

import { push, replace } from "connected-react-router";
import queryString from "query-string";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";

import { getQueryStringFromSearchFormValues } from "BookingFlow/utils";
import { fetchBookingCommentsFulfilled } from "store/bookingComments";
import { SECTIONS } from "BookingFlow/ConfirmYourStay/hooks/constants";
import catchInternalServerError from "store/catchInternalServerError";
import {
  addPackageFulfilled,
  constructBookingByBookingId,
  constructBookingByBookingIdCancel,
  constructBookingByBookingIdFailed,
  constructBookingByBookingIdFulfilled,
} from "../bookings.slice";
import {
  fetchTermsAndConditionsContent,
  getTermsAndConditionsOptionsFromBooking,
} from "../../termsAndConditionsContent";
import fetchBookingWithCommentsById$ from "./fetchBookingWithCommentsById";

export default function constructBookingByBookingIdEpic(action$) {
  return action$.pipe(
    ofType(constructBookingByBookingId.type),
    switchMap(({ payload: { searchParams: params, locale, actions } }) => {
      const { bookingId, ...searchParams } = params;
      return ajaxWithHealthCheck$({
        locale,
        propertyCode: searchParams.hotelCode,
      }).pipe(
        switchMap(() => fetchBookingWithCommentsById$({
            bookingId,
            locale,
          }).pipe(
            mergeMap(({ booking }) => [
                constructBookingByBookingIdFulfilled(),
                addPackageFulfilled({
                  booking,
                  searchParams,
                }),
                fetchBookingCommentsFulfilled({
                  bookingId,
                }),
                fetchTermsAndConditionsContent({
                  locale,
                  hotelCode: searchParams.hotelCode,
                  optionsList: getTermsAndConditionsOptionsFromBooking({
                    booking,
                    hotelCode: searchParams.hotelCode,
                  }),
                }),
                push({
                  pathname: bookingFlowRoutes.confirmYourStay.to({
                    locale,
                  }),
                  search: queryString.stringify({
                    bookingType: SECTIONS.MYSELF,
                  }),
                  analyticsData: {
                    availCheckTemplate: searchParams.availCheckTemplate,
                    roomFindingMethod: searchParams.roomFindingMethod,
                    from: window.location.href,
                  },
                }),
                ...actions,
              ])
          )),

        catchInternalServerError(),

        catchError((error) => {
          const { response } = error;
          const errorActions = [
            constructBookingByBookingIdFailed({
              apiErrors: response?.apiErrors || [],
              supplierErrors: response?.supplierErrors || [],
            }),
            ...actions,
          ];
          if (error?.message === "SYSTEM_OFFLINE") {
            return [
              ...errorActions,
              replace({
                search: getQueryStringFromSearchFormValues(searchParams),
                pathname: bookingFlowRoutes.planYourStay.to({
                  locale,
                }),
              }),
            ];
          }
          return [
            ...errorActions,
            replace({
              search: getQueryStringFromSearchFormValues(searchParams),
              pathname: bookingFlowRoutes.chooseYourRoom.to({
                locale,
              }),
            }),
          ];
        })
      );
    }),
    takeUntil(action$.pipe(ofType(constructBookingByBookingIdCancel.type)))
  );
}
