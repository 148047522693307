import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import get from "lodash/fp/get";

import { getGuestRequests$ } from "api/messenger";
import {
  fetchGuestRequestsFulfilled,
  fetchGuestRequestsFailed,
} from "store/guestRequests/guestRequests.slice";

export default function unauthenticatedBookingItinerayPathActions({
  hotelCode,
  reservationId,
  state,
}) {
  const surname = get([
    "bookings",
    "byReservationId",
    reservationId,
    "customers",
    0,
    "name",
    "surname",
  ])(state);

  if (!surname) {
    return of({ actions: [] });
  }

  return getGuestRequests$({
    propertyCode: hotelCode,
    reservationId,
    surname,
  }).pipe(
    map((guestRequests) => ({
        actions: [
          fetchGuestRequestsFulfilled({
            reservationId,
            guestRequests,
          }),
        ],
      })),

    catchError((error) => of(fetchGuestRequestsFailed({ reservationId, error })))
  );
}
