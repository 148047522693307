import queryString from "query-string";

import { stringToInt } from "utils/utils";
import { add, format } from "date-fns";
import { dateStringFormats } from "../PlanYourStay/DateRangePicker/calendarHelpers";

const { planYourStayDates } = dateStringFormats;

const handleGuestCount = (number, defaultNumber = 1) => {
  const parsed = stringToInt(number);

  return !Number.isNaN(parsed) ? parsed : defaultNumber;
};

function getSearchFormValuesFromQueryString(string, date) {
  const {
    adults: initialAdults = [],
    children: initialChildren = [],
    checkIn,
    checkOut,
    roomFindingMethod,
    availCheckTemplate,
    ...queryParams
  } = queryString.parse(string || "");

  const adults =
    typeof initialAdults === "string" ? [initialAdults] : initialAdults;

  const children =
    typeof initialChildren === "string" ? [initialChildren] : initialChildren;

  const numberOfRooms = Math.max(adults.length, children.length, 1);
  const checkInFallback = format(date, planYourStayDates);
  const checkOutFallback = format(add(date, { days: 1 }), planYourStayDates);

  const { promoCode = "", ...queryParamsWithBooleanValues } = Object.keys(
    queryParams
  ).reduce((acc, k) => {
    const v = queryParams[k];
    return { ...acc, [k]: v === "true" ? true : v === "false" ? false : v };
  }, {});

  return {
    hotelCode: "",
    dates: {
      checkIn: checkIn || checkInFallback,
      checkOut: checkOut || checkOutFallback,
    },
    rooms: [...Array(numberOfRooms).keys()].map((index) => ({
      adults: handleGuestCount(adults[index] || "2"),
      children: handleGuestCount(children[index] || "0", 0),
    })),
    promoCode: promoCode.toUpperCase(),
    roomFindingMethod,
    availCheckTemplate,
    ...queryParamsWithBooleanValues,
  };
}

export default getSearchFormValuesFromQueryString;
