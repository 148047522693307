import flow from "lodash/fp/flow";
import filter from "lodash/fp/filter";
import sortBy from "lodash/fp/sortBy";
import groupBy from "lodash/fp/groupBy";
import map from "lodash/fp/map";
import get from "lodash/get";
import partition from "lodash/partition";

import getRates from "Profile/utils/getRates";
import { convert12HourTo24hour } from "utils";

export const ARRIVAL_TYPE = "ARRIVAL";
export const DEPARTURE_TYPE = "DEPARTURE";
export const ANCILLARY_CARDS = "ANCILLARY";

export const ACTIVITY_CARD_TYPES = [
  "Aerial Activities",
  "Biking",
  "Equestrian",
  "Golf",
  "Hiking",
  "Land Sports",
  "Local Experiences",
  "Tennis",
  "Wellness",
  "Tours",
  "Fishing",
  "Jet Ski Rental",
  "Boat Rental",
  "Yachting",
  "Kayak & Canoeing",
  "Sailing",
  "Scuba Diving",
  "Snorkelling",
  "Stand Up Paddleboarding",
  "Surfing",
  "Swimming",
  "Water Sports",
  "Ice Skating",
  "Skiing",
  "Snowboarding",
  "Winter Activities",
  "Fitness Trainer",
  "Salon",
  "Spa",
  "Childcare",
  "Kids for All Seasons",
  "Pet Services",
  "Tutor Services",
  "Afternoon Tea",
  "Bar/Club",
  "Dining Experiences",
  "Picnic",
  "Restaurant Reservation",
  "Incoming",
  "Member Event",
  "Airline Tickets",
  "Bus Tickets",
  "Cabana and Day Bed",
  "Concert Tickets",
  "Ferry & Boat Tickets",
  "Lift Pass",
  "Movie Tickets",
  "Museum & Exhibition Tickets",
  "Other Event Tickets",
  "Sports Tickets",
  "Theatre Tickets",
  "Theme Park Tickets",
  "Train Tickets",
  "Wine Tasting",
  "Amenity Request",
  "Floral",
  "Gift Card/Certificates",
];

export const TRANSPORTATION_CARD_TYPES = [
  "Arrival Transportation",
  "Car Rental",
  "Departure Transportation",
  "Golf Cart",
  "House Car",
  "Shuttle",
  "As Directed Transportation",
  "Helicopter & Private Jet Charter",
];

export const PENDING_STATES = [
  "Open",
  "Pending",
  "Waitlist",
  "On Hold",
  "Requested",
];

export const CONFIRMED_STATES = ["Closed", "Reconfirmed"];

export function isActivityRequest(guestRequest) {
  return ACTIVITY_CARD_TYPES.includes(guestRequest.requestSubtype);
}

export function isTransportRequest(guestRequest) {
  return TRANSPORTATION_CARD_TYPES.includes(guestRequest.requestSubtype);
}

function isActivityOrTransportRequest(guestRequest) {
  return [
    ARRIVAL_TYPE,
    DEPARTURE_TYPE,
    ...ACTIVITY_CARD_TYPES,
    ...TRANSPORTATION_CARD_TYPES,
  ].includes(guestRequest.requestSubtype);
}

function isPendingOrConfirmedRequest(guestRequest) {
  return [...PENDING_STATES, ...CONFIRMED_STATES].includes(guestRequest.status);
}

function filterGuestRequest(guestRequest) {
  if (guestRequest.emptyDay) return true;
  if (guestRequest.requestSubtype === ANCILLARY_CARDS) return true;
  return (
    guestRequest.showOnItinerary &&
    !guestRequest.isDeleted &&
    isActivityOrTransportRequest(guestRequest) &&
    isPendingOrConfirmedRequest(guestRequest)
  );
}

function getDatesInRange(startDate, endDate) {
  const dateArray = [];
  const currentDate = new Date(startDate);
  currentDate.setDate(currentDate.getDate() + 1);
  while (currentDate < new Date(endDate)) {
    dateArray.push(currentDate.toISOString().slice(0, 10));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
}

const mapWithIndex = map.convert({ cap: false });

export default function getItineraryItems({
  upcomingTrip,
  guests,
  accommodationTitle,
  propertyContent,
  guestRequests,
  highlightsMap,
  bookingMessages,
  taxes,
  restaurants = [],
}) {
  const [adults, children] = partition(guests, ({ type }) => type === 1);
  const propertyDefaultCheckIn = convert12HourTo24hour(
    propertyContent?.checkInTime || "3:00 PM"
  );
  const propertyDefaultCheckOut = convert12HourTo24hour(
    propertyContent?.checkOutTime || "12:00 PM"
  );
  const arrivalTime = get(
    upcomingTrip,
    ["hotelProducts", 0, "checkInTime"],
    propertyDefaultCheckIn
  );
  const priceViewable = get(
    upcomingTrip,
    ["hotelProducts", 0, "roomRate", "priceViewable"],
    true
  );

  const rates = getRates({ upcomingTrip, taxes });
  const arrival = {
    status: "Open",
    isDeleted: false,
    requestDate: upcomingTrip.startDate,
    time: arrivalTime,
    requestSubtype: ARRIVAL_TYPE,
    showOnItinerary: true,
    guests: {
      adults: adults.length,
      child: children.length,
    },
    priceViewable,
    highlightsMap,
    bookingMessages,
    rates,
    estimatedTotalDisclaimer: propertyContent?.estimatedTotalDisclaimer,
    description: accommodationTitle,
    estimatedTotal: {
      currencyCode: get(
        upcomingTrip,
        ["price", "total", "cash", "currencyCode"],
        ""
      ),
      amount: get(upcomingTrip, ["price", "total", "cash", "amount"], ""),
    },
  };

  const departure = {
    status: "Open",
    isDeleted: false,
    requestDate: upcomingTrip.endDate,
    time: propertyDefaultCheckOut,
    requestSubtype: DEPARTURE_TYPE,
    showOnItinerary: true,
  };

  const availableRestaurants = {
    requestDate: upcomingTrip.startDate,
    time: "23:59:00.000",
    restaurants,
    requestSubtype: ANCILLARY_CARDS,
  };

  const datesInRange = getDatesInRange(
    upcomingTrip.startDate,
    upcomingTrip.endDate
  );

  const guestRequestsDates = guestRequests.map(
    (request) => request.requestDate
  );

  const emptyDays = flow(
    filter((date) => !guestRequestsDates.includes(date)),
    mapWithIndex((date, index) => ({ requestDate: date, emptyDay: true, firstEmptyDay: index === 0 }))
  )(datesInRange);

  return flow(
    filter((item) => !item.restaurants || item.restaurants.length > 0),
    filter(filterGuestRequest),
    sortBy(["requestDate", "time"]),
    groupBy("requestDate")
  )([...guestRequests, arrival, availableRestaurants, ...emptyDays, departure]);
}
