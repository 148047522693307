import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";
import castArray from "lodash/fp/castArray";
import filter from "lodash/fp/filter";
import flatMap from "lodash/fp/flatMap";
import flow from "lodash/fp/flow";
import keys from "lodash/fp/keys";
import map from "lodash/fp/map";
import pick from "lodash/fp/pick";
import reduce from "lodash/fp/reduce";
import update from "lodash/fp/update";

import env from "config/env";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;

const formFieldValuesForSubmit = flatMap((item) => flow(
    keys,
    reduce((acc, key) => [...acc, { name: key, value: item[key] }], [])
  )(item));

const getProcessedFormData = flow(
  pick([
    "details",
    "quantity",
    "numQuantity",
    "startDate",
    "code",
    "imageURL",
    "name",
    "onRequestEventProductType",
    "PRICE_ID",
    "PRODUCT_ID",
    "merchantGroupType",
    "merchantGroupCategory",
    "merchantGroupSubCategory",
  ]),
  update(["details", "fields"], formFieldValuesForSubmit),
  update(
    ["details", "fields"],
    filter(({ value = "" }) => value !== "")
  ),
  update(
    ["details", "aggregation"],
    filter(({ value = "" }) => value !== "")
  )
);

export default function refreshOnRequestEventList$({
  locale = "en",
  bookingId,
  formData,
}) {
  return ajax({
    ...basePostRequest({ locale }),
    url: `${TRETAIL_API_BASE_URL}/bookings/${bookingId}/products/refreshOnRequestEventList`,
    body: flow(castArray, map(getProcessedFormData))(formData),
  }).pipe(pluck("response"));
}
