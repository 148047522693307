import take from "lodash/take";
import takeRight from "lodash/takeRight";
import zipWith from "lodash/zipWith";
import {
  add,
  areIntervalsOverlapping,
  differenceInDays,
  format,
  isAfter,
  parse,
  sub,
} from "date-fns";
import * as dateFnLocales from "date-fns/locale";
import { getDateFnsLocale } from "config/languages";
import map from "lodash/fp/map";
import compose from "lodash/fp/compose";
import get from "lodash/fp/get";
import flatten from "lodash/fp/flatten";
import compact from "lodash/fp/compact";

const today = new Date();

export const generateDays = (
  currentMonthDays,
  previousMonthDays,
  nextMonthDays
) => {
  const wantedPreviousMonthDays = takeRight(
    previousMonthDays,
    currentMonthDays.filter((a) => a === 0).length
  );
  const wantedNextMonthDays = take(
    nextMonthDays.filter((a) => a !== 0),
    7 - nextMonthDays.filter((a) => a === 0).length
  );

  return [
    ...zipWith(wantedPreviousMonthDays, currentMonthDays, (a, b) =>
      b === 0 ? a : b
    ),
    ...wantedNextMonthDays,
  ];
};

export const formatDate = (date, locale) => {
  const f = (formatString) => format(date, formatString, {
      locale: dateFnLocales[getDateFnsLocale(locale)],
    });

  switch (locale) {
    case "zh":
    case "zh_hant":
    case "jp":
      return f("yyyy'年'MM'月'dd'日'");

    case "kr":
      return f("yyyy'년'MM'월'dd'일'");

    case "ar":
      return f("eeee d MMMM yy");

    case "es":
    case "es-es":
      return f("eee d MMM'.' yy");

    case "de":
      return f("eee',' d MMM'.' ''yy");

    case "it":
      return f("eee d MMM yy")
        .split("")
        .map((a, i) => (i === 0 ? a.toUpperCase() : a))
        .join("");

    case "br":
    case "pt":
      return f("eee d MMM yy")
        .split(" ")
        .map((a) => a[0].toUpperCase() + a.substring(1, a.length))
        .join(" ");

    case "tr":
      return f("d MMM yyyy eee");

    default:
      return f("eee d MMM yy");
  }
};

export const dateStringFormats = {
  planYourStayDates: "yyyy-MM-dd",
};

export const getCorrectCheckOutDate =
  ({ minNumberOfNights, maxNumberOfNights }) =>
  ({ correctedCheckInDate, checkOutDate }) => {
    if (!checkOutDate) return undefined;

    const selectedStayLength = differenceInDays(
      checkOutDate,
      correctedCheckInDate
    );

    if (
      selectedStayLength >= minNumberOfNights &&
      selectedStayLength <= maxNumberOfNights
    ) {
      return checkOutDate;
    }

    if (selectedStayLength < minNumberOfNights) {
      return add(correctedCheckInDate, { days: minNumberOfNights });
    }

    return sub(checkOutDate, { days: selectedStayLength - maxNumberOfNights });
  };

export const dateFormat = dateStringFormats.planYourStayDates;

export const parseDate = (d) =>
  parse(d, dateStringFormats.planYourStayDates, new Date());

export const getInitialStartAndEndDates = ({
  checkIn,
  checkOut,
  minNumberOfNights,
  openingDate,
}) => {
  try {
    const parsedStartDate = parseDate(checkIn);
    const parsedEndDate = parseDate(checkOut);
    const parsedOpeningDate = openingDate
      ? parse(openingDate, "MM/dd/yy", new Date())
      : parsedStartDate;
    const validInterval = isAfter(parsedStartDate, parsedOpeningDate)
      ? { start: parsedStartDate, end: add(parsedStartDate, { years: 1 }) }
      : { start: parsedOpeningDate, end: add(parsedOpeningDate, { years: 1 }) };

    const resetToWithinValidInterval = ({ startDate, endDate }) =>
      areIntervalsOverlapping(validInterval, { start: startDate, end: endDate })
        ? { startDate, endDate }
        : {
            startDate: validInterval.start,
            endDate: add(validInterval.start, { days: minNumberOfNights }),
          };

    const ensureEndDateIsAfter = ({ startDate, endDate }) =>
      isAfter(endDate, startDate)
        ? { startDate, endDate }
        : {
            startDate,
            endDate: add(startDate, { days: minNumberOfNights }),
          };

    return resetToWithinValidInterval(
      ensureEndDateIsAfter({
        startDate: parsedStartDate,
        endDate: parsedEndDate,
        openingDate: parsedOpeningDate,
      })
    );
  } catch (e) {
    return {
      startDate: today,
      endDate: add(today, { days: minNumberOfNights }),
    };
  }
};

export function getCalendarMonthLabel(date, locale) {
  const f = (formatString) => format(date, formatString, {
      locale: dateFnLocales[getDateFnsLocale(locale)],
    });

  switch (locale) {
    case "zh":
    case "zh_hant":
    case "jp":
      return f("MMMM yyyy'年'");

    case "kr":
      return f("MMMM yyyy'년'");

    case "ru":
    case "br":
    case "pt":
    case "it":
      return f("MMMM yyyy")
        .split("")
        .map((a, i) => (i === 0 ? a.toUpperCase() : a))
        .join("");

    default:
      return f("MMMM yyyy");
  }
}

export function getWeekdayLabelFormatter(locale) {
  return (date) => {
    const result = format(date, "eee", {
      locale: dateFnLocales[getDateFnsLocale(locale)],
    });

    switch (locale) {
      case "br":
      case "pt":
      case "it":
        return result
          .split("")
          .map((a, i) => (i === 0 ? a.toUpperCase() : a))
          .join("");

      default:
        return result;
    }
  };
}

export function getCheckInDay(date, locale) {
  const f = (formatString) => format(date, formatString, {
      locale: dateFnLocales[getDateFnsLocale(locale === "br" ? "pt" : locale)],
    });

  switch (locale) {
    case "zh":
      return f("eee");

    case "it":
    case "br":
    case "pt":
      return f("EEEE")
        .split("")
        .map((a, i) => (i === 0 ? a.toUpperCase() : a))
        .join("");

    default:
      return f("EEEE");
  }
}

export function getCheckInDate(date, locale) {
  const f = (formatString) => format(date, formatString, {
      locale: dateFnLocales[getDateFnsLocale(locale)],
    });

  switch (locale) {
    case "zh":
    case "zh_hant":
    case "jp":
      return f("d '日'");

    case "kr":
      return f("d ' 일'");

    default:
      return f("d");
  }
}

export function getCheckInMonthAndYear(date, locale) {
  const f = (formatString) => format(date, formatString, {
      locale: dateFnLocales[getDateFnsLocale(locale)],
    });

  switch (locale) {
    case "zh":
    case "zh_hant":
    case "jp":
      return f("yyyy '年' MM '月'");

    case "kr":
      return f("MM '월' yyyy '년'");

    case "ar":
      return f("MMMM yyyy");

    case "es":
    case "es-es":
    case "de":
      return f("MMM'.' yyyy");

    case "it":
    case "br":
    case "pt":
      return f("MMM yyyy")
        .split("")
        .map((a, i) => (i === 0 ? a.toUpperCase() : a))
        .join("");

    default:
      return f("MMM yyyy");
  }
}

export const formatDateWithLocale = (formatString, locale) => (date) =>
  format(date, formatString, {
    locale: dateFnLocales[getDateFnsLocale(locale)],
  });

const dateFromUnixTime = (timestamp) => new Date(timestamp);

const extractDatesFromAvailability = (fieldName) =>
  compose(
    compact,
    flatten,
    map(compose(map(dateFromUnixTime), get([fieldName])))
  );

export const extractRestrictedDates =
  extractDatesFromAvailability("restrictedDates");

export const extractUnavailableDates = extractDatesFromAvailability(
  "noAvailabilityDates"
);
