import { ofType } from "redux-observable";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";
import { of, EMPTY } from "rxjs";

import { errorCodes } from "utils/errorCodes";
import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import {
  createHistoricBookingSummaries$,
  getHistoricBookingSummaries$,
} from "api/tretail/bookingHistory";
import catchInternalServerError from "store/catchInternalServerError";
import {
  fetchBookingHistory,
  fetchBookingHistoryFulfilled,
  fetchBookingHistoryFailed,
  fetchBookingHistoryCancel,
} from "../bookingHistory.slice";

export function fetchBookingHistory$({ locale }) {
  return createHistoricBookingSummaries$({ locale }).pipe(
    switchMap(({ id: resultSetId }) =>
      getHistoricBookingSummaries$({ resultSetId, locale })
    ),

    catchError(({ response = {} }) => {
      const apiErrors = response?.apiErrors || [];
      if (
        apiErrors.map((e) => e.errorCode).includes(errorCodes.NO_RESULTS_CODE)
      ) {
        return of(EMPTY);
      }
      throw response;
    })
  );
}

export default function fetchBookingHistoryEpic(action$) {
  return action$.pipe(
    ofType(fetchBookingHistory.type),
    switchMap(({ payload: { locale } }) => ajaxWithHealthCheck$({
        locale,
      }).pipe(
        switchMap(() => createHistoricBookingSummaries$({ locale }).pipe(
            map(fetchBookingHistoryFulfilled),

            catchInternalServerError(),

            catchError(({ response = {} }) =>
              of(
                fetchBookingHistoryFailed({
                  apiErrors: response?.apiErrors || [],
                  supplierErrors: response?.supplierErrors || [],
                })
              )
            ),

            takeUntil(action$.pipe(ofType(fetchBookingHistoryCancel.type)))
          ))
      ))
  );
}
