import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, mergeMap, switchMap, takeUntil } from "rxjs/operators";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { cancelBookingCust$ } from "api/tretail/bookingManagementCust";
import catchInternalServerError from "store/catchInternalServerError";
import {
  cancelBooking,
  cancelBookingFulfilled,
  cancelBookingFailed,
  cancelBookingCancel,
} from "../bookings.slice";

export default function cancelBookingEpic(action$) {
  return action$.pipe(
    ofType(cancelBooking.type),
    switchMap(({ payload: { bookingId, locale, bookingEmailOptions } }) => ajaxWithHealthCheck$({
        locale,
      }).pipe(
        switchMap(() => cancelBookingCust$({
            bookingId,
            locale,
            bookingEmailOptions,
          }).pipe(
            mergeMap(({ cancelRefs }) => {
              if (!cancelRefs) {
                // eslint-disable-next-line no-throw-literal
                throw {
                  response: { errors: ["failed cancellation"] },
                };
              }
              return [
                cancelBookingFulfilled({
                  cancelRefs,
                  bookingId,
                }),
              ];
            }),

            catchInternalServerError(),

            catchError(({ response = {} }) =>
              of(
                cancelBookingFailed({
                  errors: response?.errors || [],
                  apiErrors: response?.apiErrors || [],
                  supplierErrors: response?.supplierErrors || [],
                })
              )
            ),
            takeUntil(action$.pipe(ofType(cancelBookingCancel.type)))
          ))
      ))
  );
}
